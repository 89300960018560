import ContainerLayout from "../components/app/ContainerLayout";
import IssuedTab from "../components/issueds/IssuedInvoiceTab";

export default function IssuedInvoiceContainer() {
  return (
    <ContainerLayout>
      <IssuedTab />
    </ContainerLayout>
  );
}
