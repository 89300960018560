export const setLocalStorage = (data : any, key = "grid") => {

    localStorage.setItem(key, JSON.stringify(data));

};


export const getLocalStorage = (key = "grid") => {
    return JSON.parse(localStorage.getItem(key) as string);
};

export const clearLocalStorage1 = () => {
    localStorage.clear();
};

export const clearLocalStorage = (key: string) => {
    localStorage.removeItem(key);
};