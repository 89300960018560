export const IS_DEV = process.env.NODE_ENV !== 'production';

var urlDevHome = 'https://localhost:7271';
var urlProd = 'https://api.ethnologistics.com';
var URL = '';

if (IS_DEV) {
    // URL = urlDevHome;
    URL = urlProd;
} else if (!IS_DEV) {
    URL = urlProd;
}
export const API_HOST_SECOND = URL as string;
