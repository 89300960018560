import { update } from "immupdate";
import { PersistConfig } from "redux-persist";

import { AppStoreState } from "../store/RootReducer";
import {
  createReducer,
  createRootReducer,
  PerformAction,
} from "../utils/ReducerUtils";
import { MenuTypes } from "../api/AppDto";

export const appReducerPersistConfig: Partial<PersistConfig<AppReducerState>> =
  {
    whitelist: ["menuType", "username", "headerTitle"],
  };

interface SwitchMenuTypeMeta {
  readonly menuType: MenuTypes;
}

interface SwitchUsernameMeta {
  readonly username: string;
}

interface SwitchHeaderTitleMeta {
  readonly headerTitle: string;
}

enum ReducerActions {
  SwitchMenuType = "App/SwitchMenuType",
  SwitchUsername = "App/SwitchUsername",
  SwitchHeaderTitle = "App/SwitchHeaderTitle",
}

export interface AppReducerState {
  readonly menuType: MenuTypes;
  readonly username: string;
  readonly headerTitle: string;
}

function getState(): AppReducerState {
  return {
    menuType: MenuTypes.Opened,
    username: "",
    headerTitle: "Главная",
  };
}

export const appReducer = createRootReducer<AppReducerState>(
  getState(),

  createReducer([ReducerActions.SwitchUsername], (state, { meta }) =>
    update(state, { username: meta.username })
  ),

  createReducer([ReducerActions.SwitchMenuType], (state, { meta }) =>
    update(state, { menuType: meta.menuType })
  ),

  createReducer([ReducerActions.SwitchHeaderTitle], (state, { meta }) =>
    update(state, { headerTitle: meta.headerTitle })
  )
);

// ==================
// Selectors
// ==================

export const appMenuTypeSelector = ({ app }: AppStoreState): MenuTypes =>
  app.menuType;

export const appUsernameSelector = ({ app }: AppStoreState): string =>
  app.username;

export const appHeaderTitleSelector = ({ app }: AppStoreState): string =>
  app.headerTitle;

// ==================
// Actions
// ==================

export function switchMenuType(
  meta: SwitchMenuTypeMeta
): PerformAction<SwitchMenuTypeMeta> {
  return { type: ReducerActions.SwitchMenuType, meta };
}

export function switchUsername(
  meta: SwitchUsernameMeta
): PerformAction<SwitchUsernameMeta> {
  return { type: ReducerActions.SwitchUsername, meta };
}

export function switchHeaderTitle(
  meta: SwitchHeaderTitleMeta
): PerformAction<SwitchHeaderTitleMeta> {
  return { type: ReducerActions.SwitchHeaderTitle, meta };
}
