import { useMemo } from "react";
import { StatusApi } from "./StatusApi";
import { useApiBase } from "../ApiContext";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

interface Props{
    readonly StatusApi: StatusApi;
}

export function useStatusApiContext():Props{
    const data = useApiBase();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const api = useMemo(() => new StatusApi(data), []);
    const api = useMemo(() => new StatusApi({...data, navigate, dispatch}), []);

    return {
        StatusApi: api
    }
}