import { useMemo } from "react";
import { ParcelApi } from "./ParcelApi";
import { useApiBase } from "../ApiContext";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

interface Props {
  readonly ParcelApi: ParcelApi;
}

export function useParcelApiContext(): Props {
  const data = useApiBase();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const api = useMemo(() => new ParcelApi(data), []);
  const api = useMemo(() => new ParcelApi({...data, navigate, dispatch}), []);

  return {
    ParcelApi: api,
  };
}
