import qs from "qs";
import {pathParams} from "../utils/PathParams";
import ky, {Hooks, Options as KYOptions, ResponsePromise} from "ky";
import { resetToken } from "../reducers/authReducer";
import {apiLogger} from "../utils/ApiUtils";
import {toCamelCase} from "../utils/CaseUtils";
import {AppError, AppErrorProps} from "../helpers/AppError";
import {toast} from "react-toastify";
import {Pages} from "../constants/Routes";
import { Dispatch } from "redux";

export interface ApiProps {
    readonly host: string;
    readonly token?: string;
    readonly logout?: () => void;
    readonly navigate?: (path: string) => void;
    readonly dispatch: Dispatch;
}

export interface ResponseListProps<TData = any> {
    readonly list: TData[];
    readonly page: number;
    readonly perPage: number;
    readonly pageCount: number;
    readonly totalCount: number;

}

export interface Options extends KYOptions {
    readonly query?: object;
    readonly params?: object;
}

export class BaseApi {
    private readonly host: string;
    private accessToken?: string;
    private readonly logout?: () => void;
    private readonly navigate?: (path: string) => void; // Add navigate property
    private readonly dispatch: Dispatch; // Initialize dispatch

    constructor({token, host, logout, navigate, dispatch}: ApiProps) {
        this.host = host;
        this.accessToken = token;
        this.logout = logout;
        this.navigate = navigate; // Add navigate property
        this.dispatch = dispatch; // Initialize dispatch
    }

    private queryToString(query = {}): string {
        return qs.stringify(query);
    }

    private createRequestUrl(url: string, query = {}, params = {}): string {
        const formattedUrl = pathParams(url, params);

        return [formattedUrl, this.queryToString(query)].filter(Boolean).join("?");
    }

    private createRequestOptions(options: KYOptions): KYOptions {
        const {hooks = {} as Hooks, headers: optionHeaders = [] as any} = options;

        const headers = new Headers(optionHeaders);

        if (this.accessToken) {
            headers.set("Authorization", `Bearer ${this.accessToken}`);
        }

        headers.set("TimeZoneInfo", Intl.DateTimeFormat().resolvedOptions().timeZone)

        return {
            timeout: 120000,
            prefixUrl: this.host,
            ...options,
            headers: [...(headers as any), ...optionHeaders],
            hooks: {
                ...hooks,
                //Switch fff Logger
                beforeRequest: [...(hooks?.beforeRequest || []), /*apiLogger*/],
                afterResponse: [
                    ...(hooks?.afterResponse || []),
                    async (_: any, __: any, response: any) => {
                        if ((response.status === 403 || response.status === 401) && this.logout) {
                            this.logout();
                        }

                        if (response.status === 401 && this.navigate) {

                            this.accessToken = undefined; // Clear the accessToken
                            this.dispatch(resetToken()); // Use dispatch to reset accessToken
                            // console.log("Navigate to login page");
                            // this.navigate(Pages.Login);
                            // console.log(this.accessToken)
                            window.location.href = "/auth";
                        } else if (response.status === 403) {
                            toast.error("У вас нет доступа к этому ресурсу");
                        }

                    },
                ],
            },
        };
    }

    private request(url: string, options: Options = {}): ResponsePromise {
        const {query, params, ...kyOptions} = options;

        const formattedOptions = this.createRequestOptions(kyOptions);
        const formattedUrl = this.createRequestUrl(url, query, params);

        return ky(formattedUrl, formattedOptions);
    }

    private jsonRequest<TData>(url: string, options?: Options): Promise<TData> {
        return new Promise<TData>((resolve, reject) => {
            this.request(url, options)
                .then((response: any) => {
                    if (response.ok) {
                        return response.json().then((data: any) => {
                            if (data.success && data.data) {
                                return data.data;
                            } else if (data) {
                                return data;
                            } else {
                                return this.parseError(data);
                            }
                        });
                    }

                    return response
                        .json()
                        .then((data: any) => this.parseError(data))
                        .then((error: any) => {
                            throw error;
                        });
                })
                .then(resolve)
                .catch((error: any) => {

                    // if (error.status === 401) {
                    //     // this.navigate(Pages.Login);
                    //     window.location.href = '/auth';
                    // } else if (error.status === 403) {
                    //     toast.error("На данную страницу у вас нет доступа!");
                    // }

                    if (error instanceof AppError) {
                        reject(error);
                    } else if (error?.response?.json) {
                        error?.response
                            ?.json()
                            .then((data: Response) => reject(this.parseError(data)));
                    } else if (error) {
                        reject(
                            this.parseError({
                                statusText: error.message,
                                errors: [{userMsg: error.message}],
                            } as any)
                        );
                    } else {
                        reject(
                            this.parseError({
                                statusText: "Unknown",
                                errors: [{userMsg: "Unknown"}],
                            } as any)
                        );
                    }
                });
        });
    }

    private parseError(response: Response): AppError {
        const error = new Error(response.statusText) as AppErrorProps;

        error.status = response?.status;
        // @ts-ignore
        error.data = toCamelCase(response?.errors || []);

        return new AppError(error);
    }

    protected downloadPdf<TData = any>(
        url: string,
        fileName = "filename",
        options?: Options
    ): Promise<void> {
        return this.request(url, options)
            .then((response: any) => response.blob())
            .then((blob: any) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");

                a.href = url;
                a.download = `${fileName}.pdf`;

                document.body.appendChild(a);

                a.click();
                a.remove();
            });
    }

    protected downloadWord<TData = any>(
        url: string,
        fileName = "Report",
        options?: Options
    ): Promise<void> {
        return this.request(url, options)
            .then((response: any) => response.blob())
            .then((blob: any) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");

                a.href = url;
                a.download = `${fileName}.xlsx`;

                document.body.appendChild(a);

                a.click();
                a.remove();
            });
    }

    protected getCaptcha<TData = any>(
        url: string,
        options?: Options
    ): Promise<any> {
        return this.request(url, options).then((r: any) => {
            const a = document.createElement("img");
            a.src = r.url;
            a.alt = "captcha";
            return a;
        });
    }

    public get<TData = any>(url: string, options?: Options): Promise<TData> {
        return this.jsonRequest(url, {...options, method: "get"});
    }

    protected post<TData = any>(url: string, options?: Options): Promise<TData> {
        return this.jsonRequest(url, {...options, method: "post"});
    }

    protected put<TData = any>(url: string, options?: Options): Promise<TData> {
        return this.jsonRequest(url, {...options, method: "put"});
    }

    protected patch<TData = any>(url: string, options?: Options): Promise<TData> {
        return this.jsonRequest(url, {...options, method: "patch"});
    }

    protected delete<TData = any>(
        url: string,
        options?: Options
    ): Promise<TData> {
        return this.jsonRequest(url, {...options, method: "delete"});
    }
}
