import { ReactNode } from "react";
import "./assets/dashboard-card.scss";
import UpDownIcon from "../icons/UpDownIcon";

interface Props{
    readonly title?: string;
    readonly icon?: ReactNode;
    readonly color?: string;
    readonly fromMe?: string;
    readonly toMe?: string;
    readonly count?: any;
}

export default function DashboardCard({
    title,
    count,
    fromMe,
    toMe,
    color = "red",
    icon
}:Props){
    return (
        <div className="dashboard-card"
            style={{
                borderTop: `4px solid ${color}`
            }}
            >
            <div className="dashboard-card-icon-box">
            <div className="dashbaord-card-icon"
                style={{
                    backgroundColor: color
                }}
                >
                {icon}
            </div>
            </div>
            <div className="dashboard-card-info">
                <div className="dashboard-card-title">
                    <span>{title}</span>
                </div>
                <div className="dashboard-card-count">
                    {(!count && fromMe) && <span 
                        style={{
                            color: "green"
                        }}
                        ><UpDownIcon color="green" size={14} type="down"/>{fromMe} </span>}
                    {(!count && toMe) && <span
                        style={{
                            color: "blue"
                        }}
                        ><UpDownIcon type="up" size={14} color="blue"/>{toMe}</span>}
                    {(count && !fromMe && !toMe) && <span>{count}</span>}
                </div>
            </div>
        </div>
    )
}