import "./assets/app-layout.scss";

import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { appMenuTypeSelector, switchMenuType } from "../../reducers/appReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { MenuTypes } from "../../api/AppDto";

import Header from "../header/Header";
import SideBarMenuWrapper from "./SideBarMenuWrapper";

interface AppLayoutProps {
  readonly children: ReactNode;
}

export default function AppLayout({ children }: AppLayoutProps) {
  const menu = useShallowEqualSelector(appMenuTypeSelector);
  const dispatch = useDispatch();
  return (
    <div className="app-layout">
      <div
        className={`side-bar-menu ${
          menu === MenuTypes.Opened ? "side-bar-menu-close" : ""
        }`}
      >
        <SideBarMenuWrapper />
      </div>
      <div
        className={`page-container ${
          menu === MenuTypes.Opened ? "page-container-close" : ""
        }`}
      >
        <div className="header">
          <Header
            menuButton={() =>
              dispatch(
                switchMenuType({
                  menuType:
                    menu === MenuTypes.Opened
                      ? MenuTypes.Closed
                      : MenuTypes.Opened,
                })
              )
            }
          />
        </div>
        <div className="page">{children}</div>
      </div>
    </div>
  );
}
