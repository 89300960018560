import DashboardBranchTable from "./DashboardBranchTable";

interface Props{
    readonly data: any[]
}

export default function DashboardBranchTableWrapper({
    data
}:Props){
    return (
        <div className="bg-light mt-5"
            style={{
                boxShadow: "1px 2px 2px 1px rgb(190, 189, 189)",
                borderRadius: "4px",
                overflow: "hidden"
            }}
            >
            <DashboardBranchTable data={data}/>
        </div>
    )
}