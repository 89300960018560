import { IdProps } from "../AppDto";
import { BaseApi } from "../BaseApi";

export class UserApi extends BaseApi {
  public getAllUsers({ pageNumber, pageSize, roleId }: any): Promise<any> {
    return this.get(
      `UserManager/GetUserListWithPagination?pageNumber=${pageNumber}&pageSize=${pageSize}&RoleId=${roleId}`
    );
  }

  public getAllUsersWithSearchText({
    pageNumber,
    pageSize,
    roleId,
    searchText,
  }: any): Promise<any> {
    if (searchText == "") {
      return this.get(
        `UserManager/GetUserListWithPaginationWithSearchText?pageNumber=${pageNumber}&pageSize=${pageSize}&RoleId=${roleId}`
      );
    } else {
      //console.log(searchText)
      return this.get(
        `UserManager/GetUserListWithPaginationWithSearchText?pageNumber=${pageNumber}&pageSize=${pageSize}&RoleId=${roleId}&searchText=${searchText}`
      );
    }
  }

  public getAllPolicy(): Promise<any> {
    return this.get(`Claims/GetPolicyList`);
  }

  public getUserInfo(): Promise<any> {
    return this.get(`UserProfile/GetUserInfo`);
  }

  // public getAllUsersWithoutPagination(roleId: number): Promise<any> {
  //   return this.get(`UserManager/GetUserList?RoleId=${roleId}`);
  // }

  public getUserById({ id }: IdProps): Promise<any> {
    return this.get(`UserManager/GetUserDetails/${id}`);
  }



  public deleteUser(id: any): Promise<any> {
    return this.delete(`UserManager/DeleteUser/${id}`);
  }

  public updateUser(json: any) {
    return this.put("UserManager/UpdateUser", { json });
  }

  public createUser(json: any) {
    return this.post("UserManager/CreateUser", { json });
  }

  public addToClaims(json: any) {
    return this.post("Claims/AddToClaims", { json });
  }

  public addToRole(json: any) {
    return this.post("UserManager/AddToRole", { json });
  }

  public updateUserPassword(json: any) {
    return this.post("UserManager/UpdatePassword", { json });
  }
}
