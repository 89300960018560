import React, {useEffect, useState} from "react";
import {CheckUserClaim} from "../../utils/CheckUserInfo";
import {useShallowEqualSelector} from "../../hooks/useShallowSelector";
import {profileSelector} from "../../reducers/authReducer";

import TableButton from "../button/TableButton";
import EditIcon from "../icons/EditIcon";
import Table from "../table/Table";

interface BranchTableProps {
    readonly data: any;
    readonly loading: boolean;
    readonly selectRow: (value: any) => void;
    readonly selectRowCheckbox: (value: any) => void;
}

export default function BranchTable({
                                        data,
                                        loading,
                                        selectRow,
                                        selectRowCheckbox,
                                    }: BranchTableProps) {

    const [tableHeaders, setTableHeaders] = useState([]);

    const profile = useShallowEqualSelector(profileSelector);

    const [headers] = useState([
        {
            header: "№",
            access: "id",
            width: 50,
        },
        {
            header: "Название",
            access: "name",
            width: 80,
        },
        {
            header: "Email",
            access: "email",
            width: 200,
        },
        {
            header: "Город",
            access: "city",
            width: 80,
        },
        {
            header: "Адрес",
            access: "address",
            width: 500,
        },
        {
            header: "Страна",
            access: "country",
            width: 100,
        },
        {
            header: "Контакт",
            access: "phone",
            width: 100,
        },
        {
            header: "Код",
            access: "code",
            width: 50,
        },
        {
            header: "...",
            access: "edit",
            ceil: (row: any) => {
                return (
                    <div className="d-flex">
                        <TableButton className="bg-warning" onClick={() => selectRow(row)}>
                            <EditIcon color="white" size={14}/>
                        </TableButton>
                    </div>
                );
            },
            width: 50,
        },
    ]);

    useEffect(() => {
        const data: any = [...headers];
        if (!CheckUserClaim(profile, "UpdateBranch")) {
            data.splice(data.length - 1, 1);
        }
        setTableHeaders(data)
    }, [headers, profile])

    return (
        <Table
            selectRowCheckbox={selectRowCheckbox}
            data={data}
            loading={loading}
            headers={tableHeaders}
            withCheckbox={true}
        />
    );
}
