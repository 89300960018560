import React from "react";
import MenuButton from "./MenuButton";
import LogoutButton from "./LogoutButton";

import { useDispatch } from "react-redux";
import { resetToken } from "../../reducers/authReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { appHeaderTitleSelector } from "../../reducers/appReducer";

interface HeaderProps {
  readonly menuButton: () => void;
}

export default function Header({ menuButton }: HeaderProps) {
  const dispatch = useDispatch();

  const headerTitle = useShallowEqualSelector(appHeaderTitleSelector);

  return (
    <header
      className="w-100 h-100 d-flex justify-content-between align-items-center pe-3"
      style={{ backgroundColor: "#2e5c87" }}
    >
      <div className="d-flex align-items-center h-100">
        <MenuButton onClick={menuButton} />
        <div className="text-light ms-4">{headerTitle}</div>
      </div>
      <LogoutButton
        onClick={() => {
          dispatch(resetToken());
        }}
      />
    </header>
  );
}
