import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useCustomerApiContext } from "../../api/customer/CustomerContext";
import { useParcelApiContext } from "../../api/parcel/ParcelApiContext";
import { update } from "immupdate";

import Pagination from "../pagination/Pagination";
import TabPage from "../tabs/TabPage";
import CustomerMyReceipendTable from "./CustomerMyReceipendTable";
import Modal from "../modal/Modal";
import GroupBox from "../app/GroupBox";
import { Form, Formik } from "formik";
import SelectPickerField from "../form/SelectPickerField";
import { noop } from "lodash";
import { useCurrencyApiContext } from "../../api/currency/CurrencyApiContext";

export default function CustomerMyReceipendTableWrapper() {
  const [data, setData] = useState<any>({});
  const [tableLoading, setTableLoading] = useState(false);
  const [currencyFormModal, setCurrencyFormModal] = useState<any>({
    modalType: false,
    data: "",
  });

  const [searchParams] = useSearchParams();

  const pageSize = Number(searchParams.get("pageSize") || 25);
  const pageCount = Number(searchParams.get("pageCount") || 1);
  const [currencyList, setCurrencyList] = useState([]);

  const { CustomerApi } = useCustomerApiContext();
  const { CurrencyApi } = useCurrencyApiContext();
  const { ParcelApi } = useParcelApiContext();

  useEffect(() => {
    setTableLoading(true);
    CustomerApi.getReceipendParcelList({
      pageNumber: pageCount,
      pageSize: pageSize,
    })
      .then((response: any) => {
        setData(response);
        setTableLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setTableLoading(false);
      });
  }, [CustomerApi, pageCount, pageSize]);

  useEffect(() => {
    CurrencyApi.getCurrencyListForDropDown()
      .then((response) => setCurrencyList(response.currencies))
      .catch((error) => console.log(error));
  }, [CurrencyApi]);

  const onPrintInvoice = useCallback(
    (value: any) => {
      ParcelApi.getStickerById({ id: value.id, code: value.code });
    },
    [ParcelApi]
  );

  const onChangeCurreny = useCallback(
    (value: any) => {
      ParcelApi.getInvoice(
        { id: currencyFormModal?.data?.id, currencyId: value.value },
        currencyFormModal.data.code
      );
      setCurrencyFormModal((prev: any) =>
        update(prev, {
          modalType: false,
        })
      );
    },
    [currencyFormModal, ParcelApi]
  );

  return (
    <TabPage
      headerComponent={
        <div className="d-flex justify-content-between align-items-center h-100">
          <div />
        </div>
      }
      footerComponent={
        <div className="d-flex justify-content-between my-3">
          <div />
          <Pagination
            pageNumber={data.pageNumber}
            totalCount={data.totalCount}
            totalPages={data.totalPages}
            onSubmit={(value: any) => console.log(value)}
          />
        </div>
      }
    >
      <CustomerMyReceipendTable data={data?.items} loading={tableLoading}  
      onPrintWithCurrency={(value: any) => {
          setCurrencyFormModal((prev: any) =>
            update(prev, {
              modalType: true,
              data: value,
            })
          );
        }}/>
      <Modal
        show={currencyFormModal.modalType}
        closeHandler={() =>
          setCurrencyFormModal((prev: any) =>
            update(prev, { modalType: false })
          )
        }
        className="d-flex justify-content-center align-items-center"
        width="400px"
      >
        <div className="p-3">
          <GroupBox>
            <Formik initialValues={{ currency: "" }} onSubmit={noop}>
              {() => (
                <Form>
                  <SelectPickerField
                    label="Выберите валюты"
                    options={currencyList}
                    onChanges={(event) => onChangeCurreny(event)}
                    name="currency"
                  />
                </Form>
              )}
            </Formik>
          </GroupBox>
        </div>
      </Modal>
    </TabPage>
  );
}
