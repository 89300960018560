import React from "react";
import TabPage from "../tabs/TabPage";
import ParcelTable from "./ParcelTable";

export default function ParcelTableWrapper() {
    return (
        <TabPage
            childrenClassName="p-2"
        >
            <ParcelTable/>
        </TabPage>
    );
}