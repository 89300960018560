import React, {useState, useCallback, useEffect, useMemo} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useAccountApiContext} from "../../api/account/AccountApiContext";
import {update} from "immupdate";

import TabPage from "../tabs/TabPage";
import AccountForm from "./AccountForm";
import {useParcelApiContext} from "../../api/parcel/ParcelApiContext";
import {useMerchantApiContext} from "../../api/merchant/MerchantApiContext";
import {useCurrencyApiContext} from "../../api/currency/CurrencyApiContext";
import {useBranchApiContext} from "../../api/branch/BranchApiContext";

export default function AccountFormWrapper() {

    const {AccountApi} = useAccountApiContext();
    const {BranchApi} = useBranchApiContext();

    const {ParcelApi} = useParcelApiContext();
    const {MerchantApi} = useMerchantApiContext();
    const {CurrencyApi} = useCurrencyApiContext();
    const [paymentMethods, setPaymentMethods] = useState<any[]>([]);

    const [search, setSearch] = useSearchParams();
    const [senders, setSenders] = useState<any[]>([]);
    const [merchants, setMerchants] = useState<any[]>([]);
    const [currencyTypes, setCurrencyTypes] = useState<any[]>([]);
    const [branches, setBranches] = useState<any[]>([]);
    const [accountTypes, setAccountTypes] = useState<any[]>([]);

    const location = useLocation();
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        name: "",
        number: "",
        merchantId: "",
        userId: "",
        accountTypeId: "",
        currencyId: "",
        branchId:"",
        isActive: true,
    })

    // const accountTypes = [
    //     {
    //         label: "Branch",
    //         value: "Branch"
    //     },
    //     {
    //         label: "Merchant",
    //         value: "Merchant"
    //     },
    //     {
    //         label: "Customer",
    //         value: "Customer"
    //     },
    //     {
    //         label: "Staff",
    //         value: "Staff"
    //     },
    //     {
    //         label: "Manager",
    //         value: "Manager"
    //     },
    //     {
    //         label: "Courier",
    //         value: "Courier"
    //     },
    // ]

    const id = useMemo(() => search.get("accountId"), [search]);

    useEffect(() => {
        if (id) {
            AccountApi.getAccountDetails(Number(id))
                .then((response: any) => {
                    setInitialValues((prev: any) => update(prev, {
                        name: response.name,
                        number: response.number,
                        merchantId: {
                            label: response.merchantName,
                            value: response.merchantId
                        },
                        userId: {
                            label: response.userName,
                            value: response.userId
                        },
                        currencyId: {
                            label: response.currencyName,
                            value: response.currencyId
                        },

                        isActive: response.isActive ? {label: "Активный", value: 1} : {label: "Заблокирован", value: 2},

                        accountTypeId: {
                            label: response.accountTypeName,
                            value: response.accountTypeId
                        },
                        branchId: {
                            label: response.branchName,
                            value: response.branchId
                        },
                        paymentMethodId: {
                            label: response.paymentMethodName,
                            value: response.paymentMethodId
                        }
                    }))
                })
                .catch((err: any) => toast.error(err.message))
        }
    }, [id, setInitialValues, AccountApi]);

    useEffect(() => {
        CurrencyApi.getCurrencyTypeList()
            .then((response: any) => setCurrencyTypes(response.currencies))
            .catch((error) => console.log(error.message));
    }, [CurrencyApi]);

    useEffect(() => {
        AccountApi.getAccountTypeList()
            .then((response: any) => {
                response.accountsTypeList.map((item: any) => {
                    const data = {
                        label: item.name,
                        value: item.id,
                    };
                    setAccountTypes((prev: any) => [...prev, data]);
                });
            })
            .catch((error) => toast.error(error.message));
    }, [setAccountTypes, AccountApi]);


    useEffect(() => {
        BranchApi.getBranchList()
            .then((response: any) => {
                response.branches.map((item: any) => {
                    const data = {
                        label: item.name,
                        value: item.id,
                    };
                    setBranches((prev: any) => [...prev, data]);
                });
            })
            .catch((error) => toast.error(error.message));
    }, [setBranches, BranchApi]);


    const getSendersBySearching = useCallback(
        (value: string) => {
            if (value !== "") {
                ParcelApi.getSearchUserWithSkip(value)
                    .then((response: any) => {
                        let array: any = [];
                        // eslint-disable-next-line array-callback-return
                        response.customers.map((item: any) => {
                            const data = {
                                label: `${item.firstName} ${item.lastName} ${item.phone}`,
                                value: item.id,
                            };
                            array.push(data);
                        });
                        setSenders(array);
                    })
                    .catch((error) => {
                        toast.error(error.message);
                    });
            }
        },
        [setSenders, ParcelApi]
    );

    const getMerchantsBySearching = useCallback(
        (value: string) => {
            if (value !== "") {
                MerchantApi.getMerchantListWithSearch(value)
                    .then((response: any) => {
                        let array: any = [];
                        // eslint-disable-next-line array-callback-return
                        response.customers.map((item: any) => {
                            const data = {
                                label: `${item.tin} ${item.name}`,
                                value: item.id,
                            };
                            array.push(data);
                        });
                        setMerchants(array);
                    })
                    .catch((error) => {
                        toast.error(error.message);
                    });
            }
        },
        [setMerchants, MerchantApi]
    );
    useEffect(() => {
        ParcelApi.getPaymentMethodList()
            .then((response: any) => {
                // eslint-disable-next-line array-callback-return
                response.paymentMethods.map((item: any) => {
                    const data = {
                        label: item.name,
                        value: item.id,
                    };
                    setPaymentMethods((prev: any) => [...prev, data]);
                });
            })
            .catch((error) => toast.error(error.message));
    }, [setPaymentMethods, ParcelApi]);

    const merchantDisabled = useCallback((value: Number) => {
        if (value === 1 || value === 3 || value === 4 || value === 5 || value === 6) return true;
        else return false;
    }, []);

    const userDisabled = useCallback((value: Number) => {
        if (value === 2) return true;
        else return false;
    }, []);

    const userMerchantDisabled = useCallback((value: Number) => {
        if (value === 1) return true;
        else return false;
    }, []);

    //SUBMIT
    const submit = useCallback((value: any) => {
        // console.log(value)
        if (id) {
            const data = {
                ...value,
                id: Number(id),
                merchantId: (merchantDisabled(value.accountTypeId.value) || userMerchantDisabled(value.accountTypeId.value)) ? null : value.merchantId.value,
                userId: (userDisabled(value.accountTypeId.value) || userMerchantDisabled(value.accountTypeId.value)) ? null : value.userId.value,
                currencyId: value.currencyId.value,
                accountTypeId: value.accountTypeId.value,
                paymentMethodId: value.paymentMethodId.value,
                branchId: value.branchId.value,
                isActive: value.isActive.value === 1,
            }
            AccountApi.updateAccount(data).then((response) => {
                toast.success(response.message);
                navigate(`/app/account/${value.accountTypeId.value}`);
            }).catch((error) => toast.error(error.message))
        } else {
            if (!value.accountTypeId.value) {
                toast.error("Error with account type")
            } else {
                // console.log(value.accountTypeId)
                const data = {
                    ...value,
                    merchantId: value.merchantId.value,
                    userId: value.userId.value,
                    currencyId: value.currencyId.value,
                    accountTypeId: value.accountTypeId.value,
                    paymentMethodId: value.paymentMethodId.value,
                    branchId: value.branchId ? value.branchId.value : 0,
                }
                AccountApi.createAccount(data).then((response) => {
                    toast.success(response.message);
                    navigate(`/app/account/${value.accountTypeId.value}`)
                }).catch((error) => toast.error(error.message))
            }
        }
    }, [id, AccountApi, merchantDisabled, userDisabled, userMerchantDisabled, navigate])
    const accountStateOptions = [
        { value: 1, label: "Активный" },
        { value: 2, label: "Заблокирован" }
    ];
    return (
        <TabPage
            childrenClassName="p-3 pt-4"
        >
            <AccountForm
                initialValues={initialValues}
                setInitialValues={setInitialValues}
                currences={currencyTypes}
                branches={branches}
                senders={senders}
                paymentMethods={paymentMethods}
                merchants={merchants}
                accountTypes={accountTypes}
                accountState={accountStateOptions}
                searchSender={getSendersBySearching}
                searchMerchant={getMerchantsBySearching}
                submit={submit}/>
            <div/>
        </TabPage>
    )
}