import React, { useState, useCallback, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import Button from "../button/Button";
import TabPage from "../tabs/TabPage";
import { useParams, useSearchParams } from "react-router-dom";
import IncomesForm from "./IncomesForm";
import { useInocomesApiContext } from "../../api/incomes/IncomesApiContext";
import GetApies, { GetApiesResponse } from "../../api/incomes/CustomApies";
import { useParcelApiContext } from "../../api/parcel/ParcelApiContext";
import { update } from "immupdate";

interface BranchFormWrapperProps{
    readonly back: () => void
}

export default function IncomesFormWrapper({back}:BranchFormWrapperProps){

    const [searchParams, setSearchParams] = useSearchParams();

    const { IncomesApi } = useInocomesApiContext();

    const { ParcelApi } = useParcelApiContext();

    const { tab = "" } = useParams();

    const api: GetApiesResponse = GetApies(tab);

    const id = useMemo(()=>searchParams.get("planId"),[searchParams]);

    const [initialValues, setInitialValues] = useState({
        name: "",
        description: "",
        branchId: "",
    });

    const [branches, setBranches] = useState<any>([]);


    useEffect(()=>{
        if(id){
            IncomesApi.getOneIncome({id: id, api: api.GetOne}).then((response: any)=>{
                setInitialValues((prev: any)=>update(prev, {
                    name: response.name,
                    description: response.description,
                    branchId: {
                        label: response.branchName,
                        value: response.branchId,
                    }
                }))
            }).catch((error)=>toast.error(error.message))
  
        }        
    },[IncomesApi, setInitialValues, id, api.GetOne]);

    useEffect(() => {
        ParcelApi.getInfoParcel()
          .then((response: any) => {
            // eslint-disable-next-line array-callback-return
            response.branch.branches.map((item: any) => {
              const data = {
                label: item.name,
                value: item.id,
              };
              setBranches((user: any) => [...user, data]);
            });
          })
          .catch((error) => console.log(error.message));
      }, [setBranches, ParcelApi]);

    const submit = useCallback((value: any)=>{
       if(id){
        const data = {
            ...value,
            api: api.Update,
            id: id,
            branchId: Number(value.branchId.value)
        }
        IncomesApi.updateIncome(data).then((response: any)=>{
                toast.success(response.message)
                setSearchParams({ pageType: "table" });
            }).catch((err: any)=>toast.error(err.message));
       }else{
        const data = {
            ...value,
            api: api.Create,
            branchId: value.branchId.value
        }
        IncomesApi.createIncome(data).then((response: any)=>{
                toast.success(response.message)
                setSearchParams({pageType: "table"});
            }).catch((err: any)=>toast.error(err.message))
            }
    },[IncomesApi, id, setSearchParams, api.Create, api.Update])

    return (
    <TabPage 
        childrenClassName="p-3 pt-4"
        headerComponent={
            <div className="d-flex align-items-center h-100">
                <Button onClick={back} className="bg-gold text-light px-2 py-1">
                    Назад
                </Button>
            </div>
        }
        >
        <IncomesForm 
            initialValues={initialValues} 
            setInitialValues={setInitialValues} 
            branches={branches}
            submit={submit}/>
    </TabPage>
    )}