import { BaseApi } from "../BaseApi";
import { GetAccountListPagnQuery } from "./CurrencyDto";

export class CurrencyApi extends BaseApi {
  public getCurrencyHistoryListPagn({
    pageNumber,
    pageSize,
  }: GetAccountListPagnQuery) {
    return this.get(`CurrencyHistory/GetCurrencyHistoryListWithPagination`, {
      query: { pageNumber, pageSize },
    });
  }

  public getCurrencyTypeListPagn({
    pageNumber,
    pageSize,
  }: GetAccountListPagnQuery) {
    return this.get(`CurrencyType/GetCurrencyTypeListWithPagination`, {
      query: { pageNumber, pageSize },
    });
  }

  public getCurrencyListForDropDown() {
    return this.get(`CurrencyType/GetCurrencyTypeList`);
  }

  public getOneCurrencyHistory(id: any) {
    return this.get(`CurrencyHistory/GetCurrencyHistoryDetails/${id}`);
  }

  public getCurrencyTypeList() {
    return this.get(`CurrencyType/GetCurrencyTypeList`);
  }

  public getCurrencyTypeListSecond() {
    return this.get(`CurrencyType/GetCurrencyTypeListSecond`);
  }

  public createCurrencyHistory(json: any) {
    return this.post("CurrencyHistory/CreateCurrencyHistory", { json });
  }

  public updateCurrencyHistory(json: any) {
    return this.put("CurrencyHistory/UpdateCurrencyHistory", { json });
  }

  public deleteCurrencyHistory(id: any) {
    return this.delete(`CurrencyHistory/DeleteCurrencyHistory/${id}`);
  }
}
