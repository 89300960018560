import { useMemo } from "react";

import { useApiBase } from "../ApiContext";
import {PayersTypeApi} from "./PayersTypeApi";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

interface Props {
    readonly PayersTypeApi: PayersTypeApi;
}

export function usePayersTypeApiContext(): Props {
    const data = useApiBase();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const api = useMemo(() => new PayersTypeApi(data), []);
    const api = useMemo(() => new PayersTypeApi({...data, navigate, dispatch}), []);

    return {
        PayersTypeApi: api,
    };
}
