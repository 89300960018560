import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useAccountApiContext} from "../../api/account/AccountApiContext";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {useShallowEqualSelector} from "../../hooks/useShallowSelector";
import {profileSelector} from "../../reducers/authReducer";

import YesOrNoModal from "../app/YesOrNoModal";
import Modal from "../modal/Modal";
import TabPage from "../tabs/TabPage";
import AccountTable from "./AccountTable";
import {Pages} from "../../constants/Routes";

export default function AccountTableWrapper() {
    const {tab = "1"} = useParams();

    const navigate = useNavigate();
    const profile = useShallowEqualSelector(profileSelector);

    const {AccountApi} = useAccountApiContext();

    const [data, setData] = useState<any>({});
    const [isDelModal, setIsDelModal] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const [tableLoading, setTableLoading] = useState(false);
    const pageSize = Number(searchParams.get("pageSize") || 25);
    const pageCount = Number(searchParams.get("pageCount") || 1);

    useEffect(() => {
        setTableLoading(true);
        AccountApi.getAccountListPagn({
            pageNumber: pageCount,
            pageSize: pageSize,
            accountTypeId: tab,
        })
            .then((response: any) => {
                setData(response.items);
                setTableLoading(false);
            })

            .catch((error) => {
                toast.error(error.message);
                setTableLoading(false);
            });


    }, [AccountApi, pageCount, pageSize, tab]);

    return (
        <TabPage
            childrenClassName="p-2"

        >
            <AccountTable
                type={tab}

                deleteRow={(value: any) => {
                    AccountApi.deleteAccount(value.id)
                        .then((response) => {
                            toast.success(response.message);
                            window.location.reload();
                        })
                        .catch((err) => toast.error(err.message));
                }}
            />

            <Modal
                width="500px"
                show={isDelModal}
                closeHandler={() => setIsDelModal(false)}
                className="d-flex justify-content-center align-items-center"
            >
                <YesOrNoModal
                    titleText="Вы уверены, что хотите удалить?"
                    onClick={(value: boolean) => {
                        if (value) {
                        } else {
                            setIsDelModal(false);
                        }
                    }}
                />
            </Modal>
        </TabPage>
    );
}
