import { Form, Formik } from "formik";
import { useCallback } from "react";
import { update } from "immupdate";
import { CreateOrderProps } from "../../api/order/OrderDto";

import GroupBox from "../app/GroupBox";
import Button from "../button/Button";
import InputField from "../form/InputField";

interface Props {
  readonly initialValues: CreateOrderProps;
  readonly setInitialValues: (value: any) => void;
  readonly onSubmit: (value: any) => void;
}

export default function CustomerOrderForm({
  initialValues,
  setInitialValues,
  onSubmit,
}: Props) {
  const onChangeName = useCallback(
    (event: any) => {
      setInitialValues((prev: any) =>
        update(prev, {
          name: event?.target.value,
        })
      );
    },
    [setInitialValues]
  );

  const onChangeCost = useCallback(
    (event: any) => {
      setInitialValues((prev: any) =>
        update(prev, {
          cost: event?.target.value,
        })
      );
    },
    [setInitialValues]
  );

  const onChangeLink = useCallback(
    (event: any) => {
      setInitialValues((prev: any) =>
        update(prev, {
          link: event?.target.value,
        })
      );
    },
    [setInitialValues]
  );

  const onChangeDescription = useCallback(
    (event: any) => {
      setInitialValues((prev: any) =>
        update(prev, {
          description: event?.target.value,
        })
      );
    },
    [setInitialValues]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {() => (
        <Form>
          <div className="row p-4">
            <div className="col-12">
              <GroupBox>
                <div className="row">
                  <div className="col-6">
                    <InputField
                      label="Name"
                      name="name"
                      onChange={onChangeName}
                      value={initialValues.name}
                    />
                  </div>
                  <div className="col-6">
                    <InputField
                      label="Cost"
                      name="cost"
                      onChange={onChangeCost}
                      value={initialValues.cost}
                      type="number"
                    />
                  </div>
                  <div className="col-6">
                    <InputField
                      label="Link"
                      name="link"
                      onChange={onChangeLink}
                      value={initialValues.link}
                    />
                  </div>
                  <div className="col-6">
                    <InputField
                      label="Descirption"
                      name="description"
                      onChange={onChangeDescription}
                      value={initialValues.description}
                    />
                  </div>

                  <div className="col-12 mt-2">
                    <Button
                      className="bg-gold text-light px-3 py-1"
                      type="submit"
                    >
                      Сохранить
                    </Button>
                  </div>
                </div>
              </GroupBox>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
