import { useMemo } from "react";
import { useApiBase } from "../ApiContext";
import {StatusManifestApi} from "./StatusManifestApi";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

interface Props{
    readonly StatusManifestApi: StatusManifestApi;
}

export function useStatusManifestApiContext():Props{
    const data = useApiBase();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const api = useMemo(() => new StatusManifestApi(data), []);
    const api = useMemo(() => new StatusManifestApi({...data, navigate, dispatch}), []);

    return {
        StatusManifestApi: api
    }
}