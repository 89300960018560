import { useMemo } from "react";
import { useApiBase } from "../ApiContext";
import { OrderApi } from "./OrderApi";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

interface Props {
  readonly OrderApi: OrderApi;
}

export function useOrderApiContext(): Props {
  const data = useApiBase();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const api = useMemo(() => new OrderApi(data), []);
  const api = useMemo(() => new OrderApi({...data, navigate, dispatch}), []);

  return {
    OrderApi: api,
  };
}
