import {useNavigate, useSearchParams} from 'react-router-dom';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useParcelApiContext} from '../../api/parcel/ParcelApiContext';
import {useCurrencyApiContext} from '../../api/currency/CurrencyApiContext';
import {noop} from 'lodash';
import {Formik, Form} from 'formik';
import {update} from 'immupdate';

import ParcelView from './ParcelView';
import TabPage from '../tabs/TabPage';
import Modal from '../modal/Modal';
import GroupBox from '../app/GroupBox';
import SelectPickerField from '../form/SelectPickerField';
import ModalLoader from "../modal/MdalLoader";
import {toast} from "react-toastify";
import {useIssuedInvoiceApiContext} from "../../api/issued-invoice/IssuedInvoiceContext";

export default function ParcelViewWrapper() {
    const [search] = useSearchParams();
    const [data, setData] = useState({});
    const [dateOnly, setDateOnly] = useState<string>('');
    const [currencyList, setCurrencyList] = useState([]);
    const [loading, setLoading] = useState(false);
    const {IssuedInvoiceApi} = useIssuedInvoiceApiContext();

    const [currencyFormModal, setCurrencyFormModal] = useState<any>({
        modalType: false,
        data: '',
    });

    const [currencyForModalForInvoice, setCurrencyForModalForInvoice] = useState<any>({
        modalType: false,
        data: '',

    });

    const {ParcelApi} = useParcelApiContext();
    const {CurrencyApi} = useCurrencyApiContext();

    const navigate = useNavigate();

    const ID = useMemo(
        () => (search.get('parcelId') ? search.get('parcelId') : ''),
        [search]
    );
    useEffect(() => {
        if (Boolean(ID)) {
            setLoading(true);
            ParcelApi.getParcelDetailsById(ID)
                .then((response: any) => {
                    setLoading(false);
                    setData(response);
                    setDateOnly(response.dateCreated);
                })
                .catch((error: any) => {
                    setLoading(false);
                    // console.log(error);
                });
        }
    }, [ID, setData, ParcelApi]);

    useEffect(() => {
        CurrencyApi.getCurrencyListForDropDown()
            .then((response) => setCurrencyList(response.currencies))
            .catch((error) => console.log(error));
    }, [CurrencyApi]);

    const printParcel = useCallback(
        (value: any) => {
            ParcelApi.getStickerById({id: value.id, code: value.code});
        },
        [ParcelApi]
    );


    const onChangeCurreny = useCallback(
        (value: any) => {
            ParcelApi.getInvoice(
                {id: currencyFormModal?.data?.id, currencyId: value.value},
                currencyFormModal.data.code
            );
            setCurrencyFormModal((prev: any) =>
                update(prev, {
                    modalType: false,
                })
            );
        },
        [currencyFormModal, ParcelApi]
    );

    const onChangeCurrenyForInvoice = useCallback(
        (value: any) => {
             // console.log(value)
             // console.log(currencyForModalForInvoice)
                IssuedInvoiceApi.printIssuid({
                    id: currencyForModalForInvoice?.data?.id,
                    fileName: currencyForModalForInvoice?.data?.code,
                    currencyId: value.value,
                });

            setCurrencyForModalForInvoice((prev: any) =>
                update(prev, {
                    modalType: false,
                })
            );
        },
        [currencyForModalForInvoice, IssuedInvoiceApi]
    );


    const printParcelItem = useCallback(
        (value: any) => {
            ParcelApi.getStickerByCode({id: value.id, itemCode: value.code});
        },
        [ParcelApi]
    );
    // dateCreated={initialValues.dateCreated ? initialValues.dateCreated.split(' ')[0] : ''}
    return (
        <TabPage childrenClassName="p-3">
            <ParcelView
                data={data}
                dateOnly={dateOnly ? dateOnly.split(' ')[0] : ''}
                printParcel={printParcel}
                editParcel={(id) =>
                    // navigate(`/app/parcels/parcel-form?parcelId=${id}`)
                    navigate(`/app/parcel-form?parcelId=${id}`)
                }
                printParcel2={(value: any) => {
                    setCurrencyFormModal((prev: any) =>
                        update(prev, {
                            modalType: true,
                            data: value,
                        })
                    );
                }}

                printParcel3={(value: any) => {
                    setCurrencyForModalForInvoice((prev: any) =>
                        update(prev, {
                            modalType: true,
                            data: value,
                        })
                    );
                }}
                
                printParcelItem={printParcelItem}
            />
            <Modal
                show={currencyFormModal.modalType}
                closeHandler={() =>
                    setCurrencyFormModal((prev: any) =>
                        update(prev, {modalType: false})
                    )
                }
                className="d-flex justify-content-center align-items-center"
                width="400px"
            >
                <div className="p-3">
                    <GroupBox>
                        <Formik
                            initialValues={{currency: ''}}
                            onSubmit={noop}
                        >
                            {() => (
                                <Form>
                                    <SelectPickerField
                                        label="Выберите валюту"
                                        options={currencyList}
                                        onChanges={(event) =>
                                            onChangeCurreny(event)
                                        }
                                        name="currency"
                                    />
                                </Form>
                            )}
                        </Formik>
                    </GroupBox>
                </div>
            </Modal>


            {/*forInvoice*/}
            <Modal
                show={currencyForModalForInvoice.modalType}
                closeHandler={() =>
                    setCurrencyForModalForInvoice((prev: any) =>
                        update(prev, {modalType: false})
                    )
                }
                className="d-flex justify-content-center align-items-center"
                width="400px"
            >
                <div className="p-3">
                    <GroupBox>
                        <Formik
                            initialValues={{currency: ''}}
                            onSubmit={noop}
                        >
                            {() => (
                                <Form>
                                    <SelectPickerField
                                        label="Выберите валюту"
                                        options={currencyList}
                                        onChanges={(event) =>
                                            onChangeCurrenyForInvoice(event)
                                        }
                                        name="currency"
                                    />
                                </Form>
                            )}
                        </Formik>
                    </GroupBox>
                </div>
            </Modal>

            <ModalLoader open={loading}/>

        </TabPage>
    );
}
