import { useMemo } from "react";
import { MerchantApi } from "./MerchantApi";
import { useApiBase } from "../ApiContext";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

interface Props {
  readonly MerchantApi: MerchantApi;
}

export function useMerchantApiContext(): Props {
  const data = useApiBase();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const api = useMemo(() => new MerchantApi(data), []);
  const api = useMemo(() => new MerchantApi({...data, navigate, dispatch}), []);

  return {
    MerchantApi: api,
  };
}
