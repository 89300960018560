import {DateFormatter} from "../../utils/DateFormatter";
import TableButton from "../button/TableButton";
import PrintIcon from "../icons/PrintIcon";
import Table from "../table/Table";
import React, {useEffect, useState} from "react";
import {CheckUserClaim} from "../../utils/CheckUserInfo";
import {useShallowEqualSelector} from "../../hooks/useShallowSelector";
import {profileSelector} from "../../reducers/authReducer";

interface Props {
    readonly data: any[];
    readonly loading: boolean;
    readonly setIds: (value: any) => void;
    readonly onPrint: (value: any) => void;
}

export default function ByCourierParcelTable({
                                                 data,
                                                 loading,
                                                 setIds,
                                                 onPrint,
                                             }: Props) {

    const [tableHeaders, setTableHeaders] = useState([]);

    const profile = useShallowEqualSelector(profileSelector);

    const [headers] = useState([
        {
            header: '№',
            access: 'id',
            width: 60,
        },
        {
            header: 'Код',
            access: 'code',
            width: 100,
        },

        {
            header: 'Отправитель',
            access: 'sender',
            width: 200,
            ceil: (row: any) => {
                return <>{row.sender}</>;
            },
        },
        {
            header: 'Получатель',
            access: 'recipient',
            width: 200,
            ceil: (row: any) => {
                return <>{row.recipient}</>;
            },
        },
        {
            header: 'Направление',
            access: 'nap',
            width: 160,
            ceil: (row: any) => {
                return (
                    <>
                        {row.fromBranch} - {row.toBranch}
                    </>
                );
            },
        },
        {
            header: 'Тариф',
            access: 'parcelPlan',
            width: 100,
            ceil: (row: any) => {
                return <>{row.parcelPlan}</>;
            },
        },
        {
            header: 'Вес',
            access: 'parcelSize',
            width: 110,
            ceil: (row: any) => {
                return <>{row.parcelSize}</>;
            },
        },
        {
            header: 'Место',
            access: 'plan',
            width: 80,
            ceil: (row: any) => {
                return <>{row.numberOfPoint}</>;
            },
        },
        {
            header: 'Статус',
            access: 'generalStatus',
            width: 80
        },
        {
            header: 'Итого в USD',
            access: 'summa',
            width: 70,
            ceil: (row: any) => {
                return (
                    <div style={{color: row.generalStateUsd ? 'green':'red',fontWeight: 'bold', textAlign:'center' }}>
                        {row.totalAmountUsd}
                    </div>
                );
            },
        },
        {
            header: 'Итого в местной валюте',
            access: 'summa',
            width: 85,
            ceil: (row: any) => {
                return (
                    <div style={{color: row.generalStateLocal ? 'green':'red',fontWeight: 'bold'}}>
                        {row.totalAmountLocal}
                    </div>
                );
            },
        },
        // {
        //   header: "Статус",
        //   access: "status",
        //   width: 100,
        //   ceil: (row: any) => {
        //     return row.parcelStatus.map((item: any, index: number) => {
        //       if (item.isCurrent) {
        //         return (
        //           <div
        //             key={index}
        //             className="text-success fw-bold rounded ps-2 py-1 mb-1"
        //           >
        //             {item.statuses.name}
        //           </div>
        //         );
        //       }
        //     });
        //   },
        // },
        // {
        //   header: "Состояние в USD",
        //   access: "overdue",
        //   width: 100,
        //   ceil: (row: any) => {
        //     return <>{row.parcelCost.stateUsd}</>;
        //   },
        // },
        // {
        //   header: "Состояние в местной валюте",
        //   access: "overdue",
        //   width: 100,
        //   ceil: (row: any) => {
        //     return <>{row.parcelCost.stateLocal}</>;
        //   },
        // },
        // {
        //     header: 'Прошло',
        //     access: 'overdue',
        //     width: 80,
        //     ceil: (row: any) => {
        //         return <>{row.parcelOverdue.overdue} дней</>;
        //     },
        // },
        {
            header: 'Оператор',
            access: 'senderStaff',
            width: 200,
        },
        {
            header: 'Дата',
            access: 'date',
            width: 120,
            ceil: (row: any) => {
                return <div style={{ textAlign:'center' }}>{DateFormatter(row.dateCreated)}</div>;
            },
        },

        {
            header: "...",
            access: "edit",
            width: 60,
            ceil: (row: any) => {
                return (
                    <TableButton onClick={() => onPrint(row)} className="bg-success">
                        <PrintIcon color="white"/>
                    </TableButton>
                );
            },
        },
    ]);

    useEffect(() => {
        const data: any = [...headers];
        data.splice(data.length - 1, 1);
         setTableHeaders(data)
    }, [headers, profile])

    return (
        <Table
            withCheckbox
            selectRowCheckbox={setIds}
            headers={tableHeaders}
            onPrint={onPrint}
            data={data}
            loading={loading}
        />
    );
}
