import { useParams } from "react-router-dom";
import OrdersTableWrapper from "./OrdersTableWrapper";
import OrdersFormWrapper from "./OrdersFormWrapper";

export default function OrdersTab() {
  const { tab = "table" } = useParams();

  return (
    <>
      {tab === "table" && <OrdersTableWrapper />}
      {tab === "form" && <OrdersFormWrapper />}
    </>
  );
}
