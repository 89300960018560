import { IdProps } from "../AppDto";
import { BaseApi } from "../BaseApi";
import { GetAllRole } from "./StatusDto";

export class StatusApi extends BaseApi {
  public getAllStatus({ pageNumber, pageSize }: GetAllRole): Promise<any> {
    return this.get(
      `Status/GetStatusListWithPagination?pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
  }

  public getAllStatusWithoutPagination(): Promise<any> {
    return this.get(`Status/GetStatusList`);
  }

  public getAllStatusItemList(): Promise<any> {
    return this.get(`Status/GetStatusItemList`);
  }

  public getStatusById({ id }: IdProps): Promise<any> {
    return this.get(`Status/GetStatusDetails/${id}`);
  }

  public deleteStatus(json: any): Promise<any> {
    return this.post(`Status/DeleteStatuses`, { json });
  }

  public updateStatus(json: any) {
    return this.put("Status/UpdateStatus", { json });
  }

  public createStatus(json: any) {
    return this.post("Status/CreateStatus", { json });
  }
}
