import { IdProps } from "../AppDto";
import { BaseApi } from "../BaseApi";
import { GetAllRole } from "./BranchDto";

export class BranchApi extends BaseApi {
  public getAllBranch({ pageNumber, pageSize }: GetAllRole): Promise<any> {
    return this.get(
      `Branch/GetBranchListWithPagination?pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
  }

  public getBranchById({ id }: IdProps): Promise<any> {
    return this.get(`Branch/GetBranchDetails/${id}`);
  }

  public getBranchList(): Promise<any> {
    return this.get(`Branch/GetBranchList`);
  }



  public deleteBranch(del: any): Promise<any> {
    return this.post(`Branch/DeleteBranches`, del);
  }

  public updateBranch(json: any) {
    return this.put("Branch/UpdateBranch", { json });
  }

  public createBranch(json: any) {
    return this.post("Branch/CreateBranch", { json });
  }
}
