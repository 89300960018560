import { useMemo } from "react";
import { useApiBase } from "../ApiContext";
import {DriverTypeApi} from "./DriverTypeApi";
import {useDispatch} from "react-redux";

interface Props{
  readonly DriverTypeApi: DriverTypeApi;
}

export function useDriverTypeApiContext():Props{
  const data = useApiBase();
  const dispatch = useDispatch();
  // const api = useMemo(() => new DriverTypeApi(data), []);
  const api = useMemo(() => new DriverTypeApi({...data,  dispatch}), []);

  return {
    DriverTypeApi: api
  }
}