import TabPage from "../tabs/TabPage";
import CurrencyTable from "./CurrencyTable";

export default function CurrencyTableWrapper() {

    return (
        <TabPage childrenClassName="p-2">
            <CurrencyTable/>
        </TabPage>
    );
}
