import './assets/app-menu.scss';

import {SaveActiveItem} from '../../utils/SaveActiveItem';
import React, {useCallback, useMemo} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {RouteContainerTabs} from '../../constants/Routes';
import {AdministratorTabs} from '../../containers/AdministratorContainer';
import {UserTabs} from '../../containers/UsersContainer';
import {useShallowEqualSelector} from '../../hooks/useShallowSelector';
import {profileSelector} from '../../reducers/authReducer';
import {useDispatch} from 'react-redux';
import {switchHeaderTitle} from '../../reducers/appReducer';
import AppMenu from './AppMenu';
import SideBarItem from './SideBarItem';
import BoxsIcon from '../icons/BoxsIcon';
import EditIcon from '../icons/EditIcon';
import BookIcon from '../icons/BookIcon';
import UsersIcon from '../icons/UsersIcon';
import AddIcon from "../icons/AddIcon";
import HomeIcon from "../icons/HomeIcon";
import ManagerIcon from "../icons/ManagerIcon";
import ParcelIcon from "../icons/ParcelIcon";
import PlanIcon from "../icons/PlanIcon";
import InformationIcon from "../icons/InformationIcon";
import NewsIcon from "../icons/NewsIcon";

export default function SideBarMenu() {
    const location = useLocation();
    const dispatch = useDispatch();
    const tab = useMemo(
        () => SaveActiveItem('tab', location.pathname),
        [location.pathname]
    );

    const childTab = useMemo(
        () => SaveActiveItem('childTab', location.pathname),
        [location.pathname]
    );


    const navigate = useNavigate();

    const profile: any = useShallowEqualSelector(profileSelector);

    const checkRole = useCallback(
        (role: string) => {
            const hasRole = profile.role.filter((ro: string) => ro === role);

            return hasRole.length > 0 ? true : false;
        },
        [profile]
    );

    const checkClaim = useCallback(
        (claim: string) => {
            return Boolean(profile[claim]);
        },
        [profile]
    );

    return (
        <AppMenu
            activeTab={tab}
            defaultTab={RouteContainerTabs.Administrator}
            onChangeTab={(value, title) => {
                navigate(`/app/${value}`);
                dispatch(switchHeaderTitle({headerTitle: title}));
            }}
        >
            <SideBarItem
                key={RouteContainerTabs.Dashboard}
                icon={<HomeIcon color="white"/>}
            >
                Главная
            </SideBarItem>
            <SideBarItem
                key={RouteContainerTabs.Administrator}
                icon={<ManagerIcon color="white"/>}
                show={checkRole('Administrator')}
                responsiveContent={
                    <AppMenu
                        defaultTab={AdministratorTabs.RoleManagerTab}
                        activeTab={childTab}
                        onChangeTab={(value, title) => {
                            navigate(`/app/administrator/${value}`);
                            dispatch(
                                switchHeaderTitle({
                                    headerTitle: `Администратор / ${title}`,
                                })
                            );
                        }}
                    >
                        <SideBarItem key={AdministratorTabs.RoleManagerTab}>
                            Роли
                        </SideBarItem>
                        <SideBarItem key={AdministratorTabs.PlanTab}>
                            Тарифы
                        </SideBarItem>
                        <SideBarItem key={AdministratorTabs.StatusTab}>
                            Статусы посылок
                        </SideBarItem>
                        <SideBarItem key={AdministratorTabs.BranchesTab}>
                            Филиалы
                        </SideBarItem>
                        <SideBarItem key={AdministratorTabs.ExpensesTab}>
                            Типы затрат
                        </SideBarItem>
                        <SideBarItem key={AdministratorTabs.IncomesTab}>
                            Типы приходов
                        </SideBarItem>
                        <SideBarItem key={AdministratorTabs.SalaryTab}>
                            Типы зарплат
                        </SideBarItem>
                        <SideBarItem key={AdministratorTabs.Currency}>
                            Типы валют
                        </SideBarItem>
                    </AppMenu>
                }
            >
                Администратор
            </SideBarItem>


            <SideBarItem
                show={checkClaim(
                    'CreateUser'
                )}
                key={RouteContainerTabs.AddUser}
                icon={<AddIcon color="white"/>}
            >
                Новый пользователь
            </SideBarItem>

            <SideBarItem
                key={RouteContainerTabs.Users}
                icon={<UsersIcon color="white"/>}
                show={
                    (checkRole('Administrator') ||
                        checkRole('Manager') ||
                        checkRole('Staff')) &&
                    checkClaim('GetUserListWithPaginationWithSearchText')
                }
                responsiveContent={
                    <AppMenu
                        activeTab={childTab}
                        defaultTab="add-user"
                        onChangeTab={(value, title) => {
                            navigate(`/app/users/${value}`);
                            dispatch(
                                switchHeaderTitle({
                                    headerTitle: `Пользователи / ${title}`,
                                })
                            );
                        }}
                    >
                        <SideBarItem
                            show={checkClaim(
                                'GetUserListWithPaginationWithSearchText'
                            )}
                            key={UserTabs.Customers}
                        >
                            Клиенты
                        </SideBarItem>
                        <SideBarItem
                            show={
                                (checkRole('Administrator') ||
                                    checkRole('Manager')) &&
                                checkClaim(
                                    'GetUserListWithPaginationWithSearchText'
                                )
                            }
                            key={UserTabs.Staff}
                        >
                            Сотрудники
                        </SideBarItem>
                        <SideBarItem
                            show={checkRole('Administrator')}
                            key={UserTabs.Managers}
                        >
                            Менеджеры
                        </SideBarItem>

                        <SideBarItem
                            show={checkClaim(
                                'GetUserListWithPaginationWithSearchText'
                            ) && checkRole('Manager') || checkRole('Administrator')}
                            key={UserTabs.Couriers}
                        >
                            Курьеры
                        </SideBarItem>

                    </AppMenu>
                }
            >
                Пользователи
            </SideBarItem>

            {/*<SideBarItem*/}
            {/*    show={checkClaim(*/}
            {/*        'CreateParcel'*/}
            {/*    )}*/}
            {/*    key={RouteContainerTabs.AddParcel}*/}
            {/*    icon={<AddIcon color="white"/>}*/}
            {/*>*/}
            {/*    Новая посылка*/}
            {/*</SideBarItem>*/}

            {/*New Version*/}
            <SideBarItem
                show={checkClaim(
                    'CreateParcel'
                )}
                key={RouteContainerTabs.AddParcelSecond}
                icon={<AddIcon color="white"/>}
            >
                Новая посылка
            </SideBarItem>

            <SideBarItem
                show={
                    (checkRole('Administrator') ||
                        checkRole('Manager') ||
                        checkRole('Staff')) &&
                    checkClaim('GetParcelListWithPaginationByCode')
                }
                key={RouteContainerTabs.Parcels}
                icon={<ParcelIcon color="white"/>}
                responsiveContent={
                    <AppMenu
                        activeTab={childTab}
                        defaultTab="all-parcels"
                        onChangeTab={(value, title) => {
                            navigate(`/app/parcels/${value}`);
                            dispatch(
                                switchHeaderTitle({
                                    headerTitle: `Посылки / ${title}`,
                                })
                            );
                        }}
                    >
                        {/*<SideBarItem*/}
                        {/*    show={checkClaim(*/}
                        {/*        'CreateParcel'*/}
                        {/*    )}*/}
                        {/*    key="parcel-form"*/}
                        {/*>*/}
                        {/*    Новая посылка*/}
                        {/*</SideBarItem>*/}

                        <SideBarItem
                            show={checkClaim(
                                'GetParcelListWithPaginationByCode'
                            )}
                            key="all-parcels"
                        >
                            Все
                        </SideBarItem>
                        {/*<SideBarItem*/}
                        {/*    show={checkClaim(*/}
                        {/*        'GetParcelListWithPaginationByCode'*/}
                        {/*    )}*/}
                        {/*    key="all-parcels-closed"*/}
                        {/*>*/}
                        {/*    Все доставленные*/}
                        {/*</SideBarItem>*/}
                        <SideBarItem
                            show={checkRole('Administrator') && checkClaim(
                                'GetParcelListWithPaginationByCourier'
                            )}
                            key="by-couriers"
                        >
                            По курьерам
                        </SideBarItem>

                        <SideBarItem
                            show={checkRole('Administrator') && checkClaim(
                                'GetParcelListWithPaginationByCourier'
                            )}
                            key="by-customers"
                        >
                            По клиентам
                        </SideBarItem>
                    </AppMenu>
                }
            >
                Посылки
            </SideBarItem>

            <SideBarItem
                key={RouteContainerTabs.Account}
                icon={<PlanIcon color="white"/>}
                show={checkRole('Administrator') || checkRole('Manager')}
                responsiveContent={
                    <AppMenu
                        activeTab={childTab}
                        defaultTab="all-parcels"
                        onChangeTab={(value, title) => {
                            navigate(`/app/account/${value}`);
                            dispatch(
                                switchHeaderTitle({
                                    headerTitle: `Счета / ${title}`,
                                })
                            );
                        }}
                    >
                        <SideBarItem
                            show={checkClaim(
                                'CreateAccount'
                            )}
                            key="form"
                        >
                            Новый счет
                        </SideBarItem>

                        <SideBarItem
                            show={checkClaim(
                                'GetAccountListWithPagination'
                            )}
                            key="1"
                        >
                            Счета филиалов
                        </SideBarItem>
                        <SideBarItem
                            show={checkClaim(
                                'GetAccountListWithPagination1'
                            )}
                            key="2"
                        >
                            Счета юр. лиц
                        </SideBarItem>

                        <SideBarItem
                            show={checkClaim(
                                'GetAccountListWithPagination1'
                            )}
                            key="3"
                        >
                            Счета менеджеров
                        </SideBarItem>
                        <SideBarItem
                            show={checkClaim(
                                'GetAccountListWithPagination1'
                            )}
                            key="4"
                        >
                            Счета сотрудников
                        </SideBarItem>
                        <SideBarItem
                            show={checkClaim(
                                'GetAccountListWithPagination1'
                            )}
                            key="5"
                        >
                            Счета курьеров
                        </SideBarItem>
                        <SideBarItem
                            show={checkClaim(
                                'GetAccountListWithPagination1'
                            )}
                            key="6"
                        >
                            Счета клиентов
                        </SideBarItem>
                    </AppMenu>
                }
            >
                Счета
            </SideBarItem>
            {/*<SideBarItem*/}
            {/*    show={*/}
            {/*        (checkRole('Administrator') || checkRole('Manager')) &&*/}
            {/*        checkClaim('GetAccountHistoryListWithPagination')*/}
            {/*    }*/}
            {/*    key={RouteContainerTabs.Salary}*/}
            {/*    icon={<InformationIcon color="white"/>}*/}
            {/*>*/}
            {/*    Зарплаты*/}
            {/*</SideBarItem>*/}


            <SideBarItem
                key={RouteContainerTabs.Salary}
                icon={<PlanIcon color="white"/>}
                show={
                    (checkRole('Administrator') || checkRole('Manager')) &&
                    checkClaim('GetAccountHistoryListWithPagination')
                }
                responsiveContent={
                    <AppMenu
                        activeTab={childTab}
                        defaultTab="all-currency"
                        onChangeTab={(value, title) => {
                            navigate(`/app/salary/${value}`);
                            dispatch(
                                switchHeaderTitle({
                                    headerTitle: `Зарплаты / ${title}`,
                                })
                            );
                        }}
                    >
                        <SideBarItem
                            show={checkClaim(
                                'CreateAccountHistory'
                            )}
                            key="form"
                        >
                            Новая зарплата
                        </SideBarItem>
                        <SideBarItem
                            show={
                                (checkRole('Administrator') ||
                                    checkRole('Manager') ||
                                    checkRole('Staff')) &&
                                checkClaim('GetAccountHistoryListWithPagination')
                            }
                            key=''
                        >
                            Все
                        </SideBarItem>
                    </AppMenu>
                }
            >
                Зарплаты
            </SideBarItem>



            <SideBarItem
                show={
                    (checkRole('Administrator') || checkRole('Manager')) &&
                    checkClaim('GetAccountHistoryListWithPagination1')
                }
                key={RouteContainerTabs.Orders}
                icon={<NewsIcon color="white"/>}
            >
                Заказы
            </SideBarItem>

            <SideBarItem
                show={checkRole('Courier')}
                key={RouteContainerTabs.Couriers}
                icon={<BoxsIcon color="white"/>}
            >
                Couriers
            </SideBarItem>
            {/* <SideBarItem
        show={
          (checkRole("Administrator") || checkRole("Manager")) &&
          checkClaim("GetAccountHistoryListWithPagination")
        }
        key={RouteContainerTabs.Customers}
        icon={<BoxsIcon color="white" />}
        responsiveContent={
          <AppMenu
            activeTab={childTab}
            defaultTab="all-parcels"
            onChangeTab={(value, title) => {
              navigate(`/app/customers/${value}`);
              dispatch(
                switchHeaderTitle({ headerTitle: `Посылки / ${title}` })
              );
            }}
          >
            <SideBarItem
              show={checkClaim("GetParcelListWithPaginationByCode")}
              key="customer-dashboard"
            >
              Dashboard
            </SideBarItem>
            <SideBarItem
              show={checkClaim("GetParcelListWithPaginationByCode")}
              key="customer-parcels"
            >
              Parcels
            </SideBarItem>
            <SideBarItem
              show={checkClaim("GetParcelListWithPaginationByCourier")}
              key="customer-orders"
            >
              Заказы
            </SideBarItem>
          </AppMenu>
        }
      >
        Customers
      </SideBarItem> */}

            {/*<SideBarItem*/}
            {/*    show={*/}
            {/*        (checkRole('Administrator') || checkRole('Manager')) &&*/}
            {/*        checkClaim('GetAccountHistoryListWithPagination')*/}
            {/*    }*/}
            {/*    key={RouteContainerTabs.Incomes}*/}
            {/*    icon={<BoxsIcon color="white"/>}*/}
            {/*>*/}
            {/*    Приходы*/}
            {/*</SideBarItem>*/}

            <SideBarItem
                key={RouteContainerTabs.Incomes}
                icon={<PlanIcon color="white"/>}
                show={
                    (checkRole('Administrator') ||
                        checkRole('Manager') ||
                        checkRole('Staff')) &&
                    checkClaim('GetAccountHistoryListWithPagination')
                }
                responsiveContent={
                    <AppMenu
                        activeTab={childTab}
                        defaultTab="all-currency"
                        onChangeTab={(value, title) => {
                            navigate(`/app/incomes/${value}`);
                            dispatch(
                                switchHeaderTitle({
                                    headerTitle: `Приходы / ${title}`,
                                })
                            );
                        }}
                    >
                        <SideBarItem
                            show={checkClaim(
                                'CreateAccountHistory'
                            )}
                            key="form"
                        >
                            Новый приход
                        </SideBarItem>
                        <SideBarItem
                            show={
                                (checkRole('Administrator') ||
                                    checkRole('Manager') ||
                                    checkRole('Staff')) &&
                                checkClaim('GetAccountHistoryListWithPagination')
                            }
                            key=''
                        >
                            Все
                        </SideBarItem>
                    </AppMenu>
                }
            >
                Приходы
            </SideBarItem>

            <SideBarItem
                key={RouteContainerTabs.Expense}
                icon={<PlanIcon color="white"/>}
                show={
                    (checkRole('Administrator') ||
                        checkRole('Manager') ||
                        checkRole('Staff')) &&
                    checkClaim('GetAccountHistoryListWithPagination')
                }
                responsiveContent={
                    <AppMenu
                        activeTab={childTab}
                        defaultTab="all-currency"
                        onChangeTab={(value, title) => {
                            navigate(`/app/expense/${value}`);
                            dispatch(
                                switchHeaderTitle({
                                    headerTitle: `Затраты / ${title}`,
                                })
                            );
                        }}
                    >
                        <SideBarItem
                            show={checkClaim(
                                'CreateAccountHistory'
                            )}
                            key="form"
                        >
                            Новая затрата
                        </SideBarItem>
                        <SideBarItem
                            show={
                                (checkRole('Administrator') ||
                                    checkRole('Manager') ||
                                    checkRole('Staff')) &&
                                checkClaim('GetAccountHistoryListWithPagination')
                            }
                            key=''
                        >
                            Все
                        </SideBarItem>
                    </AppMenu>
                }
            >
                Затраты
            </SideBarItem>

            {/*<SideBarItem*/}
            {/*    show={*/}
            {/*        (checkRole('Administrator') || checkRole('Manager')) &&*/}
            {/*        checkClaim('GetAccountHistoryListWithPagination1')*/}
            {/*    }*/}
            {/*    key={RouteContainerTabs.Expense}*/}
            {/*    icon={<BoxsIcon color="white"/>}*/}
            {/*>*/}
            {/*    Затраты*/}
            {/*</SideBarItem>*/}

            {/*<SideBarItem*/}
            {/*    show={*/}
            {/*        (checkRole('Administrator1') || checkRole('Manager1')) &&*/}
            {/*        checkClaim('GetAccountHistoryListWithPagination1')*/}
            {/*    }*/}
            {/*    key={RouteContainerTabs.IncomeParcel}*/}
            {/*    icon={<BoxsIcon color="white"/>}*/}
            {/*>*/}
            {/*    История счетов*/}
            {/*</SideBarItem>*/}
            {/*<SideBarItem*/}
            {/*    show={*/}
            {/*        (checkRole('Administrator') || checkRole('Manager')) &&*/}
            {/*        checkClaim('GetCurrencyHistoryListWithPagination')*/}
            {/*    }*/}
            {/*    key={RouteContainerTabs.Currency}*/}
            {/*    icon={<BoxsIcon color="white"/>}*/}
            {/*>*/}
            {/*    Курс валюты*/}
            {/*</SideBarItem>*/}

            <SideBarItem
                key={RouteContainerTabs.Currency}
                icon={<PlanIcon color="white"/>}
                show={
                    (checkRole('Administrator') ||
                        checkRole('Manager') ||
                        checkRole('Staff')) &&
                    checkClaim('GetManifestListWithPagination')
                }
                responsiveContent={
                    <AppMenu
                        activeTab={childTab}
                        defaultTab="all-currency"
                        onChangeTab={(value, title) => {
                            navigate(`/app/currency/${value}`);
                            dispatch(
                                switchHeaderTitle({
                                    headerTitle: `Курс валюты / ${title}`,
                                })
                            );
                        }}
                    >
                        <SideBarItem
                            show={checkClaim(
                                'CreateCurrencyHistory'
                            )}
                            key="form"
                        >
                            Новый курс
                        </SideBarItem>
                        <SideBarItem
                            show={
                                (checkRole('Administrator') ||
                                    checkRole('Manager') ||
                                    checkRole('Staff')) &&
                                checkClaim('GetCurrencyHistoryListWithPagination')
                            }
                            key=''
                        >
                            Все
                        </SideBarItem>
                    </AppMenu>
                }
            >
                Курс валюты
            </SideBarItem>


            {/*<SideBarItem*/}
            {/*    key={RouteContainerTabs.Merchant}*/}
            {/*    icon={<BoxsIcon color="white"/>}*/}
            {/*    show={*/}
            {/*        (checkRole('Administrator') || checkRole('Manager') || checkRole('Staff')) &&*/}
            {/*        checkClaim('GetMerchantListWithPagination')*/}
            {/*    }*/}
            {/*>*/}
            {/*    Юр. лица*/}
            {/*</SideBarItem>*/}

            <SideBarItem
                key={RouteContainerTabs.Merchant}
                icon={<PlanIcon color="white"/>}
                show={
                    (checkRole('Administrator') ||
                        checkRole('Manager') ||
                        checkRole('Staff')) &&
                    checkClaim('GetManifestListWithPagination')
                }
                responsiveContent={
                    <AppMenu
                        activeTab={childTab}
                        defaultTab="all-merchant"
                        onChangeTab={(value, title) => {
                            navigate(`/app/merchant/${value}`);
                            dispatch(
                                switchHeaderTitle({
                                    headerTitle: `Манифесты / ${title}`,
                                })
                            );
                        }}
                    >
                        <SideBarItem
                            show={checkClaim(
                                'CreateMerchant'
                            )}
                            key="form"
                        >
                            Новый контрагент
                        </SideBarItem>
                        <SideBarItem
                            show={
                                (checkRole('Administrator') ||
                                    checkRole('Manager') ||
                                    checkRole('Staff')) &&
                                checkClaim('GetMerchantListWithPagination')
                            }
                            key=""
                        >
                            Все
                        </SideBarItem>
                    </AppMenu>
                }
            >
                Контрагенты
            </SideBarItem>


            <SideBarItem
                show={
                    (checkRole('Administrator') ||
                        checkRole('Manager') ||
                        checkRole('Staff')) &&
                    checkClaim('GetIssuedInvoiceListWithPagination')
                }
                key="issued-invoice"
                icon={<EditIcon color="white"/>}
            >
                Выставленные счета
            </SideBarItem>


            {/*<SideBarItem*/}
            {/*    key={RouteContainerTabs.IssuedInvoice}*/}
            {/*    icon={<PlanIcon color="white"/>}*/}
            {/*    show={*/}
            {/*        (checkRole('Administrator') ||*/}
            {/*            checkRole('Manager') ||*/}
            {/*            checkRole('Staff')) &&*/}
            {/*        checkClaim('GetIssuedInvoiceListWithPagination')*/}
            {/*    }*/}
            {/*    responsiveContent={*/}
            {/*        <AppMenu*/}
            {/*            activeTab={childTab}*/}
            {/*            defaultTab="all-merchant"*/}
            {/*            onChangeTab={(value, title) => {*/}
            {/*                navigate(`/app/issued-invoice/${value}`);*/}
            {/*                dispatch(*/}
            {/*                    switchHeaderTitle({*/}
            {/*                        headerTitle: `Выставленные счета / ${title}`,*/}
            {/*                    })*/}
            {/*                );*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            <SideBarItem*/}
            {/*                show={checkClaim(*/}
            {/*                    'CreateIssuedInvoice1'*/}
            {/*                )}*/}
            {/*                key="form"*/}
            {/*            >*/}
            {/*                Выставить счет*/}
            {/*            </SideBarItem>*/}
            {/*            <SideBarItem*/}
            {/*                show={*/}
            {/*                    (checkRole('Administrator') ||*/}
            {/*                        checkRole('Manager') ||*/}
            {/*                        checkRole('Staff')) &&*/}
            {/*                    checkClaim('GetIssuedInvoiceListWithPagination')*/}
            {/*                }*/}
            {/*                key=""*/}
            {/*            >*/}
            {/*                Все*/}
            {/*            </SideBarItem>*/}
            {/*        </AppMenu>*/}
            {/*    }*/}
            {/*>*/}
            {/*    Выставленные счета*/}
            {/*</SideBarItem>*/}

            {/*<SideBarItem*/}
            {/*    show={*/}
            {/*        (checkRole('Administrator') ||*/}
            {/*            checkRole('Manager') ||*/}
            {/*            checkRole('Staff')) &&*/}
            {/*        checkClaim('GetManifestListWithPagination')*/}
            {/*    }*/}
            {/*    key={RouteContainerTabs.Manifest}*/}
            {/*    icon={<EditIcon color="white"/>}*/}
            {/*>*/}
            {/*    Манифест*/}
            {/*</SideBarItem>*/}

            {/*Manifest*/}
            <SideBarItem
                key={RouteContainerTabs.Manifest}
                icon={<PlanIcon color="white"/>}
                show={
                    (checkRole('Administrator') ||
                        checkRole('Manager') ||
                        checkRole('Staff')) &&
                    checkClaim('GetManifestListWithPagination')
                }
                responsiveContent={
                    <AppMenu
                        activeTab={childTab}
                        defaultTab="all-manifest"
                        onChangeTab={(value, title) => {
                            navigate(`/app/manifest/${value}`);
                            dispatch(
                                switchHeaderTitle({
                                    headerTitle: `Манифесты / ${title}`,
                                })
                            );
                        }}
                    >
                        <SideBarItem
                            show={checkClaim(
                                'CreateManifest'
                            )}
                            key="form"
                        >
                            Новый манифест
                        </SideBarItem>
                        <SideBarItem
                            show={
                                (checkRole('Administrator') ||
                                    checkRole('Manager') ||
                                    checkRole('Staff')) &&
                                checkClaim('GetManifestListWithPagination')
                            }
                            key=""
                        >
                            Все
                        </SideBarItem>
                    </AppMenu>
                }
            >
                Манифесты
            </SideBarItem>

            <SideBarItem
                show={
                    (checkRole('Administrator') ||
                        checkRole('Manager') ||
                        checkRole('Staff')) &&
                    checkClaim('UpdateParcelsStatusByCode')
                }
                key="edit-parcel"
                icon={<EditIcon color="white"/>}
            >
                Статус посылок
            </SideBarItem>
            <SideBarItem
                show={
                    (checkRole('Administrator2') ||
                        checkRole('Manager2') ||
                        !checkRole('Staff2')) &&
                    checkClaim('GetInvoices2')
                }
                key="print-invoice"
                icon={<BookIcon color="white"/>}
            >
                Накладные
            </SideBarItem>
            <SideBarItem
                show={
                    (checkRole('Administrator') ||
                        checkRole('Manager') ||
                        checkRole('Staff')) &&
                    checkClaim('ExportParcels')
                }
                // key="export-parcel"
                key={RouteContainerTabs.ExportParcel}
                icon={<BookIcon color="white"/>}
            >
                Экспорт посылок
            </SideBarItem>
            <SideBarItem
                show={checkRole('Customer')}
                key="custome-parcels"
                icon={<BookIcon color="white"/>}
            >
                Посылки
            </SideBarItem>
            <SideBarItem
                show={checkRole('Customer1')}
                key="customer-orders"
                icon={<BookIcon color="white"/>}
            >
                Заказы
            </SideBarItem>
        </AppMenu>
    );
}
