import {Form, Formik} from 'formik';
import {update} from 'immupdate';
import React, {useCallback} from 'react';
import {object, string} from 'yup';
import Button from '../button/Button';
import InputField from '../form/InputField';
import './assets/form.scss';
import PhoneInput from "react-phone-input-2";

interface AuthLoginFormProps {
    readonly initialValues: any;
    readonly setInitialValues: (value: any) => void;
    readonly onSubmit: (value: any) => void;
}

const validationSchema = object({
    phone: string().required('Required'),
    password: string().required('Required!'),
});

export default function AuthLoginForm({
                                          initialValues,
                                          setInitialValues,
                                          onSubmit,
                                      }: AuthLoginFormProps) {

    const onChangePhoneNumber = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    phone: value,
                })
            );

        },
        [setInitialValues]
    );

    const onChangePassword = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    password: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    return (
        <div className="auth-form" style={{width: '400px'}}>
            <div className="title text-center">
                <p className="fs-3 fw-bold">Вход</p>
            </div>
            <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={onSubmit}
            >
                {() => (
                    <Form>
                        <div className="row">
                            <div className="col-12">
                                {/*<InputField*/}
                                {/*    label="Phone"*/}
                                {/*    name="phone"*/}
                                {/*    value={initialValues.email}*/}
                                {/*    onChange={(value: any) =>*/}
                                {/*        onChangePhoneNumber(value)*/}
                                {/*    }*/}
                                {/*    type="number"*/}
                                {/*/>*/}


                                <label style={{marginBottom: '10px'}}> {/* Adjust the margin as needed */}
                                    Номер телефона
                                </label>
                                <PhoneInput
                                    prefix={"+"}
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                        autoFocus: true,
                                        style: {minWidth: '50px', width: '100%'}
                                    }}
                                    onlyCountries={['ru', 'uz', 'kg', 'kz', 'tj', 'tr']}
                                    country={'uz'}
                                    value={initialValues.phone}
                                    onChange={onChangePhoneNumber}
                                    defaultErrorMessage={'Неверный номер телефона'}
                                />

                            </div>
                            <div className="col-12 mt-2">
                                <InputField
                                    label="Пароль"
                                    name="password"
                                    type="password"
                                    value={initialValues.password}
                                    onChange={(value: any) =>
                                        onChangePassword(value)
                                    }
                                />
                            </div>

                            <div className="col-12 mt-3">
                                <Button
                                    type="submit"
                                    className="w-100 text-light py-2"
                                    style={{backgroundColor: '#2e5c87'}}
                                >
                                    Отправить
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
