import { BaseApi } from "../BaseApi";

export class AuthApi extends BaseApi {
  public login(json: any) {
    return this.post("Authentication/Login", {
      json,
    });
  }

  public getDashboardInfo(){
    return this.get("Dashboard/GetDashboardDetails")
  }
}
