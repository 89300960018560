import { BaseApi } from "../BaseApi";
import { GetAllWithPaginationQuery } from "../merchant/MerchantDto";
import {
  CreateOrderProps,
  DeleteOrdersProps,
  GetOneOrderQuery,
  UpdateOrderProps,
} from "../order/OrderDto";
import { GetAllSendingParcel } from "./CustomerDto";

export class CustomerApi extends BaseApi {
  public getSendingParcelList({
    pageNumber,
    pageSize,
    code,
  }: GetAllSendingParcel) {
    return this.get(`Customer/GetMySendingsListWithPaginationByCode`, {
      query: { pageNumber, pageSize, code },
    });
  }

  public getReceipendParcelList({
    pageNumber,
    pageSize,
  }: GetAllWithPaginationQuery) {
    return this.get(`Customer/GetMyReceivesListWithPagination`, {
      query: { pageNumber, pageSize },
    });
  }

  public getMyOrdersListWithPagn({
    pageNumber,
    pageSize,
  }: GetAllWithPaginationQuery) {
    return this.get(`Customer/GetMyOrderListWithPagination`, {
      query: { pageNumber, pageSize },
    });
  }

  public getOneMyOrder({ id }: GetOneOrderQuery) {
    return this.get(`Customer/GetMyOrderDetails/:id`, {
      params: { id },
    });
  }

  public createMyOrder(json: CreateOrderProps) {
    return this.post(`Customer/CreateMyOrder`, { json });
  }

  public updateMyOrder(json: UpdateOrderProps) {
    return this.put(`Customer/UpdateMyOrder`, { json });
  }

  public deleteMyOrders(json: DeleteOrdersProps) {
    return this.post(`Customer/DeleteMyOrders`, { json });
  }
}
