interface Props {
    readonly data: any[],
    readonly color: any,
    readonly editable: boolean,
    readonly delet?: (value: any) => void;
}

export default function EditParcelShowNumberForManifest({
                                                            data,
                                                            delet,
                                                            color,
                                                            editable
                                                        }: Props) {
    return (
        <div className="number-show-wrapper d-flex" style={{flexWrap: 'wrap'}}>
            {data && data.map((item: any, index: number) => {
                // console.log(item.statusItemId, item.title, item.parcelId)
                return (
                    <div
                        key={index}
                        className={`number-show-item ${item.statusItemId > 2 ? 'bg-success' : 'bg-danger'} text-light p-2 m-1`}>
                        {/*<span style={{fontSize: '14px'}}>*/}
                        {/*    <a href={`/app/parcels/parcel-view?parcelId=${item.parcelId}`}*/}
                        {/*       style={{color: 'white'}}> {item.title}</a>*/}
                        {/*</span>*/}

                        {item.parcelId ? (
                            <a href={`/app/parcels/parcel-view?parcelId=${item.parcelId}`} style={{color: 'white'}}>
                                {item.title}
                            </a>
                        ) : (
                            <span style={{fontSize: '14px'}}>{item.title}</span>
                        )}


                        {editable && (
                            <button
                                type="button"
                                className="border-0 ms-1 p-0 px-1"
                                onClick={() => delet && delet(index)}
                            >
                                <svg
                                    fill="black"
                                    width={10}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                >
                                    <path
                                        d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                    />
                                </svg>
                            </button>
                        )}
                    </div>
                )
            })}
        </div>
    )
}