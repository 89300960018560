import "./assets/modal.scss";

interface Props{
    readonly open: boolean;
}

export default function ModalLoader({open}:Props){

    if(!open){
        return null;
    }

    return (
        <div className="modal-loader">
            <h1>Loading...</h1>
        </div>
    )
}