import { useNavigate, useSearchParams } from "react-router-dom";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { profileSelector } from "../../reducers/authReducer";
import { CheckUserClaim } from "../../utils/CheckUserInfo";
import { useDispatch } from "react-redux";
import { switchHeaderTitle } from "../../reducers/appReducer";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import Button from "../button/Button";
import TabPage from "../tabs/TabPage";
import OrdersTable from "./OrdersTable";
import DeleteIcon from "../icons/DeleteIcon";
import Pagination from "../pagination/Pagination";
import Modal from "../modal/Modal";
import YesOrNoModal from "../app/YesOrNoModal";
import { useOrderApiContext } from "../../api/order/OrderContext";

export default function OrdersTableWrapper() {
  const [data, setData] = useState<any>({});
  const [tableLoading, setTableLoading] = useState(false);
  const [isDelModal, setIsDelModal] = useState(false);
  const [ids, setIds] = useState([]);
  const [searchParams] = useSearchParams();

  const pageSize = Number(searchParams.get("pageSize") || 25);
  const pageCount = Number(searchParams.get("pageCount") || 1);

  const profile = useShallowEqualSelector(profileSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { OrderApi } = useOrderApiContext();

  useEffect(() => {
    setTableLoading(true);
    OrderApi.getAllOrdersListWithPagn({
      pageNumber: pageCount,
      pageSize: pageSize,
    })
      .then((response: any) => {
        setData(response);
        setTableLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setTableLoading(false);
      });
  }, [OrderApi, pageCount, pageSize]);

  const deletePost = useCallback(() => {
    const del: any = {
      id: ids,
    };
    OrderApi.deleteOrders(del)
      .then((response) => {
        toast.success(response.message);
        setIsDelModal(false);
        window.location.reload();
      })
      .catch((error) => toast.error(error.message));
  }, [ids, setIsDelModal, OrderApi]);

  return (
    <TabPage
      headerComponent={
        <div className="h-100 d-flex align-items-center">
          {CheckUserClaim(profile, "CreateOrder") ? (
            <Button
              onClick={() => {
                navigate("/app/orders/form");
                dispatch(
                  switchHeaderTitle({
                    headerTitle: "Заказы / Создание заказа",
                  })
                );
              }}
              className="text-light px-2 py-1 bg-gold"
            >
              Создать
            </Button>
          ) : (
            <div />
          )}
        </div>
      }
      footerComponent={
        <div className="d-flex justify-content-between my-3">
          {CheckUserClaim(profile, "DeleteManifest") ? (
            <Button
              className="bg-danger px-2 py-2"
              onClick={() => {
                if (ids.length === 0) {
                  toast.error("Please choose Manifiest");
                } else {
                  setIsDelModal(true);
                }
              }}
            >
              <DeleteIcon color="white" size={16} />
            </Button>
          ) : (
            <div />
          )}
          <Pagination
            pageNumber={data.pageNumber}
            totalCount={data.totalCount}
            totalPages={data.totalPages}
            onSubmit={(value: any) => console.log(value)}
          />
        </div>
      }
    >
      <OrdersTable
        data={data?.items}
        loading={tableLoading}
        selectRow={setIds}
        editRow={(value) => {
          // console.log(value);
          navigate(`/app/orders/form?orderId=${value.id}`);
        }}
      />
      <Modal
        width="500px"
        show={isDelModal}
        closeHandler={() => setIsDelModal(false)}
        className="d-flex justify-content-center align-items-center"
      >
        <YesOrNoModal
          titleText="Вы уверены, что хотите удалить?"
          onClick={(value: boolean) => {
            if (value) {
              deletePost();
            } else {
              setIsDelModal(false);
            }
          }}
        />
      </Modal>
    </TabPage>
  );
}
