import React from "react";
import TabPage from "../tabs/TabPage";
import MerchantTable from "./MerchantTable";

export default function MerchantTableWrapper() {
    return (
        <TabPage childrenClassName="p-2">
            <MerchantTable/>
        </TabPage>
    );
}
