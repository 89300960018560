export enum Pages {
  Login = "/auth",
  App = "/app",
}

export enum RouteContainerTabs {
  Administrator = "administrator",
  Dashboard = "dashboard",
  Users = "users",
  Parcels = "parcels",

  AddParcelSecond = "parcel-form",
  AddManifest = "manifest-form",
  AddUser = "add-user",
  EditParcel = "edit-parcel",
  ExportParcel = "export-parcel",
  Salary = "salary",
  Incomes = "incomes",
  Expense = "expense",
  Currency = "currency",
  Merchant = "merchant",
  Account = "account",
  Forbidden = "forbidden",
  IssuedInvoice = "issued-invoice",
  Manifest = "manifest",
  Orders = "orders",
  Couriers = "couriers",
  Customers = "customers",
  CustomerParcels = "custome-parcels",
  CustomerOrders = "customer-orders",
  IncomeParcel = "accounthistory",

  AdministratorPath = "administrator/:tab?",
  UsersPath = "users/:tab?",
  ParcelsPath = "parcels/:tab?",
  AddParcelPath = "add-parcel/:tab?",
  EditParcelPath = "edit-parcel/:tab?",
  EditParcelSecondPath = "print-invoice/:tab?",
  ExportParcelPath = "export-parcel/:tab?",
  SalaryPath = "salary/:tab?",
  IncomesPath = "incomes/:tab?",
  ExpensePath = "expense/:tab?",
  CurrencyPath = "currency/:tab?",
  MerchantPath = "merchant/:tab?",
  AccountPath = "account/:tab?",
  IssuedInvoicePath = "issued-invoice/:tab?",
  ManifestPath = "manifest/:tab?",
  OrdersPath = "orders/:tab?",
  CouriersPath = "couriers/:tab?",
  CustomersPath = "customers/:tab?",
  CustomerParcelsPath = "custome-parcels/:tab?",
  CustomerOrdersPath = "customer-orders/:tab?",
}
