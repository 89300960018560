import { useParams } from "react-router-dom";
import ManifestTableWrapper from "./ManifiestTaleWrapper";
import ManifestFormWrapper from "./ManifestFormWrapper";

export default function ManifestTab() {
  const { tab = "table" } = useParams();
  return (
    <>
      {tab === "table" && <ManifestTableWrapper />}
      {tab === "form" && <ManifestFormWrapper />}
    </>
  );
}
