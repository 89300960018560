import React, {useCallback, useMemo, useRef, useState} from 'react';
import EditIcon from '../icons/EditIcon';
import DeleteIcon from '../icons/DeleteIcon';
import {useShallowEqualSelector} from '../../hooks/useShallowSelector';
import {profileSelector} from '../../reducers/authReducer';
import {AgGridReact} from "ag-grid-react";
import Modal from "../modal/Modal";
import YesOrNoModal from "../app/YesOrNoModal";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {PaginationNumberFormatterParams} from "ag-grid-community";
import {IDateFilterParams} from "@ag-grid-community/core";
import moment from "moment-timezone";
import {useAccountApiContext} from "../../api/account/AccountApiContext";

interface BranchTableProps {
    readonly deleteRow: (value: any) => void;
    readonly type?: string;
}

export default function AccountTable({}: BranchTableProps) {
    const navigator = useNavigate();
    const {AccountApi} = useAccountApiContext();

    const profile: any = useShallowEqualSelector(profileSelector);
    const checkRole = useCallback(
        (role: string) => {
            const hasRole = profile.role.filter((ro: string) => ro === role);
            return hasRole.length > 0 ? true : false;
        },
        [profile]
    );

    const { tab = "1" } = useParams();
    const editButton = (params: any) => {
        navigator(`/app/account/form?accountId=${params.data.id}`)
    }

    const openModal = (params: any) => {
        const userConfirmed = window.confirm("Are you sure you want to delete?");
        if (userConfirmed) {
            deleteAccount(params.data.id);
        }
    };

    const deleteAccount = useCallback((id: any) => {
        AccountApi.deleteAccount(id)
            .then((response) => {
                toast.success(response.message);
                window.location.reload();
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }, [AccountApi]);

    const datasource = {
        getRows(params: any) {
            const {startRow, endRow, filterModel, sortModel} = params.request
            let count = params.request.endRow - params.request.startRow; // or the cacheBlockSize
            let page = Math.ceil(params.request.endRow / count);

            const filterKeysNumberOfPoint = Object.keys(params.request.filterModel)
            filterKeysNumberOfPoint.forEach(filter => {
                if (filterKeysNumberOfPoint[0] === "numberOfPoint") {
                    // initialValues.numberOfPoint = "";
                    //   initialValues.numberOfPoint = `${filterModel[filter].filter}`
                }
            })
            //
            const filterKeys = Object.keys(filterModel)
            filterKeys.forEach(filter => {
                if (filterKeys[0] === "code") {
                    //   initialValues.code = "";
                    // initialValues.code += `${filterModel[filter].filter}`
                }
            })

            const filterKeysFromDate = Object.keys(filterModel)

            filterKeysFromDate.forEach(filter => {
                if (filterKeys[0] === "dateCreatedString") {
                    if (filterModel[filter].dateFrom !== null) {
                        // initialValues.fromDate = "";
                        // initialValues.fromDate += `${filterModel[filter].dateFrom}`
                    }
                    if (filterModel[filter].dateTo !== null) {
                        // initialValues.toDate = "";
                        // initialValues.toDate += `${filterModel[filter].dateTo}`
                    }
                }
            })

            AccountApi.getAccountListPagn({
                pageNumber: page,
                pageSize: count,
                accountTypeId: tab,
            })
                .then((response: any) => {
                    params.success({
                        rowData: response.items,
                        rowCount: response.items.totalCount,

                    });
                })
                .catch((error: any) => {
                    if (error.message === '') {
                        toast.error('Посылка не найдена!');
                    } else {
                        toast.error(error.message);
                    }
                });
        }
    };

    const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
        return [20, 50, 100, 10000];
    }, []);

    const paginationNumberFormatter = useCallback(
        (params: PaginationNumberFormatterParams) => {
            return "[" + params.value.toLocaleString() + "]";
        },
        [],
    );

    var dateFilterParams: IDateFilterParams = {
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var dateParts = dateAsString.split(".");
            var cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0]),
            );
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        },
        minValidYear: 2024,
        maxValidYear: 2025,
        inRangeFloatingFilterDateFormat: "Do MMM YYYY",
    };
    const onGridReady = (params: any) => {
        params.api.updateGridOptions({serverSideDatasource: datasource})
    }
    const gridRef = useRef<AgGridReact>(null);
    const [columnDefs] = useState([
        {
            field: "id",
            headerName: "№",
            minWidth: 70,
            maxWidth: 70,
        },
        {
            field: "number",
            headerName: "Номер счета",
            minWidth: 185,
            maxWidth: 200,
            filter: "agNumberColumnFilter", /*floatingFilter: true,*/
        },
        {
            field: "name",
            headerName: "Наименование счета\t",
            minWidth: 250,
            maxWidth: 250,
            filter: "agNumberColumnFilter", /*floatingFilter: true,*/
        },
        {
            field: "balance",
            headerName: "Баланс",
            hide: false,
            minWidth: 100,
            maxWidth: 100
        },
        {
            headerName: "Валюта",
            field: "currencyCode",
            minWidth: 95,
            maxWidth: 95,
            hide: false
        },
        {
            headerName: "Филиал",
            field: "branchName",
            minWidth: 100,
            maxWidth: 100,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['Москва', 'Ташкент', 'Алматы', 'Бишкек', 'Душанбе', 'Стамбул']
            }
        },
        {
            headerName: "Тип счета",
            field: "accountType",
            minWidth: 100,
            maxWidth: 100,
        },
        {
            headerName: "Состояние",
            field: "isActive",
            minWidth: 110,
            maxWidth: 110, /*, cellStyle: {textAlign: 'center'}*/
            cellRenderer: function (row: any) {
                if (row.data.isActive) {
                    return (
                            "Активный"
                    );
                } else {
                    return (
                            "Не активный"
                    );
                }
            }
        },
        {
            field: "dateCreated",
            headerName: "Дата",
            minWidth: 90,
            maxWidth: 90,
            cellRenderer: function (row: any) {
                return moment(row.data.dateCreated).format('DD.MM.yyyy')
            }
        },
        {
            headerName: "...",
            filter: false,
            field: "price",
            minWidth: checkRole('Administrator') ? 80 : 80,
            maxWidth: checkRole('Administrator') ? 80 : 80,
            cellRenderer: (params: any) =>
                <div className="btn-group" role="group" aria-label="Basic example">
                    <button type="button" className={'btn btn-sm'} onClick={() => editButton(params)}><EditIcon
                        color="green" size={10}/></button>

                    {checkRole('Administrator') &&
                        <button type="button" className={'btn btn-sm'} onClick={() => openModal(params)}><DeleteIcon
                            color="red" size={10}/></button>
                    }
                </div>
        },
    ]);


    return (
        <>
            <div
                className="ag-theme-balham"
                style={{height: '100%'}}
            >
                <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowSelection={'multiple'}
                    pagination={true}
                    paginationPageSize={20}
                    enableCellTextSelection={true}
                    rowModelType={"serverSide"}
                    onGridReady={onGridReady}
                    // onFilterChanged={onFilterChanged}
                    rowBuffer={10000}
                    cacheBlockSize={20}
                    enableRangeSelection={true}
                    maxBlocksInCache={2}
                    // gridOptions={gridOptions}
                    paginationPageSizeSelector={paginationPageSizeSelector}
                    paginationNumberFormatter={paginationNumberFormatter}
                    suppressMenuHide
                    defaultColDef={{filter: false, floatingFilter: false, sortable: false}}
                />
            </div>
        </>
    );
}
