import React, { useEffect, useState } from "react";
import TableButton from "../button/TableButton";
import DeleteIcon from "../icons/DeleteIcon";
import EditIcon from "../icons/EditIcon";
import Table from "../table/Table";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { profileSelector } from "../../reducers/authReducer";
import { CheckUserClaim } from "../../utils/CheckUserInfo";

interface UserManagerTableProps{
    readonly data: any;
    readonly dataLoading: boolean;
    readonly editRow: (value: any) => void;
    readonly deleteRow: (value: any) => void;
}

export default function UserManagerTable({
    data, 
    dataLoading,
    editRow, 
    deleteRow
}:UserManagerTableProps){

    const profile = useShallowEqualSelector(profileSelector);

    const [tableHeaders, setTableHeaders] = useState([])

    const [headers] = useState([
        {
            header: '№',
            access: 'id',
            width: 60
        },
         {
            header: 'ФИО',
            access: 'fullName',
            width: 200
        },
        {
            header: 'Адрес',
            access: 'address',
            width: 350
        },
        {
            header: 'Контакт',
            access: 'phone',
            width: 200
        },
        {
            header: 'Филиал',
            access: 'branchName',
            width: 200
        },
        // {
        //     header: 'Роль',
        //     access: 'roleName',
        //     width: 200,
        // },
        {
            header: "...",
            access: 'edit',
            ceil: (row: any)=>{
                return (
                            <div className="d-flex">
                            {CheckUserClaim(profile, "UpdateUser")?(
                            <TableButton
                                className="bg-warning me-3"
                                onClick={()=>editRow(row)}
                                >
                                <EditIcon color="white" size={14}/>
                            </TableButton>
                            ):(
                                <div/>
                            )}
                           {CheckUserClaim(profile, "DeleteUser")?(
                             <TableButton
                             className="bg-danger"
                             onClick={()=>deleteRow(row)}
                             >
                                <DeleteIcon color="white" size={14}/>
                            </TableButton>
                           ):(
                            <div/>
                           )}
                            </div>
                        )
            },
            width: 100,
        },
        
    ])

    useEffect(()=>{
        const data: any = [...headers];
    
        if(!CheckUserClaim(profile, "UpdateUser") && !CheckUserClaim(profile, "DeleteUser")){
            data.splice(data.length-1, 1);
        }

        setTableHeaders(data);
    
      },[headers, profile])

    return (<Table 
                data={data} 
                headers={tableHeaders} 
                loading={dataLoading}
                withCheckbox={false}/>)
}