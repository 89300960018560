import React, { useEffect } from "react";
import TableButton from "../button/TableButton";
import EditIcon from "../icons/EditIcon";
import Table from "../table/Table";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { profileSelector } from "../../reducers/authReducer";
import { CheckUserRole } from "../../utils/CheckUserInfo";

interface BranchTableProps {
  readonly data: any;
  readonly loading: boolean;
  readonly selectRow: (value: any) => void;
  readonly selectRowCheckbox: (value: any) => void;
}

export default function IncomesTable({
  data,
  loading,
  selectRow,
  selectRowCheckbox,
}: BranchTableProps) {

  const profile: any = useShallowEqualSelector(profileSelector);

  const headers: any[] = [
    {
      header: '№',
      access: 'id',
      width: 100
    },
    {
      header: "Название",
      access: "name",
      width: 200,
    },
    {
      header: "Описание",
      access: "description",
      width: 400,
    },
    {
      header: "...",
      access: "edit",
      ceil: (row: any) => {
        return (
          <div className="d-flex">
            <TableButton className="bg-warning" onClick={() => selectRow(row)}>
              <EditIcon color="white" size={14} />
            </TableButton>
          </div>
        );
      },
      width: 100,
    },
  ];

  useEffect(()=>{
    if(CheckUserRole(profile?.role, "Administrator")){
        headers.splice(2, 0, {
          header: "Филиал",
          access: "branchName",
          width: 200
        })
    }
  },[profile, headers]);


  return (
    <Table
      selectRowCheckbox={selectRowCheckbox}
      loading={loading}
      data={data}
      headers={headers}
      withCheckbox={true}
    />
  );
}
