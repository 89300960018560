import "./assets/sidebar.scss";
import React, {ReactNode, useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Form, Formik} from "formik";
import {object, string} from "yup";
import InputField from "../form/InputField";
import {update} from "immupdate";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useShallowEqualSelector} from "../../hooks/useShallowSelector";
import {
    appMenuTypeSelector,
    switchHeaderTitle,
} from "../../reducers/appReducer";
import {MenuTypes} from "../../api/AppDto";
import {profileSelector} from "../../reducers/authReducer";
import {useParcelApiContext} from "../../api/parcel/ParcelApiContext";
import {useUserApiContext} from "../../api/user/UserApiContext";
import {Pages} from "../../constants/Routes";

const searchParcelCodeValidationSchema = object({
    parcelCode: string(),
});

interface SideBarMenuLayoutProps {
    readonly children: ReactNode;
}

export default function SideBarMenuLayout({
                                              children,
                                          }: SideBarMenuLayoutProps) {
    const [initialValues, setInitialValues] = useState({
        parcelCode: ""
    });

    const menu = useShallowEqualSelector(appMenuTypeSelector);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const profile: any = useShallowEqualSelector(profileSelector);

    const {UserApi} = useUserApiContext();

    const [userInfo, setUserInfo] = useState<any>([]);

    // useEffect(() => {
    //     UserApi.getUserInfo()
    //         .then((response: any) => {
    //             setUserInfo((prev: any) =>
    //                 update(prev, {
    //                     userName: response.fullName,
    //                     phoneNumber: response.phoneNumber
    //                 })
    //             );
    //         },)
    //         .catch((err: any) => toast.error(err.message));
    //
    // }, [setUserInfo, UserApi]);


    useEffect(() => {

        UserApi.getUserInfo()
            .then((response: any) => {
                setUserInfo((prev: any) =>
                    update(prev, {
                        userName: response.fullName,
                        phoneNumber: response.phoneNumber
                    })
                );
            })
                 .catch((err: any) =>
                      toast.error(err.message)

                 );

    }, [setUserInfo, UserApi, navigate]);


    const {ParcelApi} = useParcelApiContext();

    const onChangeParcelCode = useCallback(
        (value: any) => {
            if (value.target.value.length < 10) {
                setInitialValues((prev: any) =>
                    update(prev, {
                        parcelCode: value.target.value,
                    })
                );
            }
        },
        [setInitialValues]
    );

    const submit = useCallback(
        (value: any) => {
            if (value.parcelCode.length === 6 || value.parcelCode.length === 8 || value.parcelCode.length === 9) {
                const code = initialValues.parcelCode;
                ParcelApi.getParcelDetailsByCode(code)
                    .then((response: any) => {
                        const ID = response.id;
                        if (ID === 0) {
                            toast.error('Посылка не найдена!');
                        } else {
                            navigate(`/app/parcels/parcel-view?parcelId=${ID}`);
                        }
                        setInitialValues((prev: any) =>
                            update(prev, {
                                parcelCode: "",
                            })
                        );
                    })
                    .catch((error: any) => {
                        toast.error('Посылка не найдена!');
                    })
            } else {
                toast.warning("Parcel code must be an 9-digit number");
            }
        },
        [initialValues, toast, setInitialValues, ParcelApi]
    );


    return (
        <div className="h-100 sidebar-menu">
            <div className="name-title d-flex justify-content-center align-items-center py-3">
                {menu != MenuTypes.Opened && (
                    <div
                        className="d-flex flex-column align-items-center justify-content-center"
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            navigate("/app/dashboard");
                            dispatch(switchHeaderTitle({headerTitle: "Главная"}));
                        }}
                    >
                        {/* <span className="text-light title-sub">{profile.sub}</span> */}
                        <span className="text-light text-center" style={{fontSize: 13}}>{profile.role[0]}
                            <br/>{userInfo.userName}<br/>{userInfo.phoneNumber}</span>
                    </div>
                )}
            </div>
            {menu != MenuTypes.Opened && (
            <div className="parcel-search-input px-3 pb-2">
                <Formik
                    initialValues={initialValues}
                    validationSchema={searchParcelCodeValidationSchema}
                    onSubmit={() => submit(initialValues)}
                >
                    {() => (
                        <Form>
                            <div className="row">
                                <div className="col-12">
                                    <InputField
                                        name="parcelCode"
                                        placeholder="Поиск..."
                                        value={initialValues.parcelCode}
                                        onChange={(value: any) => onChangeParcelCode(value)}
                                        type="number"
                                        inputClassName="amount"
                                    />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            )}

            {children}
        </div>
    );
}
