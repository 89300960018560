import { useMemo } from "react";
import { IncomesApi } from "./InocomesApi";
import { useApiBase } from "../ApiContext";
import {useDispatch} from "react-redux";

interface Props {
  readonly IncomesApi: IncomesApi;
}

export function useInocomesApiContext(): Props {
  const data = useApiBase();
  const dispatch = useDispatch();
  // const api = useMemo(() => new IncomesApi(data), [data]);
  const api = useMemo(() => new IncomesApi({...data,  dispatch}), [data]);

  return {
    IncomesApi: api,
  };
}
