import Button from '../button/Button';
import EditIcon from '../icons/EditIcon';
import PrintIcon from '../icons/PrintIcon';
import '../app/assets/app-view.scss';
import {API_HOST_SECOND} from "../../constants/AppUrls";
import '../app/assets/app-view.scss';
import {useParcelApiContext} from "../../api/parcel/ParcelApiContext";
import React, {useState} from "react";
import ModalLoader from "../modal/MdalLoader";
import {useIssuedInvoiceApiContext} from "../../api/issued-invoice/IssuedInvoiceContext";

interface Props {
    readonly data: any;
    readonly dateOnly: string;
    readonly imageIndex: number;
    readonly editParcel: (value: any) => void;
}

export default function AppView({
                                    data,
                                    imageIndex = 0,
                                    dateOnly,
                                    editParcel,
                                }: Props) {


    const [isLoading, setIsLoading] = useState(false);
    const {ParcelApi} = useParcelApiContext();
    const {IssuedInvoiceApi} = useIssuedInvoiceApiContext();

    const printButtonSticker = (params: any) => {
        const userConfirmed = window.confirm("Are you sure you want to print?");
        if (userConfirmed) {
            setIsLoading(true);
            ParcelApi.getStickerById({id: params.data.id, code: params.data.code})
                .then(() => {
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    };

    const printIssuedInvoice = (params: any) => {
        // console.log(params.data)
        const userConfirmed = window.confirm("Are you sure you want to print?");
        if (userConfirmed) {
            setIsLoading(true);
            IssuedInvoiceApi.printIssuid({
                id: params.data.invoiceId,
                fileName: params.data.code,
                currencyId: 1,
            })
                .then(() => {
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    };

    const printButtonInvoice = (params: any) => {
        const userConfirmed = window.confirm("Are you sure you want to print?");
        if (userConfirmed) {
            setIsLoading(true);
            ParcelApi.getInvoice({id: params.data.id, currencyId: 1})
                .then(() => {
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    };

    return (
        <>
            <div className="app-view-layout">
                <div className="row">
                    <div className="col-12">
                        <div className="code">
                            <strong>Посылка</strong> - Code: {data.code} Id:{' '}
                            {data.id}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 p-4">
                        {data?.parcelImage?.length > 0 && (
                            // <img
                            //
                            //     // src={data?.parcelImage[imageIndex]?.imageBytes}
                            //
                            //     src={`https://api.ethnologistics.com/images/${data?.parcelImage[imageIndex]?.dateCreated}/${data?.parcelImage[imageIndex]?.imageName}`}
                            //     width="100%"
                            //     alt=""
                            // />
                            // dateCreated={initialValues.dateCreated ? initialValues.dateCreated.split(' ')[0] : ''}
                            // <img src={`${API_HOST_SECOND}/images/${data?.parcelImage[imageIndex]?.dateCreated}/${data?.parcelImage[imageIndex]?.imageName}`} width="100%" alt="" />

                            // <img src={`${API_HOST_SECOND}/images/${dateOnly}/${data?.parcelImage[imageIndex]?.imageName}`} width="100%" alt="" />
                            <div className="image-container">
                                <img
                                    src={`${API_HOST_SECOND}/images/${dateOnly}/${data?.parcelImage[imageIndex]?.imageName}`}
                                    width="50%"
                                    alt=""
                                    onClick={() => window.open(`${API_HOST_SECOND}/images/${dateOnly}/${data?.parcelImage[imageIndex]?.imageName}`, '_blank')}
                                />
                            </div>

                        )}
                    </div>
                    <div className="col-6 p-4">
                        {<p><strong>Статус - </strong> {data.parcelStatus ? data.parcelStatus : "Не известно"} </p>}
                        {/*{<p><strong>Дата регистрации - </strong> {DateFormatter(data.dateCreated)}</p>}*/}
                        <p>
                            <strong>Отправитель - </strong> {data.sender?.fullName}{' '}
                            {data.sender?.phoneNumber}
                        </p>
                        <p>
                            <strong>Получатель - </strong>{' '}
                            {data.recipient?.fullName} {data.recipient?.phoneNumber}
                        </p>
                        <p>
                            <strong>Ответственный менеджер - </strong>{' '}
                            {data.recipientStaff?.fullName}
                        </p>
                        <p>
                            <strong>Направление - </strong> {data.fromBranch?.name}{' '}
                            - {data.toBranch?.name}
                        </p>
                        <p>
                            <strong>Адрес доставки - </strong>{' '}
                            {data.parcelAddress?.deliveryAddress}
                        </p>
                        <p>
                            <strong>Адрес забора - </strong>{' '}
                            {data.parcelAddress?.pickingUpAddress}
                        </p>
                        <p>
                            <strong>Вес посылки - </strong>{' '}
                            {data.parcelSize?.weight} кг
                        </p>
                        <p>
                            <strong>Количество мест - </strong> {data.numberOfPoint}
                        </p>
                        <p>
                            <strong>Тариф перевозки - </strong>{' '}
                            {data.parcelPlan?.name}
                        </p>
                        <p>
                            <strong>Тип посылки - </strong>{' '}
                            {data.parcelType ? data.parcelType : ""}
                        </p>
                        {data.parcelCost?.filter((item: any) => item.costTypeId === 2 && item.currencyId === 1)[0]?.amount > 0 &&
                            <p>
                                <strong>Стоимость перевозки в USD - </strong>{' '}
                                {data.parcelCost?.filter((item: any) => item.costTypeId === 2 && item.currencyId === 1)[0]?.amount} {' '}
                                {data.parcelCost?.filter((item: any) => item.costTypeId === 2 && item.currencyId === 1)[0]?.state ? 'Оплачено' : 'Не Оплачено'} {' '}
                                {data.parcelCost?.filter((item: any) => item.costTypeId === 2 && item.currencyId === 1)[0]?.accountName}
                            </p>
                        }
                        {data.parcelCost?.filter((item: any) => item.costTypeId === 2 && item.currencyId !== 1)[0]?.amount > 0 &&
                            <p>
                                <strong>Стоимость перевозки в местной валюте - </strong>{' '}
                                {data.parcelCost?.filter((item: any) => item.costTypeId === 2 && item.currencyId !== 1)[0]?.amount} {' '}
                                {data.parcelCost?.filter((item: any) => item.costTypeId === 2 && item.currencyId !== 1)[0]?.state ? 'Оплачено' : 'Не Оплачено'} {' '}
                                {data.parcelCost?.filter((item: any) => item.costTypeId === 2 && item.currencyId !== 1)[0]?.accountName}
                            </p>
                        }
                        {data.parcelCost?.filter((item: any) => item.costTypeId === 3 && item.currencyId === 1)[0]?.amount > 0 &&
                            <p>
                                <strong>Стоимость доставки до получателя в USD - </strong>{' '}
                                {data.parcelCost?.filter((item: any) => item.costTypeId === 3 && item.currencyId === 1)[0]?.amount} {' '}
                                {data.parcelCost?.filter((item: any) => item.costTypeId === 3 && item.currencyId === 1)[0]?.state ? 'Оплачено' : 'Не Оплачено'} {' '}
                                {data.parcelCost?.filter((item: any) => item.costTypeId === 3 && item.currencyId === 1)[0]?.accountName}
                            </p>
                        }
                        {data.parcelCost?.filter((item: any) => item.costTypeId === 3 && item.currencyId !== 1)[0]?.amount > 0 &&
                            <p>
                                <strong>Стоимость доставки до получателя в местной валюте - </strong>{' '}
                                {data.parcelCost?.filter((item: any) => item.costTypeId === 3 && item.currencyId !== 1)[0]?.amount} {' '}
                                {data.parcelCost?.filter((item: any) => item.costTypeId === 3 && item.currencyId !== 1)[0]?.state ? 'Оплачено' : 'Не Оплачено'} {' '}
                                {data.parcelCost?.filter((item: any) => item.costTypeId === 3 && item.currencyId !== 1)[0]?.accountName}
                            </p>
                        }
                        {data.parcelCost?.filter((item: any) => item.costTypeId === 1 && item.currencyId === 1)[0]?.amount > 0 &&
                            <p>
                                <strong>Стоимость забора в USD - </strong>{' '}
                                {data.parcelCost?.filter((item: any) => item.costTypeId === 1 && item.currencyId === 1)[0]?.amount} {' '}
                                {data.parcelCost?.filter((item: any) => item.costTypeId === 1 && item.currencyId === 1)[0]?.state ? 'Оплачено' : 'Не Оплачено'} {' '}
                                {data.parcelCost?.filter((item: any) => item.costTypeId === 1 && item.currencyId === 1)[0]?.accountName}
                            </p>
                        }
                        {data.parcelCost?.filter((item: any) => item.costTypeId === 1 && item.currencyId !== 1)[0]?.amount > 0 &&
                            <p>
                                <strong>Стоимость забора в местной валюте - </strong>{' '}
                                {data.parcelCost?.filter((item: any) => item.costTypeId === 1 && item.currencyId !== 1)[0]?.amount} {' '}
                                {data.parcelCost?.filter((item: any) => item.costTypeId === 1 && item.currencyId !== 1)[0]?.state ? 'Оплачено' : 'Не Оплачено'} {' '}
                                {data.parcelCost?.filter((item: any) => item.costTypeId === 1 && item.currencyId !== 1)[0]?.accountName}
                            </p>
                        }
                        {/*<p>*/}
                        {/*    <strong>Итоговая стоимость в USD - </strong>{' '}*/}
                        {/*    {data.totalAmountUsd} $*/}
                        {/*</p>*/}

                        {/*<p>*/}
                        {/*    <strong>Итоговая стоимость в местной валюте - </strong>{' '}*/}
                        {/*    {data.parcelCost?.TotalAmountLocal}*/}
                        {/*</p>*/}

                        {/*<p>*/}
                        {/*    <strong>Метод оплаты - </strong>{' '}*/}
                        {/*    {data.paymentMethod?.name.toString()}*/}
                        {/*</p>*/}

                        {data.senderCourier?.fullName &&
                            <p>
                                <strong>Курьер для забора - </strong>{' '}
                                {data.senderCourier?.fullName}{' '}
                                {data.senderCourier?.phoneNumber}
                            </p>
                        }
                        {data.recipientCourier?.fullName &&
                            <p>
                                <strong>Курьер для доставки - </strong>{' '}
                                {data.recipientCourier?.fullName}{' '}
                                {data.recipientCourier?.phoneNumber}
                            </p>
                        }
                        <p>
                            <strong>Дата посылки - </strong>
                            {data.dateCreated}
                        </p>

                        <p>
                            <strong>Оператор - </strong>{' '}
                            {data.senderStaff?.fullName}{' '}
                            {data.senderStaff?.phoneNumber}
                        </p>

                        <p>
                            <strong>Плательщик - </strong>
                            {data.payerTypeName}
                            {/*{data.payerType == 'sender' && <>Отправитель</>}*/}
                            {/*{data.payerType == 'recipient' && <>Получатель</>}*/}
                        </p>
                        {data?.merchant?.name && (
                            <p>
                                <strong>Юр. лицо - </strong> {data?.merchant?.name}
                            </p>
                        )}
                        {data.parcelDescription &&
                            <p>
                                <strong>Описание - </strong>
                                {data.parcelDescription}
                            </p>
                        }
                        <div className="d-flex align-items-center">
                            <Button
                                className="text-light py-2 px-2 bg-success"
                                onClick={() =>
                                    printButtonSticker({data})
                                    // printParcel({code: data.code, id: data.id})
                                }
                                type="button"
                            >
                                <PrintIcon color="white" size={15}/>
                                <span className="ms-2">Печать наклейки</span>
                            </Button>


                            <Button
                                className="text-light py-2 px-2 bg-success ms-2"
                                onClick={() =>
                                    printButtonInvoice({data})
                                    // printParcel2({code: data.code, id: data.id})
                                }
                                type="button"
                            >
                                <PrintIcon color="white" size={15}/>
                                <span className="ms-2">Печать накладной</span>
                            </Button>
                            {data.invoiceId > 0 &&
                                <Button
                                    className="text-light py-2 px-2 bg-success ms-2"
                                    onClick={() =>
                                        printIssuedInvoice({data})
                                        // printParcel3({code: data.code, id: data.invoiceId})
                                    }
                                    type="button"
                                >
                                    <PrintIcon color="white" size={15}/>
                                    <span className="ms-2">Печать счета</span>
                                </Button>
                            }
                            <Button
                                className="text-light py-2 px-2 bg-warning ms-2"
                                onClick={() => editParcel(data.id)}
                                type="button"
                            >
                                <EditIcon color="white" size={15}/>
                                <span className="ms-2">Редактировать</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <ModalLoader open={isLoading}/></>
    );
}
