export function DateFormatterDay(date: string){

    const myDate = date.substring(0, 16);

    const myYear = myDate.substring(0, 4);
    const myMonth = myDate.substring(5, 7);
    const myDay = myDate.substring(8, 10);
    const myHours = myDate.substring(11, 13);
    const myMinute = myDate.substring(14, 16);

    return `${myDay}.${myMonth}.${myYear}`
}