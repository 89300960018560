import { useEffect, useState } from "react";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { profileSelector } from "../../reducers/authReducer";
import { DateFormatter } from "../../utils/DateFormatter";
import { CheckUserClaim } from "../../utils/CheckUserInfo";

import Table from "../table/Table";
import TableButton from "../button/TableButton";
import PrintIcon from "../icons/PrintIcon";

interface Props {
  readonly data: any[];
  readonly loading: boolean;
  readonly onPrintWithCurrency: (value: any) => void;
}

export default function CustomerMyReceipendTable({ data, loading, onPrintWithCurrency }: Props) {
  const [tableHeaders, setTableHeaders] = useState([]);

  const profile = useShallowEqualSelector(profileSelector);

  const [headers] = useState<any[]>([
    {
      header: "Ид",
      access: "id",
      width: 60,
    },
    {
      header: "Код",
      access: "code",
      width: 100,
    },
    
    {
      header: "Отправитель",
      access: "sender",
      width: 200,
      ceil: (row: any) => {
        return <>{row.sender.fullName}</>;
      },
    },
    {
      header: "Направление",
      access: "nap",
      width: 160,
      ceil: (row: any) => {
        return (
          <>
            {row.fromBranch.name} - {row.toBranch.name}
          </>
        );
      },
    },
    {
      header: "Тариф",
      access: "parcelPlan",
      width: 100,
      ceil: (row: any) => {
        return <>{row.parcelPlan.name}</>;
      },
    },
    {
      header: "Вес",
      access: "parcelSize",
      width: 70,
      ceil: (row: any) => {
        return <>{row.parcelSize.weight} кг</>;
      },
    },
    {
      header: "Место",
      access: "plan",
      width: 80,
      ceil: (row: any) => {
        return <>{row.numberOfPoint}</>;
      },
    },
    {
      header: "Итого",
      access: "summa",
      width: 80,
      ceil: (row: any) => {
        return (
          <>
            {row.parcelCost.costPickingUp +
              row.parcelCost.costDeliveryToPoint +
              row.parcelCost.costDeliveryToBranch}{" "}
            $
          </>
        );
      },
    },
    {
      header: "Прошло",
      access: "overdue",
      width: 100,
      ceil: (row: any) => {
        return <>{row.parcelOverdue.overdue} дней</>;
      },
    },
    {
      header: "Дата",
      access: "date",
      width: 100,
      ceil: (row: any) => {
        return <>{DateFormatter(row.dateCreated)}</>;
      },
    },
    {
      header: "Печать",
      access: "edit",
      ceil: (row: any) => {
        return (
          <div className="d-flex">
            <TableButton
              className="bg-success ms-2"
              onClick={() => onPrintWithCurrency(row)}
            >
              <PrintIcon color="white" />
            </TableButton>
          </div>
        );
      },
      width: 50,
    },
  ]);

  useEffect(()=>{
    const data: any = [...headers];

    if(!CheckUserClaim(profile, "UpdateParcel") && !CheckUserClaim(profile, "GetStickers")){
      data.splice(data.length-1, 1);
    }

    setTableHeaders(data);

  },[headers, profile])

  return <Table headers={headers} data={data} loading={loading} />;
}
