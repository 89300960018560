import { useParams } from "react-router-dom";
import ContainerLayout from "../components/app/ContainerLayout";
import CustomerParcelTab from "../components/customers/CustomerParcelTab";
import CustomerDashboardTab from "../components/customers/CustomerDashboardTab";
import CustomerOrderTab from "../components/customers/CustomerOrderTab";

export default function CustomersContainer() {
  const { tab = "customer-dashboard" } = useParams();

  return (
    <ContainerLayout>
      {tab === "customer-dashboard" && <CustomerDashboardTab />}
      {tab === "customer-parcels" && <CustomerParcelTab />}
      {tab === "customer-orders" && <CustomerOrderTab />}
    </ContainerLayout>
  );
}
