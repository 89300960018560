import React, { useState, useCallback, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import Button from "../button/Button";
import TabPage from "../tabs/TabPage";
import PlanForm from "./PlanForm";
import { usePlanApiContext } from "../../api/plan/PlanApiContext";
import { useSearchParams } from "react-router-dom";

interface BranchFormWrapperProps {
  readonly back: () => void;
}

export default function PlanFormWrapper({ back }: BranchFormWrapperProps) {
  const { PlanApi } = usePlanApiContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const id = useMemo(() => searchParams.get("planId"), [searchParams]);

  const [initialValues, setInitialValues] = useState({
    name: "",
    cost: "",
    description: "",
  });

  useEffect(() => {
    if (id) {
      PlanApi.getPlanById({ id: Number(id) })
        .then((response: any) => setInitialValues(response))
        .catch((error) => toast.error(error.message));
    }
  }, [PlanApi, setInitialValues, id]);

  const submit = useCallback(
    (value: any) => {
      if (id) {
        const data = {
          ...value,
          id: id,
        };
        PlanApi.updatePlan(data)
          .then((response: any) => {
            toast.success(response.message);
            setSearchParams({ pageType: "table" });
          })
          .catch((err: any) => toast.error(err.message));
      } else {
        const data = {
          ...value,
          cost: Number(value.cost),
        };
        PlanApi.createPlan(data)
          .then((response: any) => {
            toast.success(response.message);
            setSearchParams({ pageType: "table" });
          })
          .catch((err: any) => toast.error(err.message));
      }
    },
    [PlanApi, id, setSearchParams]
  );

  return (
    <TabPage
      childrenClassName="p-3 pt-4"
      headerComponent={
        <div className="d-flex align-items-center h-100">
          <Button onClick={back} className="bg-gold text-light px-2 py-1">
            Назад
          </Button>
        </div>
      }
    >
      <PlanForm
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        submit={submit}
      />
    </TabPage>
  );
}
