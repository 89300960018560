import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CreateOrderProps } from "../../api/order/OrderDto";
import { useOrderApiContext } from "../../api/order/OrderContext";
import { toast } from "react-toastify";
import { update } from "immupdate";

import Button from "../button/Button";
import TabPage from "../tabs/TabPage";
import OrdersForm from "./OrdersForm";

export default function OrdersFormWrapper() {
  const [initialValues, setInitialValues] = useState<CreateOrderProps>({
    name: "",
    cost: 0,
    link: "",
    description: "",
  });

  const [search] = useSearchParams();

  const navigate = useNavigate();
  const { OrderApi } = useOrderApiContext();

  const orderId = useMemo(() => search.get("orderId") || "", [search]);

  useEffect(() => {
    if (Boolean(orderId)) {
      OrderApi.getOneOrder({ id: Number(orderId) })
        .then((response: any) => setInitialValues(response))
        .catch((error) => console.log(error));
    }
  }, [OrderApi, orderId, setInitialValues]);

  const onSubmit = useCallback((value: CreateOrderProps) => {
    if (Boolean(orderId)) {
      const data = {
        id: Number(orderId),
        ...value,
      };
      OrderApi.updateOrder(data)
        .then((response) => {
          toast.success(response.message);
          navigate("/app/orders/table");
        })
        .catch((error) => console.log(error));
    } else {
      const data = {
        ...value,
      };
      OrderApi.createOrder(data)
        .then((response) => {
          toast.success(response.message);
          navigate("/app/orders/table");
        })
        .catch((error) => console.log(error));
    }
  }, []);

  return (
    <TabPage
      headerComponent={
        <div className="h-100 d-flex align-items-center">
          <Button
            onClick={() => navigate("/app/orders/table")}
            className="text-light px-2 py-1 bg-gold"
          >
            Назад
          </Button>
        </div>
      }
    >
      <OrdersForm
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        onSubmit={onSubmit}
      />
    </TabPage>
  );
}
