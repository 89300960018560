import React, { ReactNode } from "react";

export interface TabPaneProps {
  readonly children: ReactNode;
  readonly tab: string;
}

export default function TabPane({ children }: TabPaneProps) {
  return <div>{children}</div>;
}
