import ContainerLayout from "../components/app/ContainerLayout";
import OrdersTab from "../components/orders/OrdersTab";

export default function OrdersContainer() {
  return (
    <ContainerLayout>
      <OrdersTab />
    </ContainerLayout>
  );
}
