import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import TabPane from "../tabs/TabPane";
import Tabs from "../tabs/Tabs";
import CustomerMyReceipendTableWrapper from "./CustomerMyReceipendTableWrapper";
import CustomerMySentParcelTableWrapper from "./CustomerMySentParcelTableWrapper";

export default function CustomerParcelTab() {
  const [search, setSearch] = useSearchParams();

  const tab: any = useMemo(
    () => search.get("customer_parcel_type") || "sent",
    [search]
  );

  return (
    <Tabs
      onChangeTab={(value) => setSearch({ customer_parcel_type: value })}
      activeTab={tab}
    >
      <TabPane key="sent" tab="Мои Отправки">
        <CustomerMySentParcelTableWrapper />
      </TabPane>
      <TabPane key="receipend" tab="Мои полученые">
        <CustomerMyReceipendTableWrapper />
      </TabPane>
    </Tabs>
  );
}
