import { useParams } from "react-router-dom";
import ContainerLayout from "../components/app/ContainerLayout";
import AccountHistoryFormWrapper from "../components/accounthistory/AccountHistoryFormWrapper";
import ExpenseHistoryTableWrapper from "../components/expense/ExpenseHistoryTableWrapper";

export default function ExpensesContainer(){
    const { tab = "table" } = useParams();
    return (
        <ContainerLayout>
            {tab === "table" && (
                <ExpenseHistoryTableWrapper
                />
            )}
            {tab === "form" && (
                <AccountHistoryFormWrapper
                />
            )}
        </ContainerLayout>
    )
}