import TabPage from "../tabs/TabPage";
import ManifestTable from "./ManifestTable";

export default function ManifestTableWrapper() {

    return (
        <TabPage>
            <ManifestTable/>
        </TabPage>
    );
}
