import { useMemo } from "react";
import { useApiBase } from "../ApiContext";
import { CustomerApi } from "./CustomerApi";
import {useDispatch} from "react-redux";

interface Props {
  readonly CustomerApi: CustomerApi;
}

export function useCustomerApiContext(): Props {
  const data = useApiBase();
  const dispatch = useDispatch();
  // const api = useMemo(() => new CustomerApi(data), []);
  const api = useMemo(() => new CustomerApi({...data,  dispatch}), []);

  return {
    CustomerApi: api,
  };
}
