// src/utils/costCalculator.ts
import { toast } from 'react-toastify';

export const calculateCost = (value: any, costInfo: any, initialValues: any, usdRate: number) => {
    const found = costInfo[
        costInfo.findIndex(
            (x: any) =>
                x.fromBranchId === initialValues.parcelBranchFromId.value &&
                x.toBranchId === initialValues.parcelBranchToId.value &&
                x.planId === initialValues.parcelPlanId.value
        )
        ];

    let WEIGHT,
        COST: Number = 0,
        CURRENCY_ID: Number = 0,
        CURRENCY_NAME: String = "";

    if (found && found.commonCost !== 0 && value) {
        CURRENCY_ID = found.currencyId;
        CURRENCY_NAME = found.currencyName;

        WEIGHT = Number(value) < found.minimumWeight ? found.minimumWeight : Number(value);

        if (found.firstCost === 0) {
            COST = WEIGHT * found.commonCost;
        } else if (found.firstCost !== 0) {
            COST = found.firstCost + found.commonCost * (WEIGHT - 1);
        }

        if (usdRate != 0) {
            return { COST_USD: COST.toFixed(2), CURRENCY_NAME, CURRENCY_ID };
        } else {
            toast.error('Пожалуйста установите дневной курс валюты!');
        }
    }

    return { COST_USD: 0, CURRENCY_NAME: "", CURRENCY_ID: 0 };
};