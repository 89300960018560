import ContainerLayout from "../components/app/ContainerLayout";
import ManifestTab from "../components/manifest/ManifestTab";

export default function ManifestContainer() {
  return (
    <ContainerLayout>
      <ManifestTab />
    </ContainerLayout>
  );
}
