import { useMemo } from "react";
import { useApiBase } from "../ApiContext";
import { AuthApi } from "./AuthApi";
import { useDispatch } from "react-redux";



interface Props {
  readonly AuthApi: AuthApi;
}

export function useAuthApiContext(): Props {
  const data = useApiBase();
  const dispatch = useDispatch(); // Get dispatch function

  // const api = useMemo(() => new AuthApi(data), []);
  const api = useMemo(() => new AuthApi({ ...data,   dispatch }), []);

  return {
    AuthApi: api,
  };
}