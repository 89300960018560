import { useMemo } from "react";
import { useApiBase } from "../ApiContext";
import { ManifestApi } from "./ManifestApi";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

interface Props {
  readonly ManifestApi: ManifestApi;
}

export function useManifestApiContext(): Props {
  const data = useApiBase();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const api = useMemo(() => new ManifestApi(data), []);
  const api = useMemo(() => new ManifestApi({...data, navigate, dispatch}), []);

  return {
    ManifestApi: api,
  };
}
