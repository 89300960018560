import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useParcelApiContext} from "../../api/parcel/ParcelApiContext";
import {toast} from "react-toastify";
import {useManifestApiContext} from "../../api/manifiest/ManfiestContext";
import {update} from "immupdate";
import {useNavigate, useSearchParams} from "react-router-dom";
import TabPage from "../tabs/TabPage";
import ManifestForm from "./ManifestForm";
import Button from "../button/Button";
import {CreateManifestProps} from "../../api/manifiest/ManifestDto";
import {useAccountApiContext} from "../../api/account/AccountApiContext";
import {useDriverTypeApiContext} from "../../api/drivertype/DriverTypeApiContext";
import {useStatusManifestApiContext} from "../../api/statusmanifest/StatusManifestApiContext";
import {useBranchApiContext} from "../../api/branch/BranchApiContext";
import {useCurrencyApiContext} from "../../api/currency/CurrencyApiContext";
import {useShallowEqualSelector} from "../../hooks/useShallowSelector";
import {profileSelector} from "../../reducers/authReducer";
import {ManifestType} from "./ManifestType";

export default function ManifestFormWrapper() {
    const [branches, setBranches] = useState<any[]>([]);
    const [search, setSearch] = useSearchParams();
    const [accounts, setAccounts] = useState<any>([]);
    const [driverTypes, setDriverTypes] = useState<any>([]);
    const [statusManifest, setStatusManifest] = useState<any>([]);
    const [currencyTypes, setCurrencyTypes] = useState<any>([]);
    const [manifestTypes, setManifestTypes] = useState<any>([]);
    const {CurrencyApi} = useCurrencyApiContext();
    const [usdRate, setUsdRate] = useState<any>([]);
    const manifestId = useMemo(() => search.get("manifestId") || "", [search]);
    const {ParcelApi} = useParcelApiContext();
    const profile: any = useShallowEqualSelector(profileSelector);


    let myCurrencyId = 0
    let myCurrencyValue = ""

    const getBranchName = (branchId: any) => {
        switch (branchId) {
            case 1:
                return 'Москва';
            case 2:
                return 'Ташкент';
            case 3:
                return 'Алматы';
            case 4:
                return 'Стамбул';
            case 5:
                return 'Бишкек';
            case 6:
                return 'Душанбе';
        }
    };

    switch (Number(profile?.BranchId)) {
        case 1:
            myCurrencyId = 3;
            myCurrencyValue = "RUB";
            break;
        //Toshkent
        case 2:
            myCurrencyId = 1;
            myCurrencyValue = "USD";
            break;
        case 3:
            myCurrencyId = 4;
            myCurrencyValue = "KZT";
            break;
        case 4:
            myCurrencyId = 5;
            myCurrencyValue = "TRY";
            break;
        case 5:
            myCurrencyId = 6;
            myCurrencyValue = "KGS";
            break;
        case 6:
            myCurrencyId = 7;
            myCurrencyValue = "TJS";
            break;
        default:
            // Handle default case if needed
            break;
    }

    const [initialValues, setInitialValues] = useState<CreateManifestProps>({
        parcelItem: [],
        //fromBranchId: Number(profile?.BranchId),
        toBranchId: 0,
        description: "",
        driverTypeId: 0,

        fromBranchId: {
            label: getBranchName(Number(profile?.BranchId)),
            value: Number(profile?.BranchId),
        },

        // stateCostLocal: false,
        stateCostUsd: false,
        statusManifestId: 0,
        costUsd: 0,
        // costLocal: 0,
        accountId: 0,

        currencyId: {
            label: myCurrencyValue,
            value: myCurrencyId,
        },

        manifestCostId: 0,

        manifestTypeId: {
            label: "Авиа",
            value: 1,
        },
        editable: true,

    });
    const {AccountApi} = useAccountApiContext();
    const {DriverTypeApi} = useDriverTypeApiContext();
    const {ManifestApi} = useManifestApiContext();
    const {StatusManifestApi} = useStatusManifestApiContext();
    const navigate = useNavigate();
    const {BranchApi} = useBranchApiContext();

    useEffect(() => {
        ParcelApi.getInfoParcel()
            .then((response: any) => {
                setUsdRate(response.usdRate);
            })
            .catch((error) => console.log(error.message));
    }, [setUsdRate, ParcelApi]);

    useEffect(() => {
        CurrencyApi.getCurrencyTypeListSecond()
            .then((response: any) => {
                response.currencies.map((item: any) => {
                    const data = {
                        label: item.labelSecond,
                        value: item.value,
                    };
                    setCurrencyTypes((currencies: any) => [...currencies, data]);
                });
            })
            .catch((error) => console.log(error.message));
    }, [setCurrencyTypes, CurrencyApi]);

    // get manifest types from ManifestType.tsx
    useEffect(() => {
        const types = Object.values(ManifestType).map((type, index) => ({
            label: type,
            value: index + 1,
        }));
        setManifestTypes(types);
    }, []);

    useEffect(() => {
        BranchApi.getBranchList()
            .then((response: any) => {
                response.branches.map((item: any) => {
                    const data = {
                        label: item.name,
                        value: item.id,
                    };
                    setBranches((user: any) => [...user, data]);
                });
            })
            .catch((error) => console.log(error.message));
    }, [setBranches, BranchApi]);

    //GET MANIFEST
    useEffect(() => {
        if (Boolean(manifestId)) {
            ManifestApi.getManifestDetails({id: Number(manifestId)})
                .then((response: any) => {
                    const manifestStatusInResponse = response.manifestStatus.filter(
                        (item: any) => item.isCurrent
                    )[0];
                    setInitialValues((prev: any) =>
                        update(prev, {
                            fromBranchId: {
                                label: response.fromBranchName,
                                value: response.fromBranchId,
                            },
                            toBranchId: {
                                label: response.toBranchName,
                                value: response.toBranchId,
                            },
                            description: response.description,
                            stateCostUsd: response.manifestCost?.stateCostUsd,
                            costUsd: response.manifestCost?.costUsd,
                            id: response.id,
                            code: response.code,
                            currencyId: {
                                value: response.manifestCost?.currencyId,
                                label: response.manifestCost?.currencyCode,
                            },
                            statusManifestId: {
                                label: manifestStatusInResponse.statusManifest?.name,
                                value: manifestStatusInResponse.statusManifest?.id,
                            },
                            manifestCostId: response.manifestCost?.id,
                            driverTypeId: {
                                label: response.driverTypeName,
                                value: response.driverTypeId,
                            },
                            editable: response.editable,
                            manifestTypeId: {
                                label: response.manifestType,
                                value: response.manifestType,
                            },
                            accountId: {
                                label: response.manifestCost?.account ? response.manifestCost?.account.name : "",
                                value: response.manifestCost?.account ? response.manifestCost?.account.id : "",
                            },
                            parcelItem: response.parcelItem.map((d: any) => {
                                return {
                                    title: d.parcelItemCode.toString(),
                                    statusItemId: d.statusItemId,
                                    parcelId: d.parcelId,
                                };
                            }),
                        })
                    );
                })
                .catch((error) => console.log(error));
        }
    }, [ManifestApi, manifestId, setInitialValues]);

    useEffect(() => {
        AccountApi.getAccountList()
            .then((response: any) => {
                // eslint-disable-next-line array-callback-return
                response.branches.map((item: any) => {
                    const data = {
                        label: `${item.name} ${item.currencyCode} - ${item.paymentMethod}`,
                        value: item.id,
                    };
                    setAccounts((prev: any) => [...prev, data]);
                });
            })
            .catch((error) => toast.error(error.message));
    }, [AccountApi]);

    useEffect(() => {
        DriverTypeApi.getDriversTypeList()
            .then((response: any) => {
                response.driversTypeList.map((item: any) => {
                    const data = {
                        label: `${item.label}`,
                        value: item.value,
                    };
                    setDriverTypes((prev: any) => [...prev, data]);
                });
            })
            .catch((error) => toast.error(error.message));
    }, [setDriverTypes, DriverTypeApi]);

    useEffect(() => {
        StatusManifestApi.getStatusManifestList()
            .then((response: any) => {
                response.statusManifests.map((item: any) => {
                    const data = {
                        label: item.label,
                        value: item.value,
                    };

                    setStatusManifest((prev: any) => [...prev, data]);
                });
            })
            .catch((error) => toast.error(error.message));
    }, [setStatusManifest, StatusManifestApi]);

    let accountId: any = null

    //ON SUBMIT
    const onSubmit = useCallback((value: any) => {

        if (value?.toBranchId == 0) {
            toast.error("Определите филиал получателя!");
            return;
        }

        if (value?.stateCostUsd && value.accountId.value === '') {
            toast.error("Определите счет для оплаты перевозки!");
            return;
        }

        if (value?.stateCostUsd && value.costUsd == 0) {
            toast.error("Определите стоимость для перевозки!");
            return;
        }

        if (value.costUsd > 0 && value?.currencyId.value == null) {
            toast.error("Определите валюту для перевозки!");
            return;
        }

        if (value?.manifestId == 0) {
            toast.error("Определите тип манифеста!");
            return;
        }

        if (value?.driverTypeId == 0) {
            toast.error("Определите тип перевозчика!");
            return;
        }

        if (value.parcelItem.length == 0) {
            toast.error("Добавьте места для манифеста!");
            return;
        }


        let myManifestTypeId;
        if (value?.manifestTypeId.value === 1) {
            // console.log("Avia")
            myManifestTypeId = ManifestType.Avia;
        }
        if (value?.manifestTypeId.value === 2) {
            // console.log("Standart")

            myManifestTypeId = ManifestType.Standart;
        }
        if (value?.manifestTypeId.value === 3) {
            // console.log("Avto")
            myManifestTypeId = ManifestType.Avto;
        }
        if (value?.manifestTypeId.value === 4) {
            // console.log("Med")
            myManifestTypeId = ManifestType.Med;
        }

        if (Boolean(manifestId)) {

            const data = {
                id: Number(manifestId),
                fromBranchId: value?.fromBranchId?.value,
                toBranchId: value?.toBranchId?.value,
                // parcelItem: value.parcelItem.map((p: any) => p.title),
                parcelItem: value.parcelItem.map((p: any) => Number(p.title)),
                description: value?.description,
                driverTypeId: value?.driverTypeId.value,
                statusManifestId: Number(value?.statusManifestId.value),
                manifestType: value?.manifestTypeId.label,
                manifestCost: {
                    id: value?.manifestCostId,
                    stateCostUsd: value?.stateCostUsd,
                    costUsd: value?.costUsd == '' ? 0 : value?.costUsd,
                    currencyId: value?.currencyId.value,
                    accountId: value?.stateCostUsd ? value.accountId.value : null,
                },
            };
            ManifestApi.updateManifest(data)
                .then((response) => {
                    // console.log(data)
                    toast.success(response.message);
                    navigate("/app/manifest/table");
                })
                .catch((error) => console.log(error));
        } else {
            const data = {
                fromBranchId: value?.fromBranchId?.value,
                toBranchId: value?.toBranchId?.value,
                parcelItem: value.parcelItem.map((p: any) => Number(p.title)),
                description: value?.description,
                driverTypeId: value?.driverTypeId.value,
                statusManifestId: Number(value?.statusManifestId.value),
                manifestType: value?.manifestTypeId.label,
                manifestCost: {
                    stateCostUsd: value?.stateCostUsd,
                    costUsd: value?.costUsd == '' ? 0 : value?.costUsd,
                    currencyId: value?.currencyId.value,
                    accountId: value?.stateCostUsd ? value.accountId.value : null,
                }
            };
            ManifestApi.createManifest(data)
                .then((response) => {
                    // console.log(data)
                    toast.success(response.message);
                    navigate("/app/manifest/table");
                })
                .catch((error) => console.log(error));
        }
    }, []);

    return (
        <TabPage
            headerComponent={
                <div className="h-100 d-flex align-items-center">
                    <Button
                        onClick={() => navigate("/app/manifest/table")}
                        className="text-light px-2 py-1 bg-gold"
                    >
                        Назад
                    </Button>
                </div>
            }
        >
            <ManifestForm
                initialValues={initialValues}
                branches={branches}
                usdRate={usdRate}
                setInitialValues={setInitialValues}
                onSubmit={onSubmit}
                currencyTypes={currencyTypes}
                manifestTypes={manifestTypes}
                type={manifestId ? "update" : "create"}
                accounts={accounts}
                driverTypes={driverTypes}
                statusManifest={statusManifest}
            />
        </TabPage>
    );
}
