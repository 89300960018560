import { BaseApi } from "../BaseApi";
import { GetAllWithPaginationQuery } from "../merchant/MerchantDto";
import {
  CreateOrderProps,
  DeleteOrdersProps,
  GetOneOrderQuery,
  UpdateOrderProps,
} from "./OrderDto";

export class OrderApi extends BaseApi {
  public getAllOrdersListWithPagn({
    pageNumber,
    pageSize,
  }: GetAllWithPaginationQuery) {
    return this.get(`Order/GetOrderListWithPagination`, {
      query: { pageNumber, pageSize },
    });
  }

  public getOneOrder({ id }: GetOneOrderQuery) {
    return this.get(`Order/GetOrderDetails/:id`, {
      params: { id },
    });
  }

  public createOrder(json: CreateOrderProps) {
    return this.post(`Order/CreateOrder`, { json });
  }

  public updateOrder(json: UpdateOrderProps) {
    return this.put(`Order/UpdateOrder`, { json });
  }

  public deleteOrders(json: DeleteOrdersProps) {
    return this.post(`Order/DeleteOrders`, { json });
  }
}
