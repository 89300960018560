import { useMemo } from "react";
import { AccountHistoryApi } from "./AccountHistoryApi";
import { useApiBase } from "../ApiContext";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

interface Props {
    readonly AccountHistoryApi: AccountHistoryApi;
}

export function useAccountHistoryApiContext(): Props {
    const data = useApiBase();
    const dispatch = useDispatch();
    // const api = useMemo(() => new AccountHistoryApi(data), [data]);
    const api = useMemo(() => new AccountHistoryApi({...data, dispatch}), []);

    return {
        AccountHistoryApi: api,
    };
}
