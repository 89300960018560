import {BaseApi} from "../BaseApi";
import {GetAccountHistoryListPagnQuery} from "./AccountHistoryDto";

export class AccountHistoryApi extends BaseApi {

    public getOneAccountListPagn({pageNumber, pageSize, listType}: GetAccountHistoryListPagnQuery) {
        return this.get(`AccountHistory/GetAccountHistoryListWithPagination`, {
            query: {pageNumber, pageSize, listType}
        });
    }

    public getAccountHistoryDetails(id: any) {
        return this.get(`AccountHistory/GetAccountHistoryDetails/${id}`);
    }

    public createAccountHistory(json: any) {
        return this.post("AccountHistory/CreateAccountHistory", {json});
    }

    public updateAccountHistory(json: any) {
        return this.put("AccountHistory/UpdateAccountHistory", {json});
    }

    public deleteAccountHistory(id: any) {
        return this.delete(`AccountHistory/DeleteAccountHistory/${id}`);
    }

    public deleteAccount(id: any) {
        return this.delete(`Account/DeleteAccount/${id}`);
    }
}
