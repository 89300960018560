import React, {useState, useEffect, useCallback} from "react";
import {toast} from "react-toastify";
import Pagination from "../pagination/Pagination";
import TabPage from "../tabs/TabPage";
import Modal from "../modal/Modal";
import {useNavigate, useSearchParams} from "react-router-dom";
import YesOrNoModal from "../app/YesOrNoModal";
import UserManagerTable from "./ByUserManagerTable";
import {useUserApiContext} from "../../api/user/UserApiContext";
import {Form, Formik} from "formik";
import InputField from "../form/InputField";
import {object, string} from "yup";
import {update} from "immupdate";
import CustomerUserManagerTable from "./ByCustomerUserManagerTable";
import {Pages} from "../../constants/Routes";

interface CustomerUserManagerTableWrapperProps {
    readonly editRow: (value: any) => void;

    readonly roleId: number;
}

const validationSchema = object({
    searchText: string(),
});
export default function CustomerUserManagerTableWrapper({
                                                            editRow,
                                                            roleId,
                                                        }: CustomerUserManagerTableWrapperProps) {
    const {UserApi} = useUserApiContext();
    const [data, setData] = useState<any>({});
    const [id, setId] = useState(null);
    const [isDelModal, setIsDelModal] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const pageSize = Number(searchParams.get("pageSize") || 25);
    const pageCount = Number(searchParams.get("pageCount") || 1);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        searchText: "",
    });

    useEffect(() => {
        UserApi.getAllUsersWithSearchText({
            pageNumber: pageCount,
            pageSize: pageSize,
            roleId: roleId,
            searchText: initialValues.searchText,
        })
            .then((response: any) => setData(response))
            .catch((err: any) => toast.error(err.message));
            // .catch((err: any) => {
            //     if (err.status === 401) {
            //         navigate(Pages.Login);
            //     } else if (err.status === 403) {
            //         toast.error("На данную страницу у вас нет доступа!");
            //     } else {
            //         toast.error(err.message);
            //     }
            // });


    }, [UserApi, pageCount, pageSize, roleId, initialValues.searchText]);

    const deleteRow = useCallback(
        (id: any) => {
            UserApi.deleteUser(id)
                .then((response) => {
                    toast.success(response.message);
                    setIsDelModal(false);
                    window.location.reload();
                })
                .catch((err: any) => toast.error(err.message));
                // .catch((err: any) => {
                //     if (err.status === 401) {
                //         navigate(Pages.Login);
                //     } else if (err.status === 403) {
                //         toast.error("На данную страницу у вас нет доступа!");
                //     } else {
                //         toast.error(err.message);
                //     }
                // });
            setId(null);
        },
        [setIsDelModal, setId, UserApi]
    );

    const onChangeCode = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    searchText: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    const onSubmit = useCallback(
        (value: any) => {
            if (value.searchText.length > 0) {
                UserApi.getAllUsersWithSearchText({
                    pageNumber: pageCount,
                    pageSize: pageSize,
                    roleId: roleId,
                    searchText: value.searchText,
                })
                    .then((response: any) => setData(response))
                    .catch((err: any) => toast.error(err.message));
                    // .catch((err: any) => {
                    //     if (err.status === 401) {
                    //         navigate(Pages.Login);
                    //     } else if (err.status === 403) {
                    //         toast.error("На данную страницу у вас нет доступа!");
                    //     }
                    //     else {
                    //         toast.error(err.message);
                    //     }
                    // });
            } else if (value.code.length === 0) {
                UserApi.getAllUsersWithSearchText({
                    pageNumber: pageCount,
                    pageSize: pageSize,
                    roleId: roleId,
                    searchText: value.searchText,
                })
                    .then((response: any) => setData(response))
                    .catch((err: any) => toast.error(err.message));
                    // .catch((err: any) => {
                    //     if (err.status === 401) {
                    //         navigate(Pages.Login);
                    //     } else if (err.status === 403) {
                    //         toast.error("На данную страницу у вас нет доступа!");
                    //     }
                    //     else {
                    //         toast.error(err.message);
                    //     }
                    // });
            } else {
                toast.warning("Введен не полный код");
            }
        },
        [UserApi]
    );

    return (
        <TabPage
            childrenClassName="p-2"
            headerComponent={
                <div className="d-flex justify-content-end h-100 align-items-center">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={() => onSubmit(initialValues)}
                        validationSchema={validationSchema}
                    >
                        {() => (
                            <Form>
                                <InputField
                                    width={300}
                                    name="searchText"
                                    placeholder="Поиск..."
                                    value={initialValues.searchText}
                                    onChange={(value: any) => onChangeCode(value)}
                                />
                            </Form>
                        )}
                    </Formik>
                </div>
            }
            footerComponent={
                <div className="d-flex justify-content-end my-3">
                    <Pagination
                        pageNumber={data.pageNumber}
                        totalCount={data.totalCount}
                        totalPages={data.totalPages}
                        onSubmit={(value: any) => console.log(value)}
                    />
                </div>
            }
        >

            <CustomerUserManagerTable
                editRow={editRow}
                deleteRow={(row: any) => {
                    setId(row.id);
                    setIsDelModal(true);
                }}
                byCustomerReceive={(row: any) => {
                    setSearchParams({pageType: "customer-parcelreceive-tab", userId: row.id});
                }}
                byCustomerSending={(row: any) => {
                    setSearchParams({pageType: "customer-parcelsending-tab", userId: row.id});
                }}
                data={data.items}
            />

            <Modal
                width="500px"
                show={isDelModal}
                closeHandler={() => setIsDelModal(false)}
                className="d-flex justify-content-center align-items-center"
            >
                <YesOrNoModal
                    titleText="Вы уверены, что хотите удалить?"
                    onClick={(value: boolean) => {
                        if (value) {
                            deleteRow(id);
                        } else {
                            setIsDelModal(false);
                        }
                        setId(null);
                    }}
                />
            </Modal>
        </TabPage>
    );
}
