import { useParams } from "react-router-dom";
import ContainerLayout from "../components/app/ContainerLayout";
import AccountHistoryTableWrapper from "../components/accounthistory/AccountHistoryTableWrapper";

export default function AccountHistoryContainer(){
    const { tab = "table" } = useParams();
    return (
        <ContainerLayout>
            {tab === "table" && (
                <AccountHistoryTableWrapper
                />
            )}
        </ContainerLayout>
    )
}