import { BaseApi } from "../BaseApi";
import { GetAllWithPaginationQuery } from "../merchant/MerchantDto";
import {
  CreateIssuedInvoiceDto,
  DeleteIssuedInvoiceDto,
  GetOneIssuedInvoiceQuery,
  UpdateIssuedInvoiceDto,
} from "./IssuedInvoiceDto";

export class IssuedInvoiceApi extends BaseApi {
  public getAllIssuedInvoiceListWithPagn({
    pageNumber,
    pageSize,
  }: GetAllWithPaginationQuery) {
    return this.get(`IssuedInvoice/GetIssuedInvoiceListWithPagination`, {
      query: { pageNumber, pageSize },
    });
  }

  public getOneIssuedInvoice({ id }: GetOneIssuedInvoiceQuery) {
    return this.get(`IssuedInvoice/GetIssuedInvoiceDetails/:id`, {
      params: { id },
    });
  }

  public createIssuedInvoice(json: CreateIssuedInvoiceDto) {
    return this.post(`IssuedInvoice/CreateIssuedInvoice`, {
      json,
    });
  }

  public updateIssuedInvoice(json: UpdateIssuedInvoiceDto) {
    return this.put(`IssuedInvoice/UpdateIssuedInvoice`, {
      json,
    });
  }

  public deleteIssuedInvoice(json: DeleteIssuedInvoiceDto) {
    return this.post(`IssuedInvoice/DeleteIssuedInvoices`, {
      json,
    });
  }

  public sendIssuid({ id, currencyId }: any) {
    return this.get("IssuedInvoice/SendIssuedInvoice", {
      query: { id, currencyId },
    });
  }

  public printIssuid({ id, currencyId, fileName }: any) {
    return this.downloadPdf("IssuedInvoice/PrintIssuedInvoice", fileName, {
      query: { id, currencyId },
    });
  }
}
