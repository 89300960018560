import { BaseApi } from "../BaseApi";
import { GetOneIssuedInvoiceQuery } from "../issued-invoice/IssuedInvoiceDto";
import { GetAllWithPaginationQuery } from "../merchant/MerchantDto";
import {
  AcceptanceManifestQuery,
  // CreateManifestProps,
  CreateManifestQuery,
  DeleteManifest,
  // UpdateManifestProps,
  UpdateManifestQuery,
} from "./ManifestDto";

export class ManifestApi extends BaseApi {
  public getAllManifestListWithPagn({
    pageNumber,
    pageSize,
  }: GetAllWithPaginationQuery) {
    return this.get(`Manifest/GetManifestListWithPagination`, {
      query: { pageNumber, pageSize },
    });
  }

  public getManifestDetails({ id }: GetOneIssuedInvoiceQuery) {
    return this.get(`Manifest/GetManifestDetails/:id`, {
      params: { id },
    });
  }

  public createManifest(json: CreateManifestQuery) {
    return this.post(`Manifest/CreateManifest`, { json });
  }

  public updateManifest(json: UpdateManifestQuery) {
    return this.put(`Manifest/UpdateManifest`, { json });
  }

  public acceptanceManifest(json: AcceptanceManifestQuery) {
    return this.put(`Manifest/AcceptanceManifest`, { json });
  }

  public deleteManifest(json: DeleteManifest) {
    return this.post(`Manifest/DeleteManifests`, { json });
  }

  public printManifest(id: number, fileName: string) {
    return this.downloadPdf(`Manifest/PrintManifest`, fileName, {
      query: { id },
    });
  }

  public exportManifest(id: number, fileName: string = "Manifest") {
    return this.downloadWord(`File/ExportManifest/:id`, fileName, {
      params: { id },
    });
  }
}


