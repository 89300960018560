import { Pages, RouteContainerTabs } from "../constants/Routes";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useMemo } from "react";
import { switchHeaderTitle } from "../reducers/appReducer";
import { profileSelector, tokenSelector } from "../reducers/authReducer";
import { useShallowEqualSelector } from "../hooks/useShallowSelector";

import AppContainer from "./AppContainer";
import AuthContainer from "./AuthContainer";
import AdministratorContainer from "./AdministratorContainer";
import UsersContainer from "./UsersContainer";
import ParcelContainer from "./ParcelContainer";
import EditParcelStatusContainer from "./EditParcelStatusContainer";
import ExportParcelContainer from "./ExportParcelContainer";
import SalaryContainer from "./SalaryContainer";
import IncomesContainer from "./IncomesContainer";
import ExpensesContainer from "./ExpensesContainer";
import CurrencyContainer from "./CurrencyContainer";
import MerchantContainer from "./MerchantContainer";
import AccountContainer from "./AccountContainer";
import ForbiddenContainer from "./ForbiddenContainer";
import DashboardContainer from "./DashboardContainer";
import IssuedContainer from "./IssuedInvoiceContainer";
import ManifestContainer from "./ManifestContainer";
import OrdersContainer from "./OrdersContainer";
import CouriersContainer from "./CouriersContainer";
import CustomersContainer from "./CustomersContainer";
import CustomerParcelsContainer from "./CustomerParcelsContainer";
import CustomerOrdersContainer from "./CustomerOrderContainer";
import AccountHistoryContainer from "./AccountHistoryContainer";
import AddUserFormContainer from "../components/user/AddUserFormContainer";
import AddParcelFormContainer from "./AddParcelFormContainer";

export default function RootContainer() {
  const navigate = useNavigate();

  const token = useShallowEqualSelector(tokenSelector);

  const isAuthorized = useMemo(() => Boolean(token), [token]);

  const dispatch = useDispatch();

  const location = useLocation();

  const profile = useShallowEqualSelector(profileSelector);


  useEffect(() => {
    if (!isAuthorized || !Array.isArray(profile?.role)) {
        navigate(Pages.Login);
    } else if (isAuthorized && location.pathname === "/") {
         navigate(Pages.App);
    }

  }, [navigate, isAuthorized, location.pathname, profile?.role]);

  useEffect(() => {
    if (location.pathname.includes("dashboard")) {
      dispatch(switchHeaderTitle({ headerTitle: "Главная" }));
    }
  }, [dispatch, location.pathname]);




  return (
    <>
      <Routes>
        <Route path={Pages.Login} element={<AuthContainer />} />

        {isAuthorized && Array.isArray(profile?.role) && (

          <Route path={Pages.App} element={<AppContainer />}>
            <Route
              path={RouteContainerTabs.Dashboard}
              element={<DashboardContainer />}
            />
            <Route
              path={RouteContainerTabs.AdministratorPath}
              element={<AdministratorContainer />}
            />
            <Route
              path={RouteContainerTabs.UsersPath}
              element={<UsersContainer />}
            />
            <Route
              path={RouteContainerTabs.ParcelsPath}
              element={<ParcelContainer />}
            />
            <Route
              path={RouteContainerTabs.EditParcelPath}
              element={<EditParcelStatusContainer />}
            />
            <Route
                path={RouteContainerTabs.AddParcelSecond}
                element={<AddParcelFormContainer />}
            />
            <Route
                path={RouteContainerTabs.AddUser}
                element={<AddUserFormContainer />}
            />
            <Route
              path={RouteContainerTabs.IssuedInvoicePath}
              element={<IssuedContainer />}
            />
            <Route
              path={RouteContainerTabs.ExportParcelPath}
              element={<ExportParcelContainer />}
            />
            <Route
              path={RouteContainerTabs.SalaryPath}
              element={<SalaryContainer />}
            />
            <Route
              path={RouteContainerTabs.IncomesPath}
              element={<IncomesContainer />}
            />
            <Route
              path={RouteContainerTabs.ExpensePath}
              element={<ExpensesContainer />}
            />
            <Route
              path={RouteContainerTabs.CurrencyPath}
              element={<CurrencyContainer />}
            />
            <Route
              path={RouteContainerTabs.MerchantPath}
              element={<MerchantContainer />}
            />
            <Route
              path={RouteContainerTabs.AccountPath}
              element={<AccountContainer />}
            />
            <Route
              path={RouteContainerTabs.Forbidden}
              element={<ForbiddenContainer />}
            />
            <Route
              path={RouteContainerTabs.ManifestPath}
              element={<ManifestContainer />}
            />
            {/*<Route*/}
            {/*  path={RouteContainerTabs.AddManifest}*/}
            {/*  element={<ManifestContainer />}*/}
            {/*/>*/}
            <Route
              path={RouteContainerTabs.OrdersPath}
              element={<OrdersContainer />}
            />
            <Route
              path={RouteContainerTabs.CouriersPath}
              element={<CouriersContainer />}
            />
            <Route
              path={RouteContainerTabs.CustomersPath}
              element={<CustomersContainer />}
            />
             <Route
              path={RouteContainerTabs.CustomerParcelsPath}
              element={<CustomerParcelsContainer />}
            />
            <Route
              path={RouteContainerTabs.CustomerOrdersPath}
              element={<CustomerOrdersContainer />}
            />
            <Route
              path={RouteContainerTabs.IncomeParcel}
              element={<AccountHistoryContainer />}
            />
          </Route>
        )}
      </Routes>
    </>
  );
}
