import { useMemo } from "react";
import { BranchApi } from "./BranchApi";
import { useApiBase } from "../ApiContext";
import {useDispatch} from "react-redux";

interface Props {
  readonly BranchApi: BranchApi;
}

export function useBranchApiContext(): Props {
  const data = useApiBase();
  const dispatch = useDispatch();
  // const api = useMemo(() => new BranchApi(data), []);
  const api = useMemo(() => new BranchApi({...data, dispatch}), []);

  return {
    BranchApi: api,
  };
}
