import { IdProps } from "../AppDto";
import { BaseApi } from "../BaseApi";
import { GetAllRole } from "./RoleDto";

export class RoleApi extends BaseApi {
  public getAllRole({ pageNumber, pageSize }: GetAllRole): Promise<any> {
    return this.get(
      `RoleManager/GetRoleListWithPagination?pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
  }

  public getRoleById({ id }: IdProps): Promise<any> {
    return this.get(`RoleManager/GetRoleDetails/${id}`);
  }

  public deleteRole({ id }: IdProps): Promise<any> {
    return this.delete(`RoleManager/DeleteRole/${id}`);
  }

  public updateRole(json: any) {
    return this.put("RoleManager/UpdateRole", { json });
  }

  public createRole(json: any) {
    return this.post("RoleManager/CreateRole", { json });
  }

  public getRoles() {
    return this.get("RoleManager/GetRoleList");
  }
}
