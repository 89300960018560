import React from "react";
import ReactDOM from "react-dom/client";
import RootContainer from "./containers/RootContainer";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./store/configureStore";
import { ToastContainer } from "react-toastify";
import { ProviderContainer } from "./containers/ProviderContainer";
import './App.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import {LicenseManager} from "ag-grid-enterprise";

const licenseKey1 = 'CompanyName=Equinix Asia Pacific pte ltd,LicensedGroup=equinixMendixPrivateLib,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=0,AssetReference=AG-027567,SupportServicesEnd=18_June_2023_[v2]_MTY4NzA0MjgwMDAwMA==4be2c388f9a8a7443c72842dff53d5b2';
const licenseKey2 = 'CompanyName=Qview Health, Inc.,LicensedGroup=Qview Development Team,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=2,AssetReference=AG-026813,ExpiryDate=13_May_2023_[v2]_MTY4MzkzMjQwMDAwMA==719a024f7d142d25e4dd09faba721539';
const licenseKey4 = 'SHI_UK_on_behalf_of_Lenovo_Sweden_MultiApp_1Devs6_November_2019__MTU3Mjk5ODQwMDAwMA==e27a8fba6b8b1b40e95ee08e9e0db2cb';
const licenseKey3 = 'ag-Grid_Evaluation_License_Not_for_Production_100Devs30_August_2037__MjU4ODczMzg3NzkyMg==9e93ed5f03b0620b142770f2594a23a2';
LicenseManager.setLicenseKey(licenseKey3);


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const store = configureStore();

root.render(
  <Provider store={store.store}>
    <ProviderContainer>
      <BrowserRouter>
        <RootContainer />
        <ToastContainer />
      </BrowserRouter>
    </ProviderContainer>
  </Provider>
);