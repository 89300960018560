import { useParams } from "react-router-dom";
import BranchTab from "../components/administrator/BranchTab";
import PlanTab from "../components/administrator/PlanTab";
import RoleManagerTab from "../components/administrator/RoleManagerTab";
import StatusTab from "../components/administrator/StatusTab";
import ContainerLayout from "../components/app/ContainerLayout";
import IncomesTab from "../components/administrator/IncomesTab";
import CurerncyTab from "../components/administrator/CurrencyTab";

export enum AdministratorTabs {
  RoleManagerTab = "role-manager",
  PlanTab = "plan",
  StatusTab = "status",
  BranchesTab = "branches",
  // These was added at 19.12.2023
  ExpensesTab = "expenses",
  IncomesTab = "incomes",
  SalaryTab = "salary",
  Currency = "currency",
}

export default function AdministratorContainer() {
  const { tab = AdministratorTabs.RoleManagerTab } = useParams();

  return (
    <ContainerLayout>
      {tab === AdministratorTabs.RoleManagerTab && <RoleManagerTab />}
      {tab === AdministratorTabs.PlanTab && <PlanTab />}
      {tab === AdministratorTabs.StatusTab && <StatusTab />}
      {tab === AdministratorTabs.BranchesTab && <BranchTab />}
      {tab === AdministratorTabs.ExpensesTab && <IncomesTab />}
      {tab === AdministratorTabs.IncomesTab && <IncomesTab />}
      {tab === AdministratorTabs.SalaryTab && <IncomesTab />}
      {tab === AdministratorTabs.Currency && <CurerncyTab />}
    </ContainerLayout>
  );
}
