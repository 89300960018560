import {useMemo} from "react";
import {AccountApi} from "./AccountApi";
import {useApiBase} from "../ApiContext";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

interface Props {
    readonly AccountApi: AccountApi;
}

export function useAccountApiContext(): Props {
    const data = useApiBase();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const api = useMemo(() => new AccountApi(data), [data]);
    const api = useMemo(() => new AccountApi({...data, navigate, dispatch}), [data, navigate]);

    return {
        AccountApi: api,
    };
}
