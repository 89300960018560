import { useParams } from "react-router-dom";

import ContainerLayout from "../components/app/ContainerLayout";
import AccountFormWrapper from "../components/account/AccountFormWrapper";
import AccountTableWrapper from "../components/account/AccountTableWrapper";

export default function AccountContainer(){

    const { tab = "branch" } = useParams();

    return (
        <ContainerLayout>
            {tab === "form" && (
                <AccountFormWrapper/>
            )}
            {tab !== "form" && (
                <AccountTableWrapper/>
            )}
        </ContainerLayout>
    )
}