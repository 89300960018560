import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import ContainerLayout from "../app/ContainerLayout";
import IncomesTableWrapper from "../incomes/IncomesTableWrapper";
import IncomesFormWrapper from "../incomes/IncomesFormWrapper";

export default function IncomesTab(){
    const [searchParams, setSearchParams] = useSearchParams();
    const page = useMemo(()=>searchParams.get("pageType")? searchParams.get("pageType") : "table",[searchParams]);

    return (
        <ContainerLayout>
          {page === "table" && (
            <IncomesTableWrapper 
                    selectRow={(value: any)=>{
                    setSearchParams({ pageType: "form", planId: value.id})
                    }} 
                    create={()=>{
                    setSearchParams({pageType: "form"})
                    }}/>
                     )}
          {page === "form" && (
                <IncomesFormWrapper back={()=>setSearchParams({ pageType: "table" })}/>
          )}
        </ContainerLayout>
    )
}