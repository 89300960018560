import React, {useCallback, useMemo} from "react";
import { Form, Formik } from "formik";
import { object, string } from "yup";
import { update } from "immupdate";

import GroupBox from "../app/GroupBox";
import InputField from "../form/InputField";
import Button from "../button/Button";
import SelectPickerField from "../form/SelectPickerField";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { profileSelector } from "../../reducers/authReducer";

interface StatusFormProps{
    readonly branches: any;
    readonly initialValues: any;
    readonly setInitialValues: (value: any) => void;
    readonly submit: (value: any) => void;
    readonly currencyTypes: any[];
}

const validationSchema = object({
    amount: string(),
})

export default function CurrencyForm({
    initialValues, 
    setInitialValues, 
    submit,
    branches,
    currencyTypes,
}:StatusFormProps){

    const profile: any = useShallowEqualSelector(profileSelector);

    const isAdmin = useMemo(()=>Boolean(profile.role[0] === "Administrator"), [profile]);

    const onChangeCurrencyTypeId = useCallback((value: any)=>{
        setInitialValues((prev: any)=>
            update(prev, {
                currencyTypeId: {
                    label: value.label,
                    value: value.value
                }
            })
        )
    },[setInitialValues]);

    const onChangeAmount = useCallback((value: any)=>{
        setInitialValues((prev: any)=>
            update(prev, {
                rate: value.target.value
            })
        )
    },[setInitialValues]);

    const onChangeBranchId = useCallback(
        (value: any) => {
          setInitialValues((prev: any) =>
            update(prev, {
              branchId: {
                label: value.label,
                value: value.value,
              },
            })
          );
        },
        [setInitialValues]
      );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={submit}
            >
            {()=>(
                <Form>
                    <div className="row mt-3">
                        <div className="col-6">
                        <GroupBox title="Курс валюты">
                           <div className="row">
                            <div className="col-12 mt-3">
                            <SelectPickerField
                                name="currencyTypeId" 
                                label="Тип валюты"
                                options={currencyTypes} 
                                onChanges={(value)=>onChangeCurrencyTypeId(value)}
                                            />
                            </div>
                            <div className="col-12 mt-3">
                            <InputField
                                label="Сумма"
                                name="amount"
                                value={initialValues.rate}
                                onChange={(event: any)=>onChangeAmount(event)}
                                />
                            </div>
                            { isAdmin && <div className="col-12">
                                <SelectPickerField
                                    label="Филиал"
                                    name="branchId"
                                    options={branches}
                                    onChanges={(event: any) => onChangeBranchId(event)}
                                    />
                            </div>}
                           </div>
                        </GroupBox>
                        </div>
                        <div className="col-12 mt-3">
                            <Button type="submit" className="text-light bg-gold px-2 py-1">
                                Сохранить
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}