import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import CustomerOrderTableWrapper from "./CustomerOrderTableWrapper";
import CustomerOrderFormWrapper from "./CustomerOrderFormWrapper";

export default function CustomerOrderTab() {
  const [search] = useSearchParams();

  const pageType = useMemo(() => search.get("pageType") || "table", [search]);

  return (
    <>
      {pageType === "table" && <CustomerOrderTableWrapper />}
      {pageType === "form" && <CustomerOrderFormWrapper />}
    </>
  );
}
