import { useCallback, useEffect, useState } from "react";
import { useIssuedInvoiceApiContext } from "../../api/issued-invoice/IssuedInvoiceContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { noop } from "lodash";
import { Form, Formik } from "formik";
import { update } from "immupdate";
import { useCurrencyApiContext } from "../../api/currency/CurrencyApiContext";
import { switchHeaderTitle } from "../../reducers/appReducer";
import { toast } from "react-toastify";

import IssuedInvoiceTable from "./IssuedInvoiceTable";
import TabPage from "../tabs/TabPage";
import Button from "../button/Button";
import DeleteIcon from "../icons/DeleteIcon";
import Pagination from "../pagination/Pagination";
import YesOrNoModal from "../app/YesOrNoModal";
import Modal from "../modal/Modal";
import GroupBox from "../app/GroupBox";
import SelectPickerField from "../form/SelectPickerField";
import ModalLoader from "../modal/MdalLoader";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { profileSelector } from "../../reducers/authReducer";
import { CheckUserClaim } from "../../utils/CheckUserInfo";

export default function IssuedInvoiceTableWrapper() {
  const [data, setData] = useState<any>({});
  const [tableLoading, setTableLoading] = useState(false);
  const [isDelModal, setIsDelModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [ids, setIds] = useState([]);

  const [searchParams] = useSearchParams();

  const [currencyFormModal, setCurrencyFormModal] = useState<any>({
    modalType: false,
    data: "",
    type: "",
  });

  const [currencyList, setCurrencyList] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useShallowEqualSelector(profileSelector);

  const { IssuedInvoiceApi } = useIssuedInvoiceApiContext();
  const { CurrencyApi } = useCurrencyApiContext();

  const pageSize = Number(searchParams.get("pageSize") || 25);
  const pageCount = Number(searchParams.get("pageCount") || 1);

  useEffect(() => {
    setTableLoading(true);
    IssuedInvoiceApi.getAllIssuedInvoiceListWithPagn({
      pageNumber: pageCount,
      pageSize: pageSize,
    })
      .then((response: any) => {
        setData(response);
        setTableLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setTableLoading(false);
      });
  }, [IssuedInvoiceApi, pageCount, pageSize]);

  useEffect(() => {
    CurrencyApi.getCurrencyListForDropDown()
      .then((response) => setCurrencyList(response.currencies))
      .catch((error) => console.log(error));
  }, [CurrencyApi]);

  const deletePost = useCallback(() => {
    const del: any = {
      id: ids,
    };
    IssuedInvoiceApi.deleteIssuedInvoice(del)
      .then((response) => {
        toast.success(response.message);
        setIsDelModal(false);
        window.location.reload();
      })
      .catch((error) => toast.error(error.message));
  }, [ids, setIsDelModal, IssuedInvoiceApi]);

  const onChangeCurreny = useCallback(
    (value: any) => {
      setModalLoading(true);
      if (currencyFormModal.type === "send") {
        IssuedInvoiceApi.sendIssuid({
          id: currencyFormModal?.data?.id,
          currencyId: value.value,
        }).then((response: any) => {
          toast.success(response.message);
          setModalLoading(false);
        });
      } else if (currencyFormModal.type === "print") {
        IssuedInvoiceApi.printIssuid({
          id: currencyFormModal?.data?.id,
          fileName: currencyFormModal?.data?.code,
          currencyId: value.value,
        }).then(() => setModalLoading(false));
      }
      setCurrencyFormModal((prev: any) =>
        update(prev, {
          modalType: false,
        })
      );
    },
    [currencyFormModal, IssuedInvoiceApi]
  );

  return (
    <TabPage
      // headerComponent={
      //   <div className="h-100 d-flex align-items-center">
      //    {CheckUserClaim(profile, "CreateIssuedInvoice")?(
      //      <Button
      //      onClick={() => {
      //        navigate("/app/issued-invoice/form");
      //        dispatch(
      //          switchHeaderTitle({
      //            headerTitle: "Выставленные счета / Выставление счетов",
      //          })
      //        );
      //      }}
      //      className="text-light px-2 py-1 bg-gold"
      //    >
      //      Создать
      //    </Button>
      //    ):(
      //     <div />
      //    )}
      //   </div>
      // }
      // footerComponent={
      //   <div className="d-flex justify-content-between my-3">
      //    {CheckUserClaim(profile, "DeleteIssuedInvoices")?(
      //     <Button
      //     className="bg-danger px-2 py-2"
      //     onClick={() => {
      //       if (ids.length === 0) {
      //         toast.error("Please choose Issued Invoice");
      //       } else {
      //         setIsDelModal(true);
      //       }
      //     }}
      //     >
      //     <DeleteIcon color="white" size={16} />
      //     </Button>
      //    ):(
      //     <div />
      //    )}
      //     <Pagination
      //       pageNumber={data.pageNumber}
      //       totalCount={data.totalCount}
      //       totalPages={data.totalPages}
      //       onSubmit={(value: any) => console.log(value)}
      //     />
      //   </div>
      // }
    >
      <IssuedInvoiceTable
        onSend={(value: any) => {
          setCurrencyFormModal((prev: any) =>
            update(prev, {
              modalType: true,
              data: value,
              type: "send",
            })
          );
        }}
        onPrint={(value: any) => {
          setCurrencyFormModal((prev: any) =>
            update(prev, {
              modalType: true,
              data: value,
              type: "print",
            })
          );
        }}
        data={data.items}
        loading={tableLoading}
        selectRowCheckbox={setIds}
        selectRow={(value) =>
          navigate(`/app/issued-invoice/form?issuedId=${value.id}`)
        }
      />
      <Modal
        width="500px"
        show={isDelModal}
        closeHandler={() => setIsDelModal(false)}
        className="d-flex justify-content-center align-items-center"
      >
        <YesOrNoModal
          titleText="Вы уверены, что хотите удалить?"
          onClick={(value: boolean) => {
            if (value) {
              deletePost();
            } else {
              setIsDelModal(false);
            }
          }}
        />
      </Modal>
      <Modal
        show={currencyFormModal.modalType}
        closeHandler={() =>
          setCurrencyFormModal((prev: any) =>
            update(prev, { modalType: false })
          )
        }
        className="d-flex justify-content-center align-items-center"
        width="400px"
      >
        <div className="p-3">
          <GroupBox>
            <Formik initialValues={{ currency: "" }} onSubmit={noop}>
              {() => (
                <Form>
                  <SelectPickerField
                    label="Выберите валюту"
                    options={currencyList}
                    onChanges={(event) => onChangeCurreny(event)}
                    name="currency"
                  />
                </Form>
              )}
            </Formik>
          </GroupBox>
        </div>
      </Modal>
      <ModalLoader open={modalLoading} />
    </TabPage>
  );
}
