import { useSearchParams } from "react-router-dom";
import ByCourierParcelTable from "./ByCourierParcelTable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParcelApiContext } from "../../api/parcel/ParcelApiContext";
import TabPage from "../tabs/TabPage";
import Button from "../button/Button";
import { toast } from "react-toastify";
import { loadavg } from "os";

export default function ByCourierPickupParcelTableWrapper() {
    const [search, setSearch] = useSearchParams();
    const userId = useMemo(
        () => (search.get("userId") ? search.get("userId") : ""),
        []
    );
    const [ids, setIds] = useState([]);

    const pageSize = Number(search.get("pageSize") || 25);
    const pageCount = Number(search.get("pageCount") || 1);
    const [tableLoading, setTableLoading] = useState(false);
    const { ParcelApi } = useParcelApiContext();
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        if (Boolean(userId)) {
            setTableLoading(true);
            ParcelApi.getAllParcelByCourierPickup({
                pageNumber: pageCount,
                pageSize: pageSize,
                userId: Number(userId),
            })
                .then((respon: any) => {
                    setData(respon.items);
                    setTableLoading(false);
                })
                .catch((error) => {
                    toast.error(error.message);
                    setTableLoading(false);
                });
        }
    }, [userId, ParcelApi, setData]);

    const onPrint = useCallback(() => {
        const codes = [];
        for (let i = 0; i < ids.length; i++) {
            const code = Number(
                data.filter((item: any) => item.id === ids[0])[0].code
            );
            codes.push(code);
        }
        const query = {
            code: codes,
            id: userId,
        };
        ParcelApi.getJobList(query);
    }, [ids, data, userId, ParcelApi]);

    const onPrintInvoice = useCallback(
        (value: any) => {
            const query = {
                id: value.id,
            };
            ParcelApi.getInvoice(query);
        },
        [ParcelApi]
    );

    return (
        <TabPage
            childrenClassName="p-3"
            footerComponent={
                <Button
                    className="px-3 py-1 bg-warning text-light mt-2"
                    onClick={() => onPrint()}
                >
                    Print
                </Button>
            }
        >
            <ByCourierParcelTable
                data={data}
                loading={tableLoading}
                setIds={setIds}
                onPrint={(value: any) => onPrintInvoice(value)}
            />
        </TabPage>
    );
}
