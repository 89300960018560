import "./assets/tabs.scss";
import React, { Children, ReactElement } from "react";
import { TabPaneProps } from "./TabPane";

interface AppMenuProps {
  readonly onChangeTab: (value: string, title: string) => void;
  readonly children: ReactElement<TabPaneProps>[] | ReactElement<TabPaneProps>;
  readonly activeTab: string;
  readonly className?: string;
}

export default function Tabs({
  onChangeTab,
  activeTab,
  children,
  className,
}: AppMenuProps) {
  return (
    <div className={`tabs-wrapper w-100 ${className}`}>
      <div className="d-flex tabs-menu-wrapper">
        {Children.map(children, (child, index) => {
          return (
            <div
              className={`tabs-menu-item ${
                child.key === activeTab ? "activetabs-menu-item" : ""
              }`}
              onClick={() =>
                onChangeTab(child.key as string, child.props.children as string)
              }
            >
              {child.props.tab}
            </div>
          );
        })}
      </div>
      {Children.map(children, (child, index) => {
        if (child.key === activeTab) {
          return <div className="tabs-children">{child.props.children}</div>;
        }
      })}
    </div>
  );
}
