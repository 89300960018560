import React from "react";
import TabPage from "../tabs/TabPage";
import IncomeHistoryTable from "./IncomeHistoryTable";

export default function IncomeHistoryTableWrapper() {
    return (
        <TabPage childrenClassName="p-2">
            <IncomeHistoryTable/>
        </TabPage>
    );
}
