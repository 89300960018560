export interface GetApiesResponse {
  readonly GetList: string;
  readonly GetPagn: string;
  readonly GetOne: string;
  readonly Create: string;
  readonly Update: string;
  readonly Delete: string;
}

const api = (
  url: string,
  get: string,
  get1: string,
  getOne: string,
  post: string,
  put: string,
  delet: string
): GetApiesResponse => {
  return {
    GetList: url + get,
    GetPagn: url + get1,
    GetOne: url + getOne,
    Create: url + post,
    Update: url + put,
    Delete: url + delet,
  };
};

const GetApies = (tab: string): GetApiesResponse => {
  if (tab === "incomes")
    return api(
      "IncomesType",
      "/GetIncomesTypeList",
      "/GetIncomesTypeListWithPagination",
      "/GetIncomeTypeDetails",
      "/CreateIncomeType",
      "/UpdateIncomeType",
      "/DeleteIncomeTypes"
    );
  else if (tab === "salary")
    return api(
      "SalaryType",
      "/GetSalaryTypeList",
      "/GetSalaryTypeListWithPagination",
      "/GetSalaryTypeDetails",
      "/CreateSalaryType",
      "/UpdateSalaryType",
      "/DeleteSalaryTypes"
    );
  else if (tab === "expenses")
    return api(
      "ExpensesType",
      "/GetExpensesTypeList",
      "/GetExpensesTypeListWithPagination",
      "/GetExpenseTypeDetails",
      "/CreateExpenseType",
      "/UpdateExpenseType",
      "/DeleteExpenseTypes"
    );
  else return api("", "", "", "", "", "", "");
};

export default GetApies;
