const CheckUserRole = (array: any[], role: string) => {
    const roles =  array.filter((item: any)=>item === role);
    return roles.length > 0 ;
}

const CheckUserClaim = (profile: any, claim: string) => {
    const _claim = profile[claim];
    return Boolean(_claim);
}

export { CheckUserRole, CheckUserClaim }