import React, {useCallback, useMemo, useRef, useState} from 'react';
import {useShallowEqualSelector} from '../../hooks/useShallowSelector';
import {profileSelector} from '../../reducers/authReducer';
import EditIcon from '../icons/EditIcon';
import {useNavigate} from "react-router-dom";
import {useAccountApiContext} from "../../api/account/AccountApiContext";
import DeleteIcon from "../icons/DeleteIcon";
import {toast} from "react-toastify";
import {PaginationNumberFormatterParams} from "ag-grid-community";
import {AgGridReact} from "ag-grid-react";
import moment from "moment-timezone";
import {useMerchantApiContext} from "../../api/merchant/MerchantApiContext";

export default function MerchantTable() {

    const {MerchantApi} = useMerchantApiContext();
    const navigator = useNavigate();
    const {AccountApi} = useAccountApiContext();

    const profile: any = useShallowEqualSelector(profileSelector);
    const checkRole = useCallback(
        (role: string) => {
            const hasRole = profile.role.filter((ro: string) => ro === role);
            return hasRole.length > 0 ? true : false;
        },
        [profile]
    );

    const editButton = (params: any) => {
        navigator(`/app/merchant/form?merchantId=${params.data.id}`)
    }

    const openModal = (params: any) => {
        const userConfirmed = window.confirm("Are you sure you want to delete?");
        if (userConfirmed) {
            deleteMerchant(params.data.id);
        }
    };

    const deleteMerchant = useCallback((id: any) => {
        MerchantApi.deleteMerchants({id: [id]})
            .then((response) => {
                toast.success(response.message);
                window.location.reload();
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }, [AccountApi]);

    const datasource = {
        getRows(params: any) {
            const {startRow, endRow, filterModel, sortModel} = params.request
            let count = params.request.endRow - params.request.startRow; // or the cacheBlockSize
            let page = Math.ceil(params.request.endRow / count);

            const filterKeysNumberOfPoint = Object.keys(params.request.filterModel)
            filterKeysNumberOfPoint.forEach(filter => {
                if (filterKeysNumberOfPoint[0] === "numberOfPoint") {
                    // initialValues.numberOfPoint = "";
                    //   initialValues.numberOfPoint = `${filterModel[filter].filter}`
                }
            })
            const filterKeys = Object.keys(filterModel)
            filterKeys.forEach(filter => {
                if (filterKeys[0] === "code") {
                    //   initialValues.code = "";
                    // initialValues.code += `${filterModel[filter].filter}`
                }
            })

            const filterKeysFromDate = Object.keys(filterModel)

            filterKeysFromDate.forEach(filter => {
                if (filterKeys[0] === "dateCreatedString") {
                    if (filterModel[filter].dateFrom !== null) {
                        // initialValues.fromDate = "";
                        // initialValues.fromDate += `${filterModel[filter].dateFrom}`
                    }
                    if (filterModel[filter].dateTo !== null) {
                        // initialValues.toDate = "";
                        // initialValues.toDate += `${filterModel[filter].dateTo}`
                    }
                }
            })

            const filterKeysFromDateStatus = Object.keys(filterModel)
            filterKeysFromDateStatus.forEach(filter => {
                if (filterKeys[0] === "dateCreatedStatus") {
                    if (filterModel[filter].dateFrom !== null) {
                        // initialValues.fromDateStatus = "";
                        // initialValues.fromDateStatus += `${filterModel[filter].dateFrom}`
                    }
                    if (filterModel[filter].dateTo !== null) {
                        // initialValues.toDateStatus = "";
                        // initialValues.toDateStatus += `${filterModel[filter].dateTo}`
                    }
                }
            })

            // const data = {
            //     pageNumber: page,
            //     pageSize: count === 0 ? 1 : count,
            //     code: Number(initialValues.code),
            //     numberOfPoint: Number(initialValues.numberOfPoint),
            //     fromBranch: selectedFromBranch || ['Москва', 'Алматы', 'Бишкек', 'Душанбе', 'Стамбул', 'Ташкент'],
            //     toBranch: selectedToBranch || ['Москва', 'Алматы', 'Бишкек', 'Душанбе', 'Стамбул', 'Ташкент'],
            //     planId: selectedPlan || ['Стандарт', 'Экспресс', 'Ультра', 'Авто', 'Авто-Эконом'],
            //     statusId: selectedStatus || ['Создан', 'В пути', 'Прибыл', 'У курьера'],
            //     parcelTypeId: selectedParcelType || ['Посылка', 'Документ', 'Мед'],
            //     payerType: selectedPayer || ['Отправитель', 'Получатель', 'Юр. лицо (ВЫСТАВЛЕН СЧЕТ)'],
            //     dateCreateStart: initialValues.fromDate,
            //     dateCreateEnd: initialValues.toDate,
            //     dateCreateStatusStart: initialValues.fromDateStatus,
            //     dateCreateStatusEnd: initialValues.toDateStatus,
            // };

            MerchantApi.getAllMerchantListWithPagn({
                pageNumber: page,
                pageSize: count,
            })
                .then((response: any) => {
                    params.success({
                        rowData: response.items,
                        rowCount: response.items.totalCount,
                    });
                })
                .catch((error: any) => {
                    if (error.message === '') {
                        toast.error('Контрагент не найдена!');
                    } else {
                        toast.error(error.message);
                    }
                });
        }
    };

    const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
        return [20, 50, 100, 10000];
    }, []);

    const paginationNumberFormatter = useCallback(
        (params: PaginationNumberFormatterParams) => {
            return "[" + params.value.toLocaleString() + "]";
        },
        [],
    );

    const onGridReady = (params: any) => {
        params.api.updateGridOptions({serverSideDatasource: datasource})
    }

    const gridRef = useRef<AgGridReact>(null);
    const [columnDefs, setColDefs] = useState([
        // {
        //     headerName: "...",
        //     minWidth: 30,
        //     maxWidth: 60,
        //     checkboxSelection: true
        // },
        {
            field: "id",
            headerName: "№",
            minWidth: 70,
            maxWidth: 70,
            // filter: "agNumberColumnFilter"
        },
        {
            field: "tin",
            headerName: "ИНН",
            minWidth: 120,
            maxWidth: 120,
            // filter: "agNumberColumnFilter"
        },
        {
            field: "name",
            headerName: "Наименование",
            resizable: true,
            flex: 1,

            // filter: "agNumberColumnFilter", /*floatingFilter: true,*/
        },
        {
            field: "address",
            headerName: "Адрес",
            flex: 2,
            resizable: true,

            // filter: "agNumberColumnFilter", /*floatingFilter: true,*/
        },
        {
            field: "phone",
            headerName: "Контакты",
            hide: false,
            minWidth: 140,
            maxWidth: 140
        },
        {
            headerName: "Филиал",
            field: "branchName",
            minWidth: 100,
            maxWidth: 100,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['Москва', 'Ташкент', 'Алматы', 'Бишкек', 'Душанбе', 'Стамбул']
            }
        },

        {
            field: "dateCreated",
            headerName: "Дата",
            minWidth: 90,
            maxWidth: 90,
            cellRenderer: function (row: any) {
                return moment(row.data.dateCreated).format('DD.MM.yyyy')
            }
        },
        {
            headerName: "...",
            filter: false,
            field: "price",
            minWidth: checkRole('Administrator') ? 80 : 70,
            maxWidth: checkRole('Administrator') ? 80 : 70,
            cellRenderer: (params: any) =>
                <div className="btn-group" role="group" aria-label="Basic example">
                    <button type="button" className={'btn btn-sm'} onClick={() => editButton(params)}><EditIcon
                        color="green" size={10}/></button>
                    {checkRole('Administrator') &&
                        <button type="button" className={'btn btn-sm'} onClick={() => openModal(params)}><DeleteIcon
                            color="red" size={10}/></button>
                    }
                </div>
        },
    ]);

    return (
        <>
            <div
                className="ag-theme-balham"
                style={{height: '100%'}}
            >
                <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowSelection={'multiple'}
                    pagination={true}
                    paginationPageSize={20}
                    enableCellTextSelection={true}
                    rowModelType={"serverSide"}
                    onGridReady={onGridReady}
                    // onFilterChanged={onFilterChanged}
                    rowBuffer={10000}
                    cacheBlockSize={20}
                    enableRangeSelection={true}
                    maxBlocksInCache={2}
                    paginationPageSizeSelector={paginationPageSizeSelector}
                    paginationNumberFormatter={paginationNumberFormatter}
                    suppressMenuHide
                    defaultColDef={{filter: false, floatingFilter: false, sortable: false}}
                />
            </div>
        </>
    );
}
