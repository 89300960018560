import GroupBox from "../app/GroupBox";
import Button from "../button/Button";
import PrintIcon from "../icons/PrintIcon";
import Table from "../table/Table";

interface Props {
    readonly data: any[];
    readonly printParcelItem: (value: any, value2: any) => void;
}

export default function ParcelViewStatusTable({
                                                  data,
                                                  printParcelItem
                                              }: Props) {

    const headers: any = [
        {
            header: "Код",
            access: "code",
            width: 200,
        },
        {
            header: "Номер манифеста",
            access: "manifestCode",
            width: 200,
            ceil: (row: any) => {
                return (
                    <div className="d-flex">
                        <a href={`/app/manifest/form?manifestId=${row.manifestId}`}> {row.manifestCode}</a>
                        {/*<span className={`${s.isCurrent ? "fw-bold" : ""} my-2`}>{s.statusItemName} {s.dateCreated}</span>*/}
                    </div>
                )
            }
        },
        {
            header: "Статус",
            access: "statusName",
            width: 200,
            ceil: (row: any) => {
                return <>{
                    row.parcelItemStatus.map((s: any) => {
                        return (
                            <div className="d-flex">
                                <span
                                    className={`${s.isCurrent ? "fw-bold" : ""} my-2`}>{s.statusItemName} {s.dateCreated}</span>
                            </div>
                        )
                    })
                }</>;
            }
        },
        {
            header: "...",
            access: "statusName",
            width: 200,
            ceil: (row: any) => {
                return <div className="d-flex">
                    <Button
                        className="text-light py-2 px-2 bg-success ms-2"
                        onClick={() => printParcelItem(row.id, row.code)}
                    >
                        <PrintIcon color="white" size={15}/>
                    </Button>
                </div>
            },
        },
    ]

    return (
        <GroupBox>
            <Table data={data} headers={headers}  />
        </GroupBox>
    )
}