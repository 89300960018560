import {ApiProps, BaseApi} from "../BaseApi";
import { GetAccountListPagnQuery } from "./AccountDto";

export class AccountApi extends BaseApi {

    // constructor(apiProps: ApiProps) {
    //     super(apiProps);
    // }

    // public getOneAccountListPagn({
    //     pageNumber,
    //     pageSize,
    //     listType,
    //   }: GetAccountHistoryListPagnQuery) {
    //     return this.get(`AccountHistory/GetAccountHistoryListWithPagination`, {
    //         query: { pageNumber, pageSize, listType }
    //     });
    // }

    public getOneAccountListPagn(json: any) {
        return this.post("AccountHistory/GetAccountHistoryListWithPaginationCustom", {json});
    }

    public getAccountList() {
        return this.get(`Account/GetAccountList`);
    }

    public getAccountListPagn({
        pageNumber, 
        pageSize, 
        accountTypeId,
      }: GetAccountListPagnQuery) {
        return this.get(`Account/GetAccountListWithPagination`, {
            query: { pageNumber, pageSize, accountTypeId }
        });
    }

    public getAccountHistoryDetails(id: any) {
        return this.get(`AccountHistory/GetAccountHistoryDetails/${id}`);
    }

    public getAccountDetails(id: any) {
        return this.get(`Account/GetAccountDetails/${id}`);
    }

    public getAccountTypeList() {
        return this.get(`Account/GetAccountTypeList`);
    }

    public createAccountHistory(json: any) {
        return this.post("AccountHistory/CreateAccountHistory", { json });
    }
    
    public createAccount(json: any) {
        return this.post("Account/CreateAccount", { json });
    }

    public updateAccountHistory(json: any) {
        return this.put("AccountHistory/UpdateAccountHistory", { json });
    }

    public updateAccount(json: any) {
        return this.put("Account/UpdateAccount", { json });
    }

    public deleteAccountHistory(id: any) {
        return this.delete(`AccountHistory/DeleteAccountHistory/${id}`);
    }

    public deleteAccount(id: any) {
        return this.delete(`Account/DeleteAccount/${id}`);
    }
}
