import React, {useCallback, useMemo, useRef, useState} from "react";
import EditIcon from "../icons/EditIcon";
import PrintIcon from "../icons/PrintIcon";
import {useShallowEqualSelector} from "../../hooks/useShallowSelector";
import {profileSelector} from "../../reducers/authReducer";
import {AgGridReact} from "ag-grid-react";
import moment from "moment-timezone";
import DeleteIcon from "../icons/DeleteIcon";
import {toast} from "react-toastify";
import {useManifestApiContext} from "../../api/manifiest/ManfiestContext";
import {PaginationNumberFormatterParams} from 'ag-grid-community';
import {useNavigate} from "react-router-dom";
import ModalLoader from "../modal/MdalLoader";

export default function ManifestTable() {

    const navigator = useNavigate();
    const profile: any = useShallowEqualSelector(profileSelector);
    const {ManifestApi} = useManifestApiContext();
    const checkRole = useCallback(
        (role: string) => {
            const hasRole = profile.role.filter((ro: string) => ro === role);

            return hasRole.length > 0 ? true : false;
        },
        [profile]
    );
    const [isLoading, setIsLoading] = useState(false);
    const editButton = (params: any) => {
        navigator(`/app/manifest/form?manifestId=${params.data.id}`)
    }

    const printButton1 = (params: any) => {
        ManifestApi.exportManifest(Number(params.data.id)).then(r => "Success");
    }

    const printButton = (params: any) => {
        const userConfirmed = window.confirm("Are you sure you want to print?");
        if (userConfirmed) {
            setIsLoading(true);
            ManifestApi.exportManifest(Number(params.data.id)).then(r => "Success").then(() => {
                setIsLoading(false);
            })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    };


    const openModal = (params: any) => {
        const userConfirmed = window.confirm("Are you sure you want to delete?");
        if (userConfirmed) {
            deleteManifest(params.data.id);
        }
    };

    const deleteManifest = useCallback((id: any) => {
        ManifestApi.deleteManifest({id: [id]})
            .then((response) => {
                toast.success(response.message);
                window.location.reload();
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }, [ManifestApi]);

    const datasource = {
        getRows(params: any) {
            const {startRow, endRow, filterModel, sortModel} = params.request
            let count = params.request.endRow - params.request.startRow; // or the cacheBlockSize
            let page = Math.ceil(params.request.endRow / count);

            ManifestApi.getAllManifestListWithPagn({
                pageNumber: page,
                pageSize: count,
                // Add other necessary parameters here
            })
                .then((response: any) => {
                    params.success({
                        rowData: response.items,
                        rowCount: response.items.totalCount,
                    });
                })
                .catch((error: any) => {
                    if (error.message === '') {
                        toast.error('Посылка не найдена!');
                    } else {
                        toast.error(error.message);
                    }
                });
        }
    };

    const paginationPageSizeSelector = useMemo<number[] | boolean>(() => {
        return [20, 50, 100, 10000];
    }, []);

    const paginationNumberFormatter = useCallback(
        (params: PaginationNumberFormatterParams) => {
            return "[" + params.value.toLocaleString() + "]";
        },
        [],
    );
    const onGridReady = (params: any) => {
        params.api.updateGridOptions({serverSideDatasource: datasource})
    }
    const gridOptions = {
        // other grid options ...
        getRowId: (params: any) => {
            // return the ID for the row data item
            return params.data.id;
        },
    };
    const gridRef = useRef<AgGridReact>(null);
    const [columnDefs] = useState([
        // {
        //     headerName: "...",
        //     minWidth: 30,
        //     maxWidth: 60,
        //     checkboxSelection: true
        // },
        {
            field: "id",
            headerName: "№",
            minWidth: 70,
            maxWidth: 70,
            // filter: "agNumberColumnFilter"
        },
        {
            headerName: "Код",
            field: "code",
            minWidth: 100,
            maxWidth: 100,
            filter: "agNumberColumnFilter", /*floatingFilter: true,*/
        },
        {
            headerName: "Перевозчик",
            field: "driverTypeName",
            minWidth: 130,
            maxWidth: 130,
        },
        {
            headerName: "Тип посылки",
            field: "parcelType",
            // filter: true, /*floatingFilter: true*/
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['Посылка', 'Документ', 'Мед']
            },
            hide: true,
            minWidth: 100,
            maxWidth: 100
        },
        {
            headerName: "Оператор",
            field: "staffName",
            hide: false
        },
        {
            headerName: "От",
            field: "fromBranchName",
            minWidth: 80,
            maxWidth: 80,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['Москва', 'Ташкент', 'Алматы', 'Бишкек', 'Душанбе', 'Стамбул']
            }
        },
        {
            headerName: "До",
            field: "toBranchName",
            minWidth: 80,
            maxWidth: 80,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['Москва', 'Ташкент', 'Алматы', 'Бишкек', 'Душанбе', 'Стамбул']
            }
        },
        {
            headerName: "Тип",
            field: "manifestType",
            minWidth: 80,
            maxWidth: 80,
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['Авиа', 'Авто', 'Мед', 'Стандарт']
            }
        },
        {
            headerName: "Место",
            field: "parcelItemCount",
            minWidth: 90,
            maxWidth: 90,
            filter: "agNumberColumnFilter"
        },
        {
            headerName: "Прибывшие",
            field: "parcelItemCountNo",
            minWidth: 120,
            maxWidth: 120,
            filter: "agNumberColumnFilter"
        },
        {
            headerName: "Стоимость",
            field: "generalStateUsd",
            minWidth: 120,
            maxWidth: 120,
            cellRenderer: function (row: any) {
                //Oplachen
                if (row.data.manifestCost != null && row.data.manifestCost.stateCostUsd && row.data.manifestCost.costUsd > 0) {
                    return (
                        <div style={{color: 'green', fontWeight: 'normal'}}>
                            {parseFloat(row.data.manifestCost.costUsd)} $
                        </div>
                    );
                }
                //NeOplachen
                else if (row.data.manifestCost != null && !row.data.manifestCost.stateCostUsd && row.data.manifestCost.costUsd > 0) //danger
                {
                    return (
                        <div style={{color: 'red', fontWeight: 'normal'}}>
                            {parseFloat(row.data.manifestCost.costUsd)} $
                        </div>
                    );
                }
            }
        },
        {
            headerName: "Статус",
            field: "manifestStatus",
            minWidth: 90,
            maxWidth: 90,
            cellRenderer: function (row: any) {
                if (row.data.manifestStatus) {
                    const currentStatus = row.data.manifestStatus.find((status: any) => status.isCurrent);
                        return (
                            <div >
                                {currentStatus.statusManifest.name}
                            </div>
                        );
                }
            },
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['Создан', 'В пути', 'Прибыл', 'Доставлен', 'Выдан']
            }

        },
        {
            headerName: "Дата",
            minWidth: 90,
            maxWidth: 90,
            cellRenderer: function (row: any) {
                return moment(row.data.dateCreated).format('DD.MM.yyyy')
            }
        },
        {
            headerName: "...",
            field: "price",
            minWidth: checkRole('Administrator') ? 105 : 80,
            maxWidth: checkRole('Administrator') ? 105 : 80,
            cellRenderer: (params: any) =>
                <div className="btn-group" role="group" aria-label="Basic example">
                    <button type="button" className={'btn btn-sm'} onClick={() => editButton(params)}><EditIcon
                        color="green" size={10}/></button>
                    <button type="button" className={'btn btn-sm'} onClick={() => printButton(params)}><PrintIcon
                        color="orange" size={10}/></button>
                    {checkRole('Administrator') &&
                        <button type="button" className={'btn btn-sm'} onClick={() => openModal(params)}><DeleteIcon
                            color="red" size={10}/></button>
                    }
                </div>
        },
    ]);

    return (
        <>
            <div
                className="ag-theme-balham"
                style={{height: '100%'}}
            >
                <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    rowSelection={'multiple'}
                    pagination={true}
                    paginationPageSize={20}
                    enableCellTextSelection={true}
                    rowModelType={"serverSide"}
                    onGridReady={onGridReady}
                    rowBuffer={10000}
                    cacheBlockSize={20}
                    enableRangeSelection={true}
                    maxBlocksInCache={2}
                    gridOptions={gridOptions}
                    paginationPageSizeSelector={paginationPageSizeSelector}
                    paginationNumberFormatter={paginationNumberFormatter}
                    suppressMenuHide
                    defaultColDef={{filter: false, floatingFilter: false, sortable: false}}
                />
            </div>
            <ModalLoader open={isLoading} />
        </>
    );
}
