import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import Pagination from "../pagination/Pagination";
import Button from "../button/Button";
import TabPage from "../tabs/TabPage";
import Modal from "../modal/Modal";
import { useSearchParams } from "react-router-dom";
import YesOrNoModal from "../app/YesOrNoModal";
import RoleTable from "./RoleManagerTable";
import { useRoleApiContext } from "../../api/role/RoleApiContext";

interface RoleManagerTableWrapperProps {
  readonly create: () => void;
  readonly editRow: (value: any) => void;
}

export default function RoleManagerTableWrapper({
  create,
  editRow,
}: RoleManagerTableWrapperProps) {
  const { RoleApi } = useRoleApiContext();
  const [data, setData] = useState<any>({});
  const [id, setId] = useState(null);
  const [isDelModal, setIsDelModal] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tableLoading, setTableLoading] = useState(false);
  const pageSize = Number(searchParams.get("pageSize") || 25);
  const pageCount = Number(searchParams.get("pageCount") || 1);

  useEffect(() => {
    setTableLoading(true);
    RoleApi.getAllRole({
      pageNumber: pageCount,
      pageSize: pageSize,
    })
      .then((response: any) => {
        setData(response);
        setTableLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setTableLoading(false);
      });
  }, [RoleApi, pageCount, pageSize]);

  const deleteRow = useCallback(
    (id: any) => {
      RoleApi.deleteRole({ id: id })
        .then((response: any) => {
          toast.success(response.message);
          setIsDelModal(false);
          window.location.reload();
        })
        .catch((error: any) => {
          toast.error(error.message);
        });
      setId(null);
    },
    [setIsDelModal, setId, RoleApi]
  );

  return (
    <TabPage
      childrenClassName="p-2"
      headerComponent={
      <div className="d-flex align-items-center h-100">
        <Button
            onClick={() => create()}
            className="text-light px-2 py-1 bg-gold"
            >
            Создать
          </Button>
      </div>
       
      }
      footerComponent={
        <div className="d-flex justify-content-end my-3">
          <Pagination
            pageNumber={data.pageNumber}
            totalCount={data.totalCount}
            totalPages={data.totalPages}
            onSubmit={(value: any) => console.log(value)}
          />
        </div>
      }
    >
      <RoleTable
        editRow={editRow}
        deleteRow={(row: any) => {
          setId(row.id);
          setIsDelModal(true);
        }}
        data={data.items}
        loading={tableLoading}
      />
      <Modal
        width="500px"
        show={isDelModal}
        closeHandler={() => setIsDelModal(false)}
        className="d-flex justify-content-center align-items-center"
      >
        <YesOrNoModal
          titleText="Вы уверены, что хотите удалить?"
          onClick={(value: boolean) => {
            if (value) {
              deleteRow(id);
            } else {
              setIsDelModal(false);
            }
            setId(null);
          }}
        />
      </Modal>
    </TabPage>
  );
}
