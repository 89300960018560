import React, { useCallback, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { number, object, string } from 'yup';
import GroupBox from '../app/GroupBox';
import InputField from '../form/InputField';
import { update } from 'immupdate';
import Button from '../button/Button';
import SelectPickerField from '../form/SelectPickerField';
import { useShallowEqualSelector } from '../../hooks/useShallowSelector';
import { profileSelector } from '../../reducers/authReducer';
import { CheckUserRole } from '../../utils/CheckUserInfo';

interface BranchFormProps {
    readonly initialValues: any;
    readonly setInitialValues: (value: any) => void;
    readonly searchMerchant: (value: any) => void;
    readonly searchParcel: (value: any) => void;
    readonly branches: any[];
    readonly merchants: any[];
    readonly parcels: any[];
    readonly submit: (value: any) => void;
}

const validationSchema = object({
    branchId: object().required('Required!'),
    merchantId: object().required('Required!'),
    parcelId: object().required('Required!'),
});

export default function IssuedInvoiceForm({
    initialValues,
    setInitialValues,
    searchMerchant,
    searchParcel,
    branches,
    merchants,
    parcels,
    submit,
}: BranchFormProps) {
    const profile: any = useShallowEqualSelector(profileSelector);

    const isAdmin = useMemo(
        () => CheckUserRole(profile?.role, 'Administrator'),
        [profile.role]
    );

    const onChangeBranchId = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    branchId: {
                        label: value.label,
                        value: value.value,
                    },
                })
            );
        },
        [setInitialValues]
    );

    const onChangeMerchantId = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    merchantId: {
                        label: value.label,
                        value: value.value,
                    },
                })
            );
        },
        [setInitialValues]
    );

    const onChangeParcelId = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    parcelId: {
                        label: value.label,
                        value: value.value,
                    },
                })
            );
        },
        [setInitialValues]
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={() => submit(initialValues)}
        >
            {() => (
                <Form>
                    <div className="row">
                        <div className="col-12">
                            <GroupBox title="Выставление счетов">
                                <div className="row">
                                    <div className="col-6">
                                        <SelectPickerField
                                            label="Филиал"
                                            name="branchId"
                                            options={branches}
                                            onChanges={onChangeBranchId}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <SelectPickerField
                                            label="Юр. лицо"
                                            name="merchantId"
                                            options={merchants}
                                            onChanges={onChangeMerchantId}
                                            onInputChange={searchMerchant}
                                            isSearchable
                                        />
                                    </div>
                                    <div className="col-6">
                                        <SelectPickerField
                                            label="Полсылка"
                                            name="parcelId"
                                            options={parcels}
                                            onChanges={onChangeParcelId}
                                            onInputChange={searchParcel}
                                            isSearchable
                                        />
                                    </div>
                                </div>
                            </GroupBox>
                        </div>
                        <div className="col-12 mt-3">
                            <Button
                                type="submit"
                                className="text-light bg-gold px-2 py-1"
                            >
                                Сохранить
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
