import { SWRConfig } from "swr";
import { ReactNode, useMemo } from "react";

import { useShallowEqualSelector } from "../hooks/useShallowSelector";
import { ApiProvider } from "../api/ApiContext";
import { tokenSelector } from "../reducers/authReducer";

interface Props {
  readonly children: ReactNode;
}

export function ProviderContainer({ children }: Props) {
  const token = useShallowEqualSelector(tokenSelector);

  return (
    <ApiProvider data={{ token }}>
      <SWRConfig value={{ revalidateOnFocus: false }}>{children}</SWRConfig>
    </ApiProvider>
  );
}
