import React, {useCallback} from 'react';
import {Form, Formik} from 'formik';
import {update} from 'immupdate';

import GroupBox from '../app/GroupBox';
import InputField from '../form/InputField';
import Button from '../button/Button';
import SelectPickerField from '../form/SelectPickerField';
import {useShallowEqualSelector} from "../../hooks/useShallowSelector";
import {profileSelector} from "../../reducers/authReducer";

interface StatusFormProps {
    readonly initialValues: any;
    readonly setInitialValues: (value: any) => void;
    readonly searchSender: (value: any) => void;
    readonly searchMerchant: (value: any) => void;
    readonly submit: (value: any) => void;
    readonly senders: any[];
    readonly merchants: any[];
    readonly currences: any[];
    readonly branches: any[];
    readonly accountState: any[];
    readonly accountTypes: any[];
    readonly paymentMethods: any[];
}

export default function AccountForm({
                                        initialValues,
                                        setInitialValues,
                                        searchSender,
                                        searchMerchant,
                                        submit,
                                        senders,
                                        merchants,
                                        accountTypes,
                                        accountState,
                                        branches,
                                        paymentMethods,
                                        currences,
                                    }: StatusFormProps) {
    const onChangeName = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    name: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    const profile: any = useShallowEqualSelector(profileSelector);

    const checkRole = useCallback(
        (role: string) => {
            const hasRole = profile.role.filter((ro: string) => ro === role);

            return hasRole.length > 0 ? true : false;
        },
        [profile]
    );

    const onChangeNumber = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    number: value.target.value,
                })
            );
        },
        [setInitialValues]
    );

    const onChangeSenderId = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    userId: {
                        label: value.label,
                        value: value.value,
                    },
                })
            );
        },
        [setInitialValues]
    );

    const onChangeMerchantId = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    merchantId: {
                        label: value.label,
                        value: value.value,
                    },
                })
            );
        },
        [setInitialValues]
    );

    const onChangeCurrencyId = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    currencyId: {
                        label: value.label,
                        value: value.value,
                    },
                })
            );
        },
        [setInitialValues]
    );

    const onChangeBranchId = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    branchId: {
                        label: value.label,
                        value: value.value,
                    },
                })
            );
        },
        [setInitialValues]
    );

    const onChangeAccountState = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                        isActive: {
                            label: value.label,
                            value: value.value,
                        },
                    }
                )
            );
        },
        [setInitialValues]
    );

    const onChangeAccountTypeId = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    accountTypeId: {
                        label: value.label,
                        value: value.value,
                    },
                })
            );
        },
        [setInitialValues]
    );

    const onChangePaymentMethodId = useCallback(
        (value: any) => {
            setInitialValues((prev: any) =>
                update(prev, {
                    paymentMethodId: {
                        label: value.label,
                        value: value.value,
                    },
                })
            );
        },
        [setInitialValues]
    );

    // user, coirer, staff , manager bosa merchant uchadi
    // merchant bosa user uchadi
    // branch bosa user, merchant uchadi

    const merchantDisabled = useCallback((value: Number) => {
        if (
            value === 1 ||
            value === 3 ||
            value === 4 ||
            value === 5 ||
            value === 6
        )
            return true;
        else return false;
    }, []);

    const userDisabled = useCallback((value: Number) => {
        if (value === 2) return true;
        else return false;
    }, []);

    const userMerchantDisabled = useCallback((value: Number) => {
        if (
            value === 1
        )
            return true;
        else return false;
    }, []);

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={() => submit(initialValues)}
        >
            {() => (
                <Form>
                    <div className="row mt-3">
                        <div className="col-12">
                            <GroupBox title="Счет">
                                <div className="row">
                                    <div className="col-6">
                                        <InputField
                                            label="Наименование счета"
                                            name="name"
                                            value={initialValues.name}
                                            onChange={(event: any) =>
                                                onChangeName(event)
                                            }
                                        />
                                    </div>
                                    <div className="col-6">
                                        <InputField
                                            label="Номер счета"
                                            name="number"
                                            value={initialValues.number}
                                            onChange={(event: any) =>
                                                onChangeNumber(event)
                                            }
                                        />
                                    </div>
                                    <div className="col-3">
                                        <SelectPickerField
                                            name="currencyId"
                                            label="Валюта"
                                            options={currences}
                                            onChanges={(value) =>
                                                onChangeCurrencyId(value)
                                            }
                                        />
                                    </div>
                                    {(checkRole('Administrator') ||
                                            checkRole('Manager')) &&
                                        <div className="col-3">
                                            <SelectPickerField
                                                name="branchId"
                                                label="Филиал"
                                                options={branches}
                                                onChanges={(value) =>
                                                    onChangeBranchId(value)
                                                }
                                            />
                                        </div>
                                    }
                                    <div className="col-3">
                                        <SelectPickerField
                                            name="accountTypeId"
                                            label="Тип счета"
                                            options={accountTypes}
                                            onChanges={(value) =>
                                                onChangeAccountTypeId(value)
                                            }
                                        />
                                    </div>
                                    <div className="col-3">
                                        <SelectPickerField
                                            name="paymentMethodId"
                                            label="Метод оплаты"
                                            options={paymentMethods}
                                            onChanges={(value) =>
                                                onChangePaymentMethodId(value)
                                            }
                                        />
                                    </div>
                                    <div className="col-6">
                                        <SelectPickerField
                                            name="userId"
                                            label="Пользователь"
                                            disabled={
                                                userDisabled(
                                                    initialValues.accountTypeId.value
                                                ) ||
                                                userMerchantDisabled(
                                                    initialValues.accountTypeId.value
                                                )
                                            }
                                            options={senders}
                                            onChanges={(value) =>
                                                onChangeSenderId(value)
                                            }
                                            id="userId"
                                            isSearchable
                                            onInputChange={(value) =>
                                                searchSender(value)
                                            }
                                        />
                                    </div>
                                    <div className="col-6">
                                        <SelectPickerField
                                            name="merchantId"
                                            label="Юр. лицо"
                                            disabled={
                                                merchantDisabled(
                                                    initialValues.accountTypeId.value
                                                ) ||
                                                userMerchantDisabled(
                                                    initialValues.accountTypeId.value
                                                )
                                            }
                                            options={merchants}
                                            onChanges={(value) =>
                                                onChangeMerchantId(value)
                                            }
                                            id="merchantId"
                                            isSearchable
                                            onInputChange={(value) =>
                                                searchMerchant(value)
                                            }
                                        />
                                    </div>

                                    <div className="col-12">
                                        <SelectPickerField
                                            name="isActive"
                                            label="Состояние счета"
                                            options={accountState}
                                            onChanges={(value) =>
                                                onChangeAccountState(value)
                                            }
                                        />
                                    </div>

                                </div>
                            </GroupBox>
                        </div>
                        <div className="col-12 mt-3">
                            <Button
                                type="submit"
                                className="text-light bg-gold px-2 py-1"
                            >
                                Сохранить
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
