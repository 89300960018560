import React, { useEffect } from "react";
import Table from "../table/Table";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { profileSelector } from "../../reducers/authReducer";

interface BranchTableProps {
  readonly data: any;
  readonly loading: boolean;
  readonly selectRowCheckbox: (value: any) => void;
}

export default function CurrencyTypeTable({
  data,
  loading,
  selectRowCheckbox,
}: BranchTableProps) {

  const profile: any = useShallowEqualSelector(profileSelector);

  const headers: any = [
    {
      header: '№',
      access: 'id',
      width: 40
    },
    {
      header: "Номер",
      access: "number",
      width: 100,
    },
    {
      header: "Код",
      access: "code",
      width: 100,
    },
    {
      header: "Название",
      access: "name",
      width: 200,
    },
    {
      header: "Страна",
      access: "country",
      width: 200,
    },
  ];

  return (
    <Table
      selectRowCheckbox={selectRowCheckbox}
      loading={loading}
      data={data}
      headers={headers}
      withCheckbox={true}
    />
  );
}
