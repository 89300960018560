import React from "react";
import TabPage from "../tabs/TabPage";
import ExpenseHistoryTable from "./ExpenseHistoryTable";



export default function ExpenseHistoryTableWrapper() {
    return (
        <TabPage childrenClassName="p-2">
            <ExpenseHistoryTable/>
        </TabPage>
    );
}
