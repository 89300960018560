import { useMemo } from "react";
import { UserApi } from "./UserApi";
import { useApiBase } from "../ApiContext";
import {useNavigate} from "react-router-dom";
import { useDispatch } from "react-redux";
interface Props{
    readonly UserApi: UserApi;
}

export function useUserApiContext():Props{
    const data = useApiBase();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const api = useMemo(() => new UserApi(data), []);
    const api = useMemo(() => new UserApi({...data, navigate, dispatch}), []);

    return {
        UserApi: api
    }
}