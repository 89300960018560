import { BaseApi } from "../BaseApi";
import {
  CreateMerchantBody,
  DeleteMerchantBody,
  GetAllWithPaginationQuery,
  GetOneMerchantQuery,
  UpdateMerchantBody,
} from "./MerchantDto";

export class MerchantApi extends BaseApi {
  public getAllMerchantListWithPagn({
    pageNumber,
    pageSize,
  }: GetAllWithPaginationQuery) {
    return this.get(`Merchant/GetMerchantListWithPagination`, {
      query: { pageNumber, pageSize },
    });
  }
  public getMerchantList(): Promise<any> {
    return this.get(`Merchant/GetMerchantList`);
  }

  public getMerchantListWithSearch(searchText: string) {
    return this.get(`Merchant/SearchMerchantWithSkip`, {
      query: { searchText, skip: 0, top: 20 },
    });
  }

  public getOneMerchant({ id }: GetOneMerchantQuery) {
    return this.get(`Merchant/GetMerchantDetails/:id`, {
      params: { id },
    });
  }

  public creteMerchant(json: CreateMerchantBody) {
    return this.post(`Merchant/CreateMerchant`, {
      json,
    });
  }

  public updateMerchant(json: UpdateMerchantBody) {
    return this.put(`Merchant/UpdateMerchant`, {
      json,
    });
  }

  public deleteMerchants(json: DeleteMerchantBody) {
    return this.post(`Merchant/DeleteMerchantes`, {
      json,
    });
  }
}
