import { useParams } from "react-router-dom";
import ContainerLayout from "../components/app/ContainerLayout";
import CurrencyFormWrapper from "../components/currency/CurrencyFromWrapper";
import CurrencyTableWrapper from "../components/currency/CurrencyTableWrapper";

export default function CurrencyConatiener(){
    const { tab = "table" } = useParams();
    return (
        <ContainerLayout>
            {tab === "table" && (
                <CurrencyTableWrapper
                />
            )}
            {tab === "form" && (
                <CurrencyFormWrapper
                />
            )}
        </ContainerLayout>
    )
}