import { useCallback, useEffect, useState } from "react";
import { useUserApiContext } from "../../api/user/UserApiContext";
import { Form, Formik } from "formik";
import { update } from "immupdate";
import { object } from "yup";

import GroupBox from "../app/GroupBox";
import Button from "../button/Button";
import SelectPickerField from "../form/SelectPickerField";

interface UserManagerFormProps{
    readonly initialValues: any;
    readonly setInitialValues: (value: any) => void;
    readonly submit: (value: any) => void;
}

const validationSchema = object({
    // claim: string().required("Required!"),
})

export default function UserManagerEditClaimForm({
    initialValues, 
    setInitialValues, 
    submit
}:UserManagerFormProps){

    const { UserApi } = useUserApiContext();

    const [claims, setClaims] = useState([])

    useEffect(()=>{
        UserApi.getAllPolicy()
        .then((response)=>setClaims(response.policyList))
        .catch((error)=>console.log(error))
    },[UserApi])

    const onChangeClaim = useCallback((value: any)=>{
        setInitialValues((prev: any)=>update(prev, {
            userClaims: value.target.value
        }))
    },[setInitialValues])

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={submit}
            >
            {()=>(
                <Form>
                    <div className="row">
                        <div className="col-12">
                            <GroupBox>
                                    <div className="row">
                                        <div className="col-12">
                                        <SelectPickerField
                                            label="Доступы"
                                            name="userClaims"
                                            options={claims}
                                            className="w-100"
                                            isMulti
                                            isSearchable
                                            onChange={(event: any)=>onChangeClaim(event)}
                                         />
                                        </div>
                                    </div>
                            </GroupBox>
                        </div>
                        <div className="col-12 mt-3 d-flex justify-content-end">
                            <Button type="submit" className="text-light bg-gold px-2 py-1 ">
                                Сохранить
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}