import { useMemo } from "react";
import { CurrencyApi } from "./CurrencyApi";
import { useApiBase } from "../ApiContext";
import {useDispatch} from "react-redux";

interface Props {
  readonly CurrencyApi: CurrencyApi;
}

export function useCurrencyApiContext(): Props {
  const data = useApiBase();
  const dispatch = useDispatch();
  // const api = useMemo(() => new CurrencyApi(data), [data]);
  const api = useMemo(() => new CurrencyApi({...data,  dispatch}), [data]);

  return {
    CurrencyApi: api,
  };
}
