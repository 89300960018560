import { useParams } from "react-router-dom";
import IssuedInvoiceTableWrapper from "./IssuedInvoiceTableWrapper";
import IssuedInvoiceFormWrapper from "./IssuedInvoiceFormWrapper";

export default function IssuedInvoiceTab() {
  const { tab = "table" } = useParams();

  return (
    <>
      {tab === "table" && <IssuedInvoiceTableWrapper />}
      {tab === "form" && <IssuedInvoiceFormWrapper />}
    </>
  );
}
