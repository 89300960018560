import ContainerLayout from "../app/ContainerLayout";
import CurrencyTypeTableWrapper from "../currency/CurrencyTypeTableWrapper";

export default function CurerncyTab() {
  return (
    <ContainerLayout>
      <CurrencyTypeTableWrapper />
    </ContainerLayout>
  );
}
