import HomeIcon from "../icons/HomeIcon";
import ManagerIcon from "../icons/ManagerIcon";
import ParcelIcon from "../icons/ParcelIcon";
import StatusIcon from "../icons/StatusIcon";
import DashboardCard from "./DashboardCard";

interface Props {
  readonly data: any;
}

export default function DashboardCards({ data }: Props) {
  return (
    <div className="row">
      {data.branchCount && (
        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-6 my-2">
          <DashboardCard
            icon={<HomeIcon color="white" size={30} />}
            color="olive"
            title="Филиалы"
            count={data?.branchCount}
          />
        </div>
      )}
      {data.managerCount && (
        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-6 my-2">
          <DashboardCard
            icon={<ManagerIcon color="white" size={30} />}
            color="blue"
            title="Менеджеры"
            count={data?.managerCount?.total}
          />
        </div>
      )}
      {data.staffCount && (
        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-6 my-2">
          <DashboardCard
            icon={<ManagerIcon color="white" size={30} />}
            color="green"
            title="Сотрудники"
            count={data?.staffCount?.total}
          />
        </div>
      )}
      {data.courierCount && (
        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-6 my-2">
          <DashboardCard
            icon={<ManagerIcon color="white" size={30} />}
            color="orange"
            title="Курьеры"
            count={data?.courierCount?.total}
          />
        </div>
      )}
      {data.customerCount && (
        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-6 my-2">
          <DashboardCard
            icon={<ManagerIcon color="white" size={30} />}
            color="maroon"
            title="Клиенты"
            count={data?.customerCount?.total}
          />
        </div>
      )}
      {data.parcelCount && (
        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-6 my-2">
          <DashboardCard
            icon={<ParcelIcon color="white" size={30} />}
            color="purple"
            title="Все посылки"
            count={data?.parcelCount?.total}
            fromMe={data?.parcelCount?.fromMe}
            toMe={data?.parcelCount?.toMe}
          />
        </div>
      )}
      {data.sozdan && (
        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-6 my-2">
          <DashboardCard
            icon={<StatusIcon color="white" size={30} />}
            color="tan"
            title="Созданные"
            count={data?.sozdan?.total}
            fromMe={data?.sozdan?.fromMe}
            toMe={data?.sozdan?.toMe}
          />
        </div>
      )}
      {data.vPuti && (
        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-6 my-2">
          <DashboardCard
            icon={<StatusIcon color="white" size={30} />}
            color="navy"
            title="В пути"
            count={data?.vPuti?.total}
            fromMe={data?.vPuti?.fromMe}
            toMe={data?.vPuti?.toMe}
          />
        </div>
      )}
      {data.pribil && (
        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-6 my-2">
          <DashboardCard
            icon={<StatusIcon color="white" size={30} />}
            color="magenta"
            title="Прибывшие"
            count={data?.pribil?.total}
            fromMe={data?.pribil?.fromMe}
            toMe={data?.pribil?.toMe}
          />
        </div>
      )}
      {data.uKurera && (
        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-6 my-2">
          <DashboardCard
            icon={<StatusIcon color="white" size={30} />}
            color="indigo"
            title="У курьера"
            count={data?.uKurera?.total}
            fromMe={data?.uKurera?.fromMe}
            toMe={data?.uKurera?.toMe}
          />
        </div>
      )}
      {data.vozvrat && (
        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-6 my-2">
          <DashboardCard
            icon={<StatusIcon color="white" size={30} />}
            color="teal"
            title="Возвращенные"
            count={data?.vozvrat?.total}
            fromMe={data?.vozvrat?.fromMe}
            toMe={data?.vozvrat?.toMe}
          />
        </div>
      )}
      {data.dostavlen && (
        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-6 my-2">
          <DashboardCard
            icon={<StatusIcon color="white" size={30} />}
            color="coral"
            title="Доставленные"
            count={data?.dostavlen?.total}
            fromMe={data?.dostavlen?.fromMe}
            toMe={data?.dostavlen?.toMe}
          />
        </div>
      )}
      {data.vidan && (
        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-6 my-2">
          <DashboardCard
            icon={<StatusIcon color="white" size={30} />}
            color="silver"
            title="Выданные"
            count={data?.vidan?.total}
            fromMe={data?.vidan?.fromMe}
            toMe={data?.vidan?.toMe}
          />
        </div>
      )}
      {data.uteryan && (
        <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-6 my-2">
          <DashboardCard
            icon={<StatusIcon color="white" size={30} />}
            color="cyan"
            title="Утерянные"
            count={data?.uteryan?.total}
            fromMe={data?.uteryan?.fromMe}
            toMe={data?.uteryan?.toMe}
          />
        </div>
      )}
      {data.uteryan && (
          <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-6 my-2">
            <DashboardCard
                icon={<StatusIcon color="white" size={30} />}
                color="cyan"
                title="Курс валюты"
                count={data?.usdRate ? data?.usdRate : "Дневной курс валюты не установлен!" }
            />
          </div>
      )}
    </div>
  );
}
