import { useMemo } from "react";

import { useApiBase } from "../ApiContext";
import {ParcelTypeApi} from "./ParcelTypeApi";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

interface Props {
    readonly ParcelTypeApi: ParcelTypeApi;
}

export function useParcelTypeApiContext(): Props {
    const data = useApiBase();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const api = useMemo(() => new ParcelTypeApi(data), []);
    const api = useMemo(() => new ParcelTypeApi({...data, navigate, dispatch}), []);

    return {
        ParcelTypeApi: api,
    };
}
