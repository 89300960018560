import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CreateOrderProps } from "../../api/order/OrderDto";
import { toast } from "react-toastify";
import { useCustomerApiContext } from "../../api/customer/CustomerContext";

import Button from "../button/Button";
import TabPage from "../tabs/TabPage";
import CustomerOrderForm from "./CustomerOrderForm";

export default function CustomerOrderFormWrapper() {
  const [initialValues, setInitialValues] = useState<CreateOrderProps>({
    name: "",
    cost: 0,
    link: "",
    description: "",
  });

  const [search, setSearchParams] = useSearchParams();

  const { CustomerApi } = useCustomerApiContext();

  const orderId = useMemo(() => search.get("customerOrderId") || "", [search]);

  useEffect(() => {
    if (Boolean(orderId)) {
      CustomerApi.getOneMyOrder({ id: Number(orderId) })
        .then((response: any) => setInitialValues(response))
        .catch((error) => console.log(error));
    }
  }, [CustomerApi, orderId, setInitialValues]);

  const onSubmit = useCallback(
    (value: CreateOrderProps) => {
      if (Boolean(orderId)) {
        const data = {
          id: Number(orderId),
          ...value,
        };
        CustomerApi.updateMyOrder(data)
          .then((response) => {
            toast.success(response.message);
            setSearchParams({ pageType: "table" });
          })
          .catch((error) => console.log(error));
      } else {
        const data = {
          ...value,
        };
        CustomerApi.createMyOrder(data)
          .then((response) => {
            toast.success(response.message);
            setSearchParams({ pageType: "table" });
          })
          .catch((error) => console.log(error));
      }
    },
    [orderId]
  );

  return (
    <TabPage
      headerComponent={
        <div className="h-100 d-flex align-items-center">
          <Button
            onClick={() => setSearchParams({ pageType: "table" })}
            className="text-light px-2 py-1 bg-gold"
          >
            Назадь
          </Button>
        </div>
      }
    >
      <CustomerOrderForm
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        onSubmit={onSubmit}
      />
    </TabPage>
  );
}
