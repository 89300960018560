import { useState } from "react";
import AppView from "./AppView";
import ParcelViewImageList from "./ParcelViewImageList";
import ParcelViewStatusTable from "./ParcelViewStatusTable";

interface Props{
    readonly data: any;
    readonly dateOnly: string;
    readonly printParcel: (value: any) => void;
    readonly printParcel2: (value: any) => void;
    readonly printParcel3: (value: any) => void;
    readonly printParcelItem: ({parcelItemId, code}: any) => void;
    readonly editParcel: (value: any) => void;
}

export default function ParcelView({
    data,
    printParcelItem,
    editParcel,
                                       dateOnly
}:Props){


    //Self
    const [imageIndex, setImageIndex] = useState(0);

    return (
    <div>
        <AppView 
            data={data}
            imageIndex={imageIndex}
            editParcel={editParcel}
            dateOnly={dateOnly}
            />

        <ParcelViewImageList
            images={data.parcelImage}
            dateOnly={dateOnly}
            setImage={(value: any)=>setImageIndex(value)}
            />

        <ParcelViewStatusTable
            data={data.parcelItem}
            printParcelItem={(parcelItemId, code)=>printParcelItem({parcelItemId, id: data.id, code: code})
            }
            />
    </div>)
}