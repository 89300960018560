import { useMemo } from "react";
import { useApiBase } from "../ApiContext";
import { IssuedInvoiceApi } from "./IssuedInvoiceApi";
import {useDispatch} from "react-redux";

interface Props {
  readonly IssuedInvoiceApi: IssuedInvoiceApi;
}

export function useIssuedInvoiceApiContext(): Props {
  const data = useApiBase();
  const dispatch = useDispatch();
  // const api = useMemo(() => new IssuedInvoiceApi(data), []);
  const api = useMemo(() => new IssuedInvoiceApi({...data, dispatch}), []);

  return {
    IssuedInvoiceApi: api,
  };
}
