import { IdProps } from '../AppDto';
import { BaseApi } from '../BaseApi';
import { GetAllRole } from './PlanDto';

export class PlanApi extends BaseApi {
    public getAllPlan({ pageNumber, pageSize }: GetAllRole): Promise<any> {
        return this.get(
            `Plan/GetPlanListWithPagination?pageNumber=${pageNumber}&pageSize=${pageSize}`
        );
    }

    public getPlanById({ id }: IdProps): Promise<any> {
        return this.get(`Plan/GetPlanDetails/${id}`);
    }

    public deletePlan({ del }: any): Promise<any> {
        return this.post(`Plan/DeletePlans`, del);
    }

    public updatePlan(json: any) {
        return this.put('Plan/UpdatePlan', { json });
    }

    public createPlan(json: any) {
        return this.post('Plan/CreatePlan', { json });
    }

    public getPlanList(): Promise<any> {
        return this.get(`Plan/GetPlanList`);
    }
}
