import { useEffect, useState } from "react";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { profileSelector } from "../../reducers/authReducer";
import { CheckUserClaim } from "../../utils/CheckUserInfo";

import TableButton from "../button/TableButton";
import EditIcon from "../icons/EditIcon";
import Table from "../table/Table";
import { DateFormatter } from "../../utils/DateFormatter";

interface Props {
  readonly data: any[];
  readonly loading: boolean;
  readonly selectRow: (value: any) => void;
  readonly editRow: (value: any) => void;
}

export default function OrdersTable({
  data,
  loading,
  editRow,
  selectRow,
}: Props) {
  const [tableHeaders, setTableHeaders] = useState([]);

  const profile = useShallowEqualSelector(profileSelector);

  const [headers]: any[] = useState([
    {
      header: "№",
      access: "id",
      width: 60,
    },
    {
      header: "Название товара",
      access: "name",
      width: 200,
    },
    {
      header: "Ссылка",
      access: "link",
      width: 200,
    },
    {
      header: "Стоимость",
      access: "cost",
      width: 100,
    },
    {
      header: "Описание",
      access: "description",
      width: 200,
    },
    {
      header: "Дата",
      access: "dateCreated",
      width: 100,
      ceil: (row: any) => {
        return <>{DateFormatter(row.dateCreated)}</>;
      },
    },
    {
      header: "...",
      access: "edit",
      ceil: (row: any) => {
        return (
          <div className="d-flex">
            {CheckUserClaim(profile, "UpdateOrder") ? (
              <TableButton
                className="bg-warning ms-2"
                onClick={() => editRow(row)}
              >
                <EditIcon color="white" size={14} />
              </TableButton>
            ) : (
              <div />
            )}
          </div>
        );
      },
      width: 200,
    },
  ]);

  useEffect(() => {
    const data: any = [...headers];
    if (!CheckUserClaim(profile, "UpdateOrder")) {
      data.splice(data.length - 1, 1);
    }
    setTableHeaders(data);
  }, [headers, profile]);

  return (
    <Table
      headers={tableHeaders}
      data={data}
      withCheckbox={true}
      selectRowCheckbox={selectRow}
      loading={loading}
    />
  );
}
