import { useMemo } from "react";
import { RoleApi } from "./RoleApi";
import { useApiBase } from "../ApiContext";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

interface Props {
  readonly RoleApi: RoleApi;
}

export function useRoleApiContext(): Props {
  const data = useApiBase();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const api = useMemo(() => new RoleApi(data), []);
  const api = useMemo(() => new RoleApi({...data, navigate, dispatch}), []);

  return {
    RoleApi: api,
  };
}
