import React, {useState, useCallback, useEffect, useMemo} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useMerchantApiContext} from "../../api/merchant/MerchantApiContext";
import {update} from "immupdate";
import {toast} from "react-toastify";

import Button from "../button/Button";
import TabPage from "../tabs/TabPage";
import MerchantForm from "./MerchantForm";
import {useBranchApiContext} from "../../api/branch/BranchApiContext";

export default function MerchantFormWrapper() {
    const {MerchantApi} = useMerchantApiContext();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = useMemo(() => searchParams.get("merchantId"), [searchParams]);
    const [branches, setBranches] = useState<any>([]);
    const {BranchApi} = useBranchApiContext();

    const [initialValues, setInitialValues] = useState({
        name: "", //Input text buladi
        kpp: "", //Input text buladi
        tin: "", //Input text buladi
        address: "", //Input text buladi
        email: "", //Input text buladi
        phone: "", //Input text buladi
        branchId: "", //opdown buladi GET (Branch/GetBranchList)

    });

    useEffect(() => {
        BranchApi.getBranchList()
            .then((response: any) => {
                response.branches.map((item: any) => {
                    const data = {
                        label: item.name,
                        value: item.id,
                    };
                    setBranches((user: any) => [...user, data]);
                });
            })
            .catch((error) => console.log(error.message));
    }, [setBranches, BranchApi]);

    useEffect(() => {
        if (id) {
            MerchantApi.getOneMerchant({id: Number(id)})
                .then((response: any) => {
                    setInitialValues(response);
                    setInitialValues((prev: any) =>
                        update(prev, {
                            branchId: {
                                value: response.branchId,
                                label: response.branchName,
                            },
                        })
                    );
                })
                .catch((error) => toast.error(error.message));
        }
    }, [MerchantApi, setInitialValues, id]);

    //submit
    const submit = useCallback(
        (value: any) => {
            if (id) {
                const data = {
                    ...value,
                    id: id,
                    branchId: value.branchId.value ? Number(value.branchId.value): 0,
                };
                MerchantApi.updateMerchant(data)
                    .then((response: any) => {
                        toast.success(response.message);
                        navigate("/app/merchant/table");
                    })
                    .catch((error) => toast.error(error.message));
            } else {
                const data = {
                    ...value,
                    branchId: value.branchId.value ? Number(value.branchId.value): 0,
                };
                console.log(value.branchId)
                MerchantApi.creteMerchant(data)
                    .then((response: any) => {
                        toast.success(response.message);
                        navigate("/app/merchant/table");
                    })
                    .catch((error) => toast.error(error.message));
            }
        },
        [MerchantApi, id, navigate]
    );

    return (
        <TabPage
            childrenClassName="p-3 pt-4"
            headerComponent={
                <div className="d-flex align-items-center h-100">
                    <Button
                        onClick={() => navigate("/app/merchant/table")}
                        className="bg-gold text-light px-2 py-1"
                    >
                        Назад
                    </Button>
                </div>
            }
        >
            <MerchantForm
                initialValues={initialValues}
                setInitialValues={setInitialValues}
                branches={branches}
                submit={submit}
            />
        </TabPage>
    );
}
