import { useMemo } from "react";
import { PlanApi } from "./PlanApi";
import { useApiBase } from "../ApiContext";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

interface Props {
  readonly PlanApi: PlanApi;
}

export function usePlanApiContext(): Props {
  const data = useApiBase();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const api = useMemo(() => new PlanApi(data), []);
  const api = useMemo(() => new PlanApi({...data, navigate, dispatch}), []);

  return {
    PlanApi: api,
  };
}
