import { useParams } from "react-router-dom";
import ContainerLayout from "../components/app/ContainerLayout";
import MerchantTableWrapper from "../components/merchant/MerchantTableWrapper";
import MerchantFormWrapper from "../components/merchant/MerchantFormWrapper";

export default function MerchantContainer() {
  const { tab = "table" } = useParams();
  return (
    <ContainerLayout>
      {tab === "table" && <MerchantTableWrapper />}
      {tab === "form" && <MerchantFormWrapper />}
    </ContainerLayout>
  );
}
