import Table from "../table/Table";

interface Props{
    readonly data: any[]
}

export default function DashboardBranchTable({data}:Props){

    const headers = [
        {
            header: "Наименование филиала",
            access: "branchName",
            width: 200
        },
        {
            header: "Наименование счета",
            access: "accountName",
            width: 200
        },
        {
            header: "Номер счета",
            access: "accountNumber",
            width: 200
        },
        {
            header: "Баланс",
            access: "balance",
            width: 200
        },
    ]

    return (
        <Table headers={headers} data={data}/>
    )
}