import { useParams } from "react-router-dom";

import ContainerLayout from "../components/app/ContainerLayout";
import ParcelTableWrapper from "../components/parcel/ParcelTableWrapper";
import ParcelViewWrapper from "../components/parcel/ParcelViewWrapper";
import ByCouriersTab from "../components/parcel/ByCouriersTab";
import ByCustomersTab from "../components/parcel/ByCustomersTab";
import AddParcelFormWrapper from "../components/parcel/ParcelFormWrapper";

export default function ParcelContainer(){
    const { tab = "all-parcels" } = useParams();
    return (
        <ContainerLayout>
           {tab === "all-parcels" && (
            <ParcelTableWrapper 
            />
           )}
           {tab === "parcel-form" && (
            <AddParcelFormWrapper/>
           )}
           {tab === "parcel-view" && (
            <ParcelViewWrapper/>
           )}
           {tab === "by-couriers" && (
            <ByCouriersTab/>
          )}
          {tab === "by-customers" && (
            <ByCustomersTab/>
          )}
        </ContainerLayout>
    )
}