import { useCallback, useMemo } from "react";
import { Form, Formik } from "formik";
import { object, string } from "yup";
import { update } from "immupdate";
import { profileSelector } from "../../reducers/authReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";

import GroupBox from "../app/GroupBox";
import InputField from "../form/InputField";
import Button from "../button/Button";
import SelectPickerField from "../form/SelectPickerField";

interface IncomesFormProps{
    readonly branches: any;
    readonly initialValues: any;
    readonly setInitialValues: (value: any) => void;
    readonly submit: (value: any) => void;
}

const validationSchema = object({
    name: string().required("Required!"),
    description: string().required("Required!"),
    branchId: object()
})

export default function IncomesForm({
    initialValues, 
    setInitialValues, 
    branches,
    submit
}:IncomesFormProps){

    const profile: any = useShallowEqualSelector(profileSelector);

    const isAdmin = useMemo(()=>Boolean(profile.role[0] === "Administrator"), [profile]);

    const onChangeName = useCallback((value: any)=>{
        setInitialValues((prev: any)=>update(prev, {
            name: value.target.value
        }))
    },[setInitialValues])

    const onChangeDescription = useCallback((value: any)=>{
        setInitialValues((prev: any)=>update(prev, {
            description: value.target.value
        }))
    },[setInitialValues]);

    const onChangeBranchId = useCallback(
        (value: any) => {
          setInitialValues((prev: any) =>
            update(prev, {
              branchId: {
                label: value.label,
                value: value.value,
              },
            })
          );
        },
        [setInitialValues]
      );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={submit}
            >
            {()=>(
                <Form>
                    <div className="row">
                        <div className="col-6">
                        <GroupBox title="Информация о тарифе">
                           <div className="row">
                            <div className="col-12">
                            <InputField
                                label="Название"
                                name="name"
                                value={initialValues.name}
                                onChange={(event: any)=>onChangeName(event)}
                                />
                            </div>
                            <div className="col-12">
                            <InputField
                                label="Описание"
                                name="description"
                                value={initialValues.description}
                                onChange={(event: any)=>onChangeDescription(event)}
                                />
                            </div>
                           { isAdmin && <div className="col-12">
                                <SelectPickerField
                                    label="Филиал"
                                    name="branchId"
                                    options={branches}
                                    onChanges={(event: any) => onChangeBranchId(event)}
                                    />
                            </div>}
                           </div>
                        </GroupBox>
                        </div>
                        <div className="col-12 mt-3">
                            <Button type="submit" className="text-light bg-gold px-2 py-1">
                                Сохранить
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}