import "./assets/app-menu.scss";
import React, { Children, ReactElement } from "react";
import { SideBarItemProps } from "./SideBarItem";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { appMenuTypeSelector } from "../../reducers/appReducer";
import { MenuTypes } from "../../api/AppDto";

import ActiveItemIcon from "../icons/ActiveItemIcon";

interface AppMenuProps {
  readonly onChangeTab: (value: string, title: string) => void;
  readonly activeTab: string;
  readonly defaultTab: string;
  readonly children:
    | ReactElement<SideBarItemProps>[]
    | ReactElement<SideBarItemProps>;
  readonly className?: string;
}

export default function AppMenu({
  onChangeTab,
  activeTab,
  children,
  className,
  defaultTab,
}: AppMenuProps) {
  const menu = useShallowEqualSelector(appMenuTypeSelector);

  return (
    <div className={`menu-item-list-container app-menu w-100 ${className}`}>
      {Children.map(children, (child, index) => {
        if (child.props.show === false) {
          return null;
        } else {
          return (
            <div key={index} className="item-container w-100">
              <div
                className={`w-100 item-header py-2 px-4 ${
                  activeTab === child.key ||
                  (!activeTab && defaultTab === child.key)
                    ? "active-item"
                    : ""
                } `}
                onClick={() =>
                  onChangeTab(
                    child.key as string,
                    child.props.children as string
                  )
                }
              >
                <div className="item-title w-100">
                  {child.props.icon && (
                    <span className="pe-3">{child.props.icon}</span>
                  )}
                  {menu !== MenuTypes.Opened && (
                    <span
                      className={`${
                        menu === MenuTypes.Closed ? "" : "opasity"
                      }`}
                    >
                      {child.props.children}
                    </span>
                  )}
                </div>
                {child.props.responsiveContent && menu !== MenuTypes.Opened && (
                  <ActiveItemIcon
                    color="white"
                    active={activeTab === child.key}
                    size={15}
                  />
                )}
              </div>
              {child.props.responsiveContent &&
                activeTab === child.key &&
                menu === MenuTypes.Closed && (
                  <div
                    className="responsive-content-box"
                    style={{ paddingLeft: "30px" }}
                  >
                    <div className={`responsive-content`}>
                      {child.props.responsiveContent}
                    </div>
                  </div>
                )}
            </div>
          );
        }
      })}
    </div>
  );
}
