import { BaseApi } from "../BaseApi";
import { CreateIncomeQuery, DeleteIncomeTypeQuery, GetIncomesListPagnQuery, GetIncomesListQuery, GetOneIncomeQuery, UpdateIncomeTypeQuery } from "./InocomesDto";

export class IncomesApi extends BaseApi {

  public getListIncomes({ api }: GetIncomesListQuery): Promise<any> {
    return this.get(`${api}`);
  }

  public getListIncomesPagination({ 
      pageNumber, 
      pageSize, 
      api 
    }: GetIncomesListPagnQuery): Promise<any> {
    return this.get(`${api}`, {
      query: { pageNumber, pageSize }
    });
  }

  public getOneIncome({ id, api }: GetOneIncomeQuery): Promise<any> {
    return this.get(`${api}/${id}`);
  }

  public createIncome({ name, description, branchId, api }: CreateIncomeQuery): Promise<any> {
    return this.post(`${api}`, {
      json: { name, description, branchId }
    });
  }

  public updateIncome({ name, description, branchId, api,  id }: UpdateIncomeTypeQuery ): Promise<any>  {
    return this.put(`${api}`, {
      json: { name, description, id, branchId }
    });
  }

  public deleteIncome({ id, api }:DeleteIncomeTypeQuery) {
    return this.post(`${api}`, {
      json: { id } 
    });
  }
}
