import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import AuthLayout from "./AuthLayout";
import AuthLoginForm from "./AuthLoginForm";
import jwtDecode from "jwt-decode";
import ModalLoader from "../modal/MdalLoader";
import { useDispatch } from "react-redux";
import { setToken } from "../../reducers/authReducer";
import { useAuthApiContext } from "../../api/auth/AuthApiContext";

export default function AuthFormWrapper() {
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    phone: "",
    password: "",
  });

  const { AuthApi } = useAuthApiContext();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (value: any) => {
      setIsLoading(true);
      AuthApi.login({
        phoneNumber: value.phone.startsWith('+') ? value.phone : `+${value.phone}`,
        password: value.password,
      })
        .then((response: any) => {
          if (response) {
            if (response.result) {
              setIsLoading(false);
              dispatch(setToken({ token: response.accessToken }));
              toast.success(response.message[0]);
              const profile: any = jwtDecode(response.accessToken);

              if (profile?.role?.length > 0) {
                  navigate("/app/dashboard");
              }

            } else {
              alert(response.message[0]);
            }
          } else {
            toast.error("Connection Error");
          }
          setIsLoading(false);
        })
        .catch((erro) => {
          toast.error(erro.message);
          setIsLoading(false);
        });
    },
    [navigate, AuthApi, dispatch]
  );

  return (
    <AuthLayout>
      <AuthLoginForm
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        onSubmit={onSubmit}
      />
      <ModalLoader open={isLoading} />
    </AuthLayout>
  );
}
