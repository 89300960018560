import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import ContainerLayout from "../app/ContainerLayout";
import CustomerUserManagerTableWrapper from "./ByCustomerUserManagerTableWrapper";
import ByCustomerReceiveParcelTableWrapper from "./ByCustomerReceiveParcelTableWrapper";
import ByCustomerSendingParcelTableWrapper from "./ByCustomerSendingParcelTableWrapper";

export default function CustomersTab(){

  const [searchParams, setSearchParams] = useSearchParams();
  const page = useMemo(()=>searchParams.get("pageType")? searchParams.get("pageType") : "table",[searchParams])

    return (
        <ContainerLayout>
          {page === "table" && (
             <CustomerUserManagerTableWrapper roleId={5} editRow={(value: any)=>{
              setSearchParams({pageType: "form", userId: value.id})
             }} 
             />
            )}
            {page === "customer-parcelreceive-tab" && (
                <ByCustomerReceiveParcelTableWrapper/>
            )}
            {page === "customer-parcelsending-tab" && (
                <ByCustomerSendingParcelTableWrapper/>
            )}
        </ContainerLayout>
    )
}